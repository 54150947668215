import React, { Component } from 'react'
import {StyledCG, StyledButton} from "./StyledCG";
import Header from '../../components/header'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import residingAddressImg from '../../assets/images/residing-address-img.png'
import Button from '../../components/button'
import { getAddress } from '../../api/UserAPI';
import Swal from 'sweetalert2'
import ProccessData from '../../lib/ProccessData'

export class CGresidingAddress extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
         
      username: this.props.location.state.username,
      password: this.props.location.state.password,
      prefixCountryNum: this.props.location.state.prefixCountryNum,
      phone: this.props.location.state.phone,
      firstname: this.props.location.state.firstname,
      lastname: this.props.location.state.lastname,
      displayName: this.props.location.state.displayName,
      gender : this.props.location.state.gender,
      birth: this.props.location.state.birth, 
      homeNumber:this.props.location.state.homeNumber,
      phone:this.props.location.state.phone,
      email:this.props.location.state.email,
      lastNric:this.props.location.state.lastNric,
      credentialType: this.props.location.state.isEditing ? this.props.location.state.credential.credentialType :  this.props.location.state.credentialType,
      idTokenGoogle: this.props.location.state.idTokenGoogle,
      findCaraList: this.props.location.state.findCaraList,
      uidAdmin: this.props.location.state.uidAdmin,
      streetName:'',
      unitNumber:'',
      postalCode:'',
      cityCountry:'',
      building: '',
      blockNumber: '',
      address: '',
      uid:'',
      token:'',
      errorMessage:'',
      showConfirmation:false,
      showError:false,
      show: false,
      agree: true,
      agree1: false,
      agree2: false,
        }
        this.back = this.back.bind(this);
      }
      onChangeText = (stateName, value) => {
        this.setState({ [stateName]: value });
      };

      componentDidUpdate() {
        this.mapStateToSessionStorage()
      }

      componentDidMount(){
        if(  this.props.location.state.streetName){
          this.setState({streetName:  this.props.location.state.streetName})
        }else if(window.sessionStorage.getItem("streetName") != '' && window.sessionStorage.getItem("streetName") !='null'){
          this.setState({streetName: window.sessionStorage.getItem("streetName")})
     
        }
        if(  this.props.location.state.blockNumber){
          this.setState({blockNumber:  this.props.location.state.blockNumber})
        }else if(window.sessionStorage.getItem("blockNumber") != '' && window.sessionStorage.getItem("blockNumber") !='null'){
          this.setState({blockNumber: window.sessionStorage.getItem("blockNumber")})
        }
        if(  this.props.location.state.building){
          this.setState({building:  this.props.location.state.building})
        }else if(window.sessionStorage.getItem("building") != '' && window.sessionStorage.getItem("building") !='null'){
          this.setState({building: window.sessionStorage.getItem("building")})
        }
        if(  this.props.location.state.address){
          this.setState({address:  this.props.location.state.address})
        }else if(window.sessionStorage.getItem("address") != '' && window.sessionStorage.getItem("address") !='null'){
          this.setState({address: window.sessionStorage.getItem("address")})
        }
        if(  this.props.location.state.unitNumber){
          this.setState({unitNumber:  this.props.location.state.unitNumber})
        }else if(window.sessionStorage.getItem("unitNumber") != '' && window.sessionStorage.getItem("unitNumber") !='null'){
          this.setState({unitNumber: window.sessionStorage.getItem("unitNumber")})
     
        }
        if(  this.props.location.state.postalCode){
          this.setState({postalCode:  this.props.location.state.postalCode})
        }else if(window.sessionStorage.getItem("postalCode") != '' && window.sessionStorage.getItem("postalCode") !='null'){
          this.setState({postalCode: window.sessionStorage.getItem("postalCode")})
     
        }
        if(  this.props.location.state.cityCountry){
          this.setState({cityCountry:  this.props.location.state.cityCountry})
        } else if(window.sessionStorage.getItem("cityCountry") != '' && window.sessionStorage.getItem("cityCountry") !='null'){
          this.setState({cityCountry: window.sessionStorage.getItem("cityCountry")})
        }
    }

    alertError = (text, exit = true) => {
      Swal.fire({
        title: 'Error!',
        text,
        icon: 'error',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        preConfirm: () => {
          console.log('exit => ', exit)
          if (exit) {
            this.props.history.replace('/')
          } else null
        },
      })
    }
 
back=()=>{
  this.props.history.push({
    pathname: '/CareGiverPersonDetail',
    state: {
      
      idTokenGoogle:this.state.idTokenGoogle,
      credentialType: this.state.credentialType, 
      prefixCountryNum:this.state.prefixCountryNum,
      phone:this.state.phone,
      username:this.state.username,
      password:this.state.password,
      firstname: this.state.firstname,
      lastname:this.state.lastname,
      gender:this.state.gender,
      birth:this.state.birth,
      lastNric:this.state.lastNric,
      uidAdmin: this.state.uidAdmin
    }
  });
}
handleCloseError =()=>{
    this.setState({showError:false})
}

mapStateToSessionStorage = () => {
  for(let state in this.state) {
    sessionStorage.setItem(state, this.state[state]);
  }
}

onGetAddress = async (name, value) => {
  this.onChangeText(name, value)

  try {
    let resultGet = {}
    resultGet.payload = await getAddress({
      searchVal: `${value}`,
      returnGeom: `Y`,
      getAddrDetails: `Y`,
    })
    resultGet.payload.type = 'SUCCESS'
    ProccessData(resultGet, 'get address')
    .then(res => {
      if (res && res.data && res.data.results && res.data.results.length > 0) {
        this.setState(states => {

          states[`address`] = `${res.data.results[0].ADDRESS.replace(value, '')}`;
          states[`streetName`] = `${res.data.results[0].ROAD_NAME}`;
          states[`building`] = `${res.data.results[0].BUILDING}`;
          states[`blockNumber`] = `${res.data.results[0].BLK_NO}`;
          states[`cityCountry`] = 'Singapore'

          return {
            ...states
          }
        })
      }
    })
    .catch(err => {
      this.alertError(err)
    })
  } catch (e) {
    this.alertError(e)
  }
}


 handleClose= ()=> {
    this.props.history.push({pathname:'/summary-caregiver', // /CareGiverCreateNewPWDORExisting
    state:{
      uid: this.state.uid,
      username: this.state.username,
      password: this.state.password,
      phone: this.state.phone,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      displayName: this.state.displayName,
      gender : this.state.gender,
      birth: this.state.birth,
      streetName: this.state.streetName,
      unitNumber: this.state.unitNumber,
      postalCode: this.state.postalCode,
      cityCountry: this.state.cityCountry,
      blockNumber: this.state.blockNumber,
      building: this.state.building,
      address: this.state.address,
      uid: this.state.uid,
      token: this.state.token,
      homeNumber:this.state.homeNumber,
      phone:this.state.phone,
      credential : {
        credentialType: this.state.credentialType,
        credentialData: {
            username:this.state.username,
            password:this.state.password,
            idTokenGoogle: this.state.idTokenGoogle,
        },
        accountData:{
            prefixCountryNum: this.state.prefixCountryNum,
            phone: this.state.phone,
            email: this.state.email,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            displayName: this.state.displayName,
            gender: this.state.gender,
            birth: this.state.birth,
            streetName: this.state.streetName,
            unitNumber: this.state.unitNumber,
            postalCode: this.state.postalCode,
            cityCountry: this.state.cityCountry,
            blockNumber: this.state.blockNumber,
            building: this.state.building,
            address: this.state.address,
            interests: "-",
            activityPreferences: "-",
            homeNumber: this.state.homeNumber,
            lastNric: this.state.lastNric,
            findCaraList: this.state.findCaraList,
            createdBy: this.state.uidAdmin
        }

      }
  }})
  }


  render() {
    const {streetName,unitNumber,postalCode,cityCountry,showError, blockNumber, building, address} = this.state
  
    return (
      <>
        <StyledCG>
          <Header />
            <Row style={{marginTop:'5%', marginBottom: '4%'}}>
              <Col md={8}>
                <h2>Residing Address</h2>
              </Col>
              <Col md={4}>
                <img src={residingAddressImg} className="fluid" alt="Residing Address" />
              </Col>
            </Row>
            
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Postal Code</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your postal code here...',name:"postalCode",
                                                                    value:postalCode,
                                                                    onChange: (e) => this.onGetAddress('postalCode', e.target.value) }}/>
                    </Col>
                </Row>
                <Row style={{textAlign:'start'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Street Name</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type your street name here...',name:"streetName",
                                                                    value:streetName,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('streetName', e.target.value),}}/>
                    </Col>
                </Row>
                <Row style={{textAlign:'start'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Building</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type your building name here...',name:"building",
                                                                    value:building,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('building', e.target.value),}}/>
                    </Col>
                </Row>
                <Row style={{textAlign:'start'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Block Number</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type your block number here...',name:"blockNumber",
                                                                    value:blockNumber,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('blockNumber', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Unit Number</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your unit number here...',name:"unitNumber",
                                                                    value:unitNumber,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('unitNumber', e.target.value),}}/>
                   
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>City, Country</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type your city country here...',name:"cityCountry",
                                                                    value:cityCountry,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('cityCountry', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Address</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your address here...',name:"address",
                                                                    value:address,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('address', e.target.value),}}/>
                   
                    </Col>
                </Row>
                <Row style={{marginTop:'6%',marginBottom:'6%'}}>
                  <Col md={6}></Col>
                  <Col md={3}>
                  </Col>
                  <Col md={3}>
                    <Button 
                      onClick={() => {
                        if((this.state.streetName !='' ) || (this.state.postalCode != '' )  || (this.state.cityCountry !='' ) ){
                          this.handleClose()
                      }else{
                        Swal.fire({
                          title: 'Warning!',
                          text: 'please fill the required data ',
                          icon: 'info',
                          confirmButtonText: 'OK'
                        })
                      }
                
                      }} 
                      text="Next" 
                      containerStyle={StyledButton} 
                      textStyle={StyledButton} 
                      buttonStyle={{ 
                        height:'50px',
                        width:'auto', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'flex-end'
                      }}
                      disabled={ 
                        streetName === '' || !streetName ||
                        postalCode === '' || !postalCode ||
                        cityCountry === '' || !cityCountry 
                      }  
                    />
                  </Col>
                </Row>
            </StyledCG>

        
       
      </>
    )
  }
}


export default CGresidingAddress