import React from 'react'
import PropTypes from 'prop-types'

import {Container, StyledButton, StyledDropdown } from './StyledButton'
import { Colors, Images, } from '../../themes'

function Button({ text, onClick, buttonStyle, textStyle, backgroundColor, textColor, bordered, borderColor, iconRight, iconRightImage, containerStyle, showDropdown, sortData, dropdownHeight, onSelectSort, disabled, dropdownWidth, selectedData, sortPropertyName, boxShadow }) {

  return (
    <StyledButton onClick={disabled ? null : onClick} buttonStyle={buttonStyle} backgroundColor={backgroundColor} textColor={textColor} bordered={bordered} borderColor={borderColor} textStyle={textStyle} disabled={disabled}>
      <p className="button-text">{text}</p>
    </StyledButton>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.string,
  buttonStyle: PropTypes.string,
  textStyle: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  bordered: PropTypes.bool,
  boxShadow: PropTypes.bool,
  borderColor: PropTypes.string,
  containerStyle: PropTypes.string,
}

Button.defaultProps = {
  text: '',
  onClick: () => null,
  buttonStyle: '',
  buttonStyle: '',
  textStyle: '',
  backgroundColor: Colors.color8,
  textColor: Colors.color3,
  bordered: false,
  boxShadow: true,
  borderColor: Colors.color11,
  containerStyle: '',
}

export default Button




{/* <StyledDropdown showDropdown={showDropdown} dropdownHeight={dropdownHeight} dropdownWidth={dropdownWidth}>
<ul>
  {
    sortData.map((data, index) => {
      return (
        <div style={{ background: selectedData === data[sortPropertyName] ? Colors.color10  : Colors.color3 }}>
          <li key={index.toString()} onClick={() => onSelectSort(data)} style={{ color: selectedData === data[sortPropertyName] ? Colors.color3 : '' }}>{data[sortPropertyName]}</li>
        </div>
      )
    })
  }
</ul>
</StyledDropdown> */}