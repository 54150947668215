import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import { StyledInput, StyledDropdown } from "./StyledInput";

function Input({ inputProps, containerStyle, multiline, rows, iconRight, iconRightStyle, showDropdown, dropdownWidth, onSelectDropdown, dropdownData, dropdownPropertyName, onToggleIcon , fontAwesomeRight, fontAwesomeRightStyle}) {
  return (
    <>
      <StyledInput containerStyle={containerStyle} iconRightStyle={iconRightStyle} dropdownData={dropdownData}>
        {
          multiline ?
            <textarea {...inputProps} className="input-field" rows={rows} />
          :
            <input {...inputProps} className="input-field" />
        }
        {
          iconRight !== '' &&
            <img src={iconRight} className="icon-right" />
        }
        {
          fontAwesomeRight !== null &&
            <FontAwesomeIcon icon={fontAwesomeRight} style={fontAwesomeRightStyle} className="icon-right" onClick={onToggleIcon} />
        }
      </StyledInput>
      <StyledDropdown showDropdown={showDropdown} dropdownWidth={dropdownWidth}>
        <ul>
          {
            dropdownData && dropdownData.map((data, index) => {
              return (
                <li key={index.toString()} onClick={() => onSelectDropdown(data)}>{data[dropdownPropertyName]}</li>
              )
            })
          }
        </ul>
      </StyledDropdown>
    </>
  )
}

Input.propTypes = {
  inputProps: PropTypes.any,
  containerStyle: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  iconRight: PropTypes.string,
  iconRightStyle: PropTypes.string,
  showDropdown: PropTypes.bool,
  dropdownData: PropTypes.array,
  dropdownWidth: PropTypes.string,
  onSelectDropdown: PropTypes.func,
  dropdownPropertyName: PropTypes.string,
  onToggleIcon: PropTypes.func,
  fontAwesomeRight: PropTypes.any,
  fontAwesomeRightStyle: PropTypes.any

}

Input.defaultProps = {
  inputProps: {},
  containerStyle: ``,
  multiline: false,
  rows: 6,
  iconRight: '',
  iconRightStyle: ``,
  showDropdown: false,
  dropdownData: [],
  dropdownWidth: '136px',
  onSelectDropdown: () => null,
  dropdownPropertyName: '',
  onToggleIcon: () => null,
  fontAwesomeRight: null,
  fontAwesomeRightStyle: {}
}

export default Input
