import React , { useState } from 'react';

import Modal from 'react-bootstrap/Modal'

import { Colors, Fonts, Scale } from '../../themes';
import {StyledModal,StyledButton} from "./StyledModalConfirmaton";

import Col from 'react-bootstrap/Col'
import Button from '../button'


function PopUpBoxConfirmation(props) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <StyledModal>
        <Modal style={{borderRadius:'40px'}} show={props.showConfirmation} onHide={props.handleClose}>
          <Modal.Header  style={{borderBottom:'none'}}>
            <Modal.Title style={{margin:'auto'}}></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{margin:'38px'}}>
          <h3 style={{fontFamily:'${Fonts.FontRegular}',fontSize:'24px',textAlign:'center',fontWeight:'800',paddingBottom:'20px'}}>Before you move on</h3>
          <h5 style={{fontFamily:'${Fonts.FontRegular}',fontSize:'18px',fontWeight:'400',color:'#000',lineHeight:'1.5'}}>Inaccurate information may affect your application. Please review your information before you move on </h5></Modal.Body>
          
          <div style={{width:'auto',paddingBottom:'20px',paddingTop:'5px',display:'flex',justifyContent:'center'}}>
          
          <Col style={{display:'flex',justifyContent:'center'}}>
           <Button backgroundColor='#fff' textColor='#502773' bordered='#502773' containerStyle={StyledButton} textStyle={StyledButton}   buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}  style={{height:'20px'}} text="Review now"
                    onClick={props.handleNo}
              />
              </Col>
            <Col style={{display:'flex',justifyContent:'center'}}>
            <Button containerStyle={StyledButton} textStyle={StyledButton}   buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}  style={{height:'20px'}} text="Move on"
                    onClick={props.handleYes}
              /></Col>
              </div>
        </Modal>
        </StyledModal>
      </>
    );
  }
  export default PopUpBoxConfirmation