import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton,InputStyle} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'
import styled from 'styled-components'

import Button from '../../components/button'
import Search from '../../assets/images/searchIcon.png'

import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'
import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import PictureCard from '../../assets/images/iconProfile.jpeg'
import back from '../../assets/images/icon-back.png'
import qr from '../../assets/images/qr.png'

import Swal from 'sweetalert2'
import PopUpBox from '../../components/modal'


import AppConfig from '../../config'

import Select from 'react-select'
import PopUpBoxHandler from '../../components/modalhandler';
const accountUrl = AppConfig.ACCOUNT_URL


import moment from 'moment'
import { Images, Colors } from '../../themes';
import axios from 'axios';
const InputDateStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`
const InputReadOnlyStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin: 10px 0 25px 0; 
.input-field { cursor: pointer; }
`;

const InputTypeStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin-top: 10px; 
.input-field { cursor: pointer; }
`;


const relationshipStatusData =
[
      {
        id: 0,
        name: `Uncle / Aunt`,
        value: `Uncle / Aunt`,
        label: `Uncle / Aunt`
      },
      {
        id: 1,
        name:  'Spouse',
        value: `Spouse`,
        label:  `Spouse`
      },
      {
        id: 2,
        name:  'Parent',
        value: `Parent`,
        label:  `Parent`
      },
      {
        id: 3,
        name:  'Grandparent',
        value: `Grandparent`,
        label: `Grandparent`
      },
      {
        id: 4,
        name:  'Child',
        value: `Child`,
        label:  `Child`
      },
      {
        id: 5,
        name: 'Grandchild',
        value: `Grandchild`,
        label:  `Grandchild`
      },
      {
        id: 6,
        name: `Nephew / Niece`,
        value: `Nephew / Niece`,
        label:  `Nephew / Niece`
      },
      {
        id: 7,
        name: 'Others, please specify',
        isCustom: false
      },
      {
        id: 8,
        name: `Organisation Staff`,
        value: `Organisation Staff`,
        label:  `Organisation Staff`
      },
      {
        id: 9,
        name:  'Volunteer',
        value: `Volunteer`,
        label:  `Volunteer`
      },
    ]

const relationshipStatusData2 =
[
  {
    id: 0,
    name: `Organisation Staff`,
    value: `Organisation Staff`,
    label:  `Organisation Staff`
  },
  {
    id: 1,
    name:  'Volunteer',
    value: `Volunteer`,
    label:  `Volunteer`
  },
]

export class tagNewPWD extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            uidTagged: '',
            token:this.props.location.state.token,
            uidTags:this.props.location.state.uid,
            partnerToken:this.props.location.state.partnerToken,
            searchCG:'',
            searchCGName:'',
            resultName:'',
            cityCG:'',
            membershipCG:'',
            searchCGNric:'',email:'',lastname:'',
            searchBy:'',
            relationship:'',
            relationshipStatus:'',
            data:[],
            showError:false,
          errorMessage:'',
            Switch: SwitchNo,
            SwitchName:TypeInactive,
            SwitchNumber:TypeInactive,
        
        }
      }
      handleShow = (i)=> {
        if(this.state.relationship == ''){
          Swal.fire({
            title: 'Warning!',
            text: `Please select This Person Living with Dementia is my `,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }else{
        axios.post(`${accountUrl}/public/accounts/tagMyOtherAccount`, {
          uidTagged: i,
          uidTags: parseInt(this.state.uidTags),
          status: "PENDING",
          relationship: this.state.relationship,
          },{headers: {
            Authorization: `${this.state.token}`
          }},)
          .then(res => {
            console.log(res)
            if(res.status==200 && res.data.type=="SUCCESS"){
              
     this.setState({show: true})
            }else{
              Swal.fire({
                title: 'Warning!',
                text: `${res.data.data.message}`,
                icon: 'info',
                confirmButtonText: 'OK'
              })
            };
          }, (error) => {
            console.log(error);
          });
        }
    }
    handleClose = (e)=> {
     this.setState({show: false})
     if (this.state.partnerToken) {
      window.open(`${AppConfig.ADMIN_HOSTNAME}/users`, "_self")
      // this.props.history.push('/')
     } else  {
       this.props.history.push({pathname:'/registrationNotification'})
     }
    }

  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state })
  } 
  handleChangeRelationship =(e)=> {
    this.setState({relationship:e.value})
    console.log('relationship', this.state.relationship )
    this.setState({relationshipStatus:e.id})
  };
  
  changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes})
   }else{ 
       this.setState({Switch: SwitchNo})
   }
  }
  changeNumber=()=>{
    if(this.state.SwitchNumber == TypeInactive ){
        this.setState({SwitchNumber: TypeActive,searchBy:'NRIC', SwitchName:TypeInactive})
        
     }else{ 
         this.setState({SwitchGender: TypeInactive,SwitchGenderM: TypeActive,})
     }
  }
  changeName=()=>{
    if(this.state.SwitchName == TypeInactive ){
        this.setState({SwitchName: TypeActive,searchBy:'name', SwitchNumber:TypeInactive})
        
     }else{ 
         this.setState({SwitchGenderM: TypeInactive,SwitchGenderF: TypeActive,})
     }
  }
  getCG = () =>{
    if (this.state.partnerToken) {
      this.partnerSearch()
    } else {
      if(this.state.searchCGName !='' && this.state.searchCGNric !=''){
        axios.get(`${accountUrl}/public/accounts/usersFiltered?name=${this.state.searchCGName}&lastNric=${this.state.searchCGNric}&type=DEMENTIA`, 
          )
          .then(res => {
            console.log(res)
            console.log(this.state.phone)
            let CGName = this.state.searchCGName
            this.setState({resultName:CGName})
            if(res.status==200 && res.data.type=="SUCCESS"){
              
                console.log(this.state.token)
                this.setState({data:res.data.data})
                this.setState({ errorMessage: res && res.data && res.data.data && res.data.data.length < 1 ? 'No matched' : '' })
            }else{
              Swal.fire({
                title: 'Warning!',
                text: `${res.data.data.message}`,
                icon: 'info',
                confirmButtonText: 'OK'
              })
            };
          }, (error) => {
            console.log(error);
          });}else{
            Swal.fire({
              title: 'Warning!',
              text: `Please Fill the name and last NRIC`,
              icon: 'info',
              confirmButtonText: 'OK'
            })
          }
    }
  }

  partnerSearch = () => {
    if(this.state.searchCGName !='' && this.state.searchCGNric !=''){
      axios.get(`${accountUrl}/partner/users?name=${this.state.searchCGName}&lastNric=${this.state.searchCGNric}&type=DEMENTIA`, {
        headers: {
          Authorization: this.state.partnerToken,
        }
      })
      .then(res => {
        let CGName = this.state.searchCGName
        this.setState({resultName:CGName})
        if( res.status == 200 && res.data.type == "SUCCESS") {
          console.log(this.state.token)
          this.setState({data:res.data.data})
          this.setState({ errorMessage: res && res.data && res.data.data && res.data.data.length < 1 ? 'No matched' : '' })
        } else {
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        };
      }, (error) => {
        console.log(error);
      });
    } 
    
    else {
      Swal.fire({
        title: 'Warning!',
        text: `Please Fill the name and last NRIC`,
        icon: 'info',
        confirmButtonText: 'OK'
      })
    }
    
  }
  
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
  
  handleCloseError =()=>{
    this.setState({showError:false})
  }
  render() {
    const {SwitchName,email,data, SwitchNumber,lastNric,searchCGName,searchCGNric, searchCG ,cityCG, nameCG,membershipCG, lastname, gender,SwitchGenderF,SwitchGenderM, partnerToken } = this.state
    
    return (
      <>
        <StyledCG>
            <Header/>
            <div onClick={() => this.props.history.goBack()} style={{ marginBottom: '20px', width: '70px', height: '30px', background: '#502773', borderRadius: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
              <div><img src={back} alt="back" style={{ width: '9px', objectFit: 'contain' }} /></div>
              <div style={{ fontWeight: 800, fontSize: '13px', color: 'white', marginLeft: '5px' }}>Back</div>
            </div>
            {/* <h2>Tag new PLWD / Next-of-Kin</h2> */}
            <Row>
              <Col md={12}>
                <h2>Tag new Person Living with Dementia</h2>
              </Col>
              <Col md={6}>
                <p style={{
                  color: '#758BA9',
                  textAlign: 'left'
                }}>To search, please input the person living with dementia’s name and last 4 digits of NRIC. Tap on the result, select relationship to caregiver, and add account. Please note that this has to be approved in the person living with dementia’s account.</p>
              </Col>
            </Row>
               
                <Row>
                    <Col md={6} style={{margin:'auto'}}>
                        <label>Name </label>
                    </Col>
                    <Col md={6}>
                    <Input inputProps={{placeholder:'search by name..',name:"searchCGName",
                                                                    value:searchCGName,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('searchCGName', e.target.value),}}  /></Col>
                </Row>
                
                <Row>
                <Col md={6} style={{margin:'auto'}}>
                        <label>Last 4 characters of NRIC </label>
                    </Col>
                    <Col md={6}>
                        <Input inputProps={{placeholder:'search by NRIC..',name:"searchCGNric",
                                                                    value:searchCGNric,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('searchCGNric', e.target.value),}}  />
                    </Col>
                              
                </Row>
                {/* <Row style={{marginTop:'20px',marginBottom:'20px'}}>
                  <Col md={6} style={{margin:'auto'}}>
                      <label>This Person Living with Dementia is my </label>
                  </Col>
                  <Col md={6} >
                  <Select  onChange={(e) =>  this.handleChangeRelationship(e)} defaultValue={relationshipStatusData[window.sessionStorage.getItem("RelationshipStatus")] } options={relationshipStatusData} />
                  </Col>
                  
              </Row> */}
                <Row style={{display:'flex', marginTop: '20px' ,justifyContent:'flex-end',marginRight:'10px'}}>
                <Col md={4}>
                <CustomButton color="#62318C" onClick={this.getCG}>
                  Search Account
                </CustomButton>
                </Col>
                {/* <Button buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} text="Search"  onClick={this.getCG}/> */}
                </Row>
                <hr/>
                {this.state.errorMessage !== '' && (
                  <div className='d-flex justify-content-center pt-5'>
                    <p style={{color: '#EB5757', textAlign: 'center'}}>No matched</p>
                  </div>
                )}
            <Row>
                <Col  md={6}>
                  {this.state.resultName && this.state.errorMessage === '' && <p>Matched Result</p> }
                    {/* <p>Showing {data.length} results for “{this.state.resultName}”</p>  */}
                </Col>
                <Col md={6} className="d-flex justify-content-end">
                <Row style={{width: '100%', justifyContent: 'flex-end'}}>{
              data.map((item,i) => {
                return (
                  
                  <Col key={i} md={6} style={{width: '100%'}}>
                      <div onClick={() => {
                        
                        this.props.history.push('/tagNewPWDSelectRelation', { ...this.state, memberSince: moment(item.createdAt).format('DD MMMM YYYY'), id: item.uid, taggedPhoto: item.photo, taggedMembershipId: item.membershipId, taggedName: `${item.firstname} ${item.lastname || ''}` })
                      }} style={{ display: 'flex', backgroundColor: '#F5F5F5', alignItems: 'center', justifyContent: 'center', boxSizing: 'content-box', padding: '20px 10px', boxShadow: '-3px -3px 10px #FFFFFF, 3px 3px 10px #CCD5E1', borderRadius: 12, width: '250px' }}>
                        <img src={item.photo ? item.photo : PictureCard} style={{height:'50px',width:'50px', marginRight: '10px', borderRadius: '50%' }}/>
                        <p style={{color: '#424242', marginTop: 10,}}>{item.firstname} {item.lastname}</p>
                      </div>
                        {/* <Row style={{height:'70px'}} className="headerCard">
                        <img src={PictureCard} style={{left:'2%',top:'6%',position:'absolute',height:'80px',width:'80px' }}/>
                            
                            <Col > 
                           
                              <Row >
                              <Col style={{left:'21%',position:'absolute'}} >  <h3 style={{marginTop:'5%' }}>{item.firstname} {item.lastname}</h3></Col>
                              
                              <Col></Col>
                               
                            </Row>
                            </Col>
                            </Row>
                            <Row >
                            
                                   <Col style={{left:'21%',position:'absolute'}}> <h4 style={{marginTop:'3.5%' }}></h4></Col>
                                   <Col style={{left:'35%'}}> <h4 style={{marginTop:'7%' }}>{item.email}</h4></Col>
                                   <Col style={{display:'flex',justifyContent:'flex-end'}}>  <h4 style={{marginTop:'7%' }}>{item.membershipId}</h4></Col>
                                
                            </Row>
                        
                        <hr/>
                        <Row >
                            <Col md={7} >
                            
                            </Col>
                            <Col md={5} style={{display:'flex',justifyContent:'flex-end'}} >
                               
                                
                                <Button  text="Add" buttonStyle={{height:'40px',width:'150px', display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}} onClick={() => this.handleShow(item.uid)}/>
                              <PopUpBox body="The user has been successfully added! Please note that this has to be approved in the person living with dementia's account." show={this.state.show}  handleClose={this.handleClose}/>
                              <PopUpBoxHandler show={this.state.showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
          
                            </Col>
                        </Row> */}
                       
                    </Col>
                )
              })
            }
                    </Row>
                    
                </Col>
                </Row>
               
            </StyledCG>
        
       
      </>
    )
  }
}

const CustomButton = styled.button`
  background-color: ${props => props.color} !important;
  font-size: 18px !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100% !important;
  height: 50px !important;
  box-shadow: -3px -3px 10px #FFFFFF, 3px 3px 10px #C1CAD7 !important;
  font-weight: bold !important;
  cursor: pointer !important; 
`;


export default tagNewPWD