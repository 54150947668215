import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledCard = styled.div`
  
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top:80px;
  border-radius: 15px;
  overflow: hidden;
  
  width: 100%;
  // height: 400px;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  // margin-bottom:15px;
  padding-left: 5%; 
  padding-right: 5%; 
  
  :hover {
    cursor: pointer;
  }

  @media (max-width: 500px) {
    margin-bottom: 7%;
  }
    
  h4{
    text-align: center;
    font-family: "TruenoBold", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /* identical to box height, or 83% */

    margin-top:30px;
    color: #502773;
  }
  .image{
    margin:auto
  }
  .button-text {
      font-family: "TruenoRegular", Arial, sans-serif;
      font-size: 18px;
      /* or 133% */
      margin-top: 30px;
      /* TXT STD */
      padding: 0 20px;
      color: #424242;
  }

  @media (max-width:480px) {
    width: 100%;
    height:340px;
    
    h4 {
      font-size: 18px;
      line-height: 20px;
      /* identical to box height, or 83% */

      margin-top:30px;
      color: #502773;
    }

    .button-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      /* or 133% */
      margin-top: 20px;
      /* TXT STD */
      padding:10px;
      color: #424242;
    }
  }
`