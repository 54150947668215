import { API } from '../lib/Request'
import AppConfig from '../config'

const accountUrl = AppConfig.ACCOUNT_URL

export const getAddress = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/account/address`, method: 'GET', params })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}


export const deleteUser = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/account/${data.uid}`, method: 'DELETE', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const editUser = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/account/${data.uid}`, method: 'PATCH', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const updateAccountStatus = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/status?uid=${data.uid}`, method: 'PATCH', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const exportUser = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/export?uid=${data.uid}`, method: 'GET', data, responseType: 'blob' })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const exportUserSelected = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/export?uidList=${data.uidList}`, method: 'GET', data, responseType: 'blob' })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const exportUserFiltered = async (params) => {
  console.log('params => ', params)
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/export`, method: 'GET', params, responseType: 'blob' })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getUserById = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/accounts/users/v2/${data.uid}`, method: 'GET', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getUsers = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/accounts/users`, method: 'GET', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getOtherAccount = async (uid, params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/otheraccount/${uid}`, method: 'GET', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}
export const createSafeReturnCard = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/credential/safereturncard`, method: 'POST', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const signUp = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/credential/signupDementia`, method: 'POST', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const signupPartner = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/credential/signUpPartner`, method: 'POST', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const signupMoP = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/credential/signupCaregiver`, method: 'POST', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const verifyCGUPToken = async ({ data, headers, params, }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/credential/verifyCGUnderPartner`, method: 'POST', data, params, headers, })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const verifyPartnerToken = async ({ data, headers, params, }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/credential/verifyPartnerToken`, method: 'POST', data, params, headers, })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const submitPartner = async ({ data, headers, params, }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/account/submitPartner`, method: 'POST', data, params, headers, })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const SubmitCaregiverUnderPartner = async ({ data, headers, params, }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/account/submitCGUnderPartner`, method: 'POST', data, params, headers, })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const tagAccount = async ({ data }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/accounts/tagMyOtherAccount`, method: 'POST', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const updateDementiaInfo = async ({ uid, data }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/dementia-info?uid=${uid}`, method: 'POST', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getTaggedAccount = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/myotheraccount`, method: 'GET', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getPartnerData = async ({headers, params}) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/partner/users`, method: 'GET', params, headers, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const resetPassword = async ({data}) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/reset-password`, method: 'POST', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const verifyTokenResetPassword = async ({data}) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/verify-token-reset-password`, method: 'POST', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const updateInvitation = async ({data, headers, params,}) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/account/invite`, method: 'PATCH', data, headers, params })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const checkDuplicateLastNricLastNameDateOfBirth = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/accounts/checkDuplicateLastNricLastNameDateOfBirth`, method: 'POST', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const sendSafeReturnSms = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/safe-return/sms`, method: 'POST', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const checkAccountWithOtp = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/credential/checkCaregiverRegistrationWithOtp`, method: 'POST', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const verifyToken = async ({data, headers}) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/credential/verifyToken`, method: 'POST', data, headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getNearestGtp = async (params) => 
  new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/account/go-to-points`, method: 'GET', params })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })