import styled, { css } from "styled-components";

import { Fonts, Colors, } from '../../../themes/'

import { deviceSize } from "../../../constants/deviceSize";

export const StyledRegistrationPartner = styled.div`
  width: 57%;
  margin: 0 auto;
  overflow: hidden;

  @media ${deviceSize.tabletL} {
    width: 80%;
  }

  .form-container {
    padding-bottom: 42px;
    margin-bottom: 56px;
    border-bottom: 1px solid #758BA9;
  }

  .registration-title {
    ${Fonts.FontRegular}
    ${Fonts.size.size32}
    color: #502773;
    padding-top: 80px;
  } 

  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media ${deviceSize.smMaterialUI} {
      flex-direction: column;
    }
  }

  .label-partner {
    font-family: "TruenoRegular", Arial, sans-serif;
    ${Fonts.size.size20}
    color: #424242;
    width: 233px;
    position: relative;
    top: 20px;
    @media ${deviceSize.smMaterialUI} {
      padding: 0;
    }
  }

  .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 290px;
    height: 56px;
    border: 1.5px solid ${Colors.color11};
    border-radius: 10px;
    cursor: pointer;
  }

  .button-back-text {
    ${Fonts.FontRegular};
    font-style: normal;
    display: inline-block;
    font-size: 20px;
    color: ${Colors.color11};
    font-weight: 800;
  }

  .button-register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 290px;
    height: 56px;
    background: ${props => props.buttonDisabled ? Colors.color16 : `linear-gradient(132.58deg, #62318C 26.06%, #512874 80.3%)`};
    border-radius: 10px;
    cursor: ${props => props.buttonDisabled ? `not-allowed` : 'pointer'};
  }

  .button-register-text {
    ${Fonts.FontRegular};
    font-style: normal;
    display: inline-block;
    font-size: 20px;
    color: #fff;
    font-weight: 800;
  }

  .link-text {
    ${Fonts.FontRegular};
    font-size: 14px;
    color: #502773;
  }

  .google{
    width: 148px;
    height: 60px;
    background: #F5F6F8;
    border: 1px solid #B1C2D8;
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
 }

 .oauth-container {
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 166px;
  @media ${deviceSize.smMaterialUI} {
    flex-direction: column;
  }
 }
`

export const InputStyle = `
  background-color: #FFFFFF;
`