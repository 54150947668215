import React, { Component } from 'react';

class AppStoresRedirect extends Component {
 
    componentDidMount() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        console.log('AppStoresRedirect', userAgent);
        if(/android/i.test(userAgent)){
            window.location.href = 'https://play.google.com/store/apps/details?id=com.embreo.carasg';
        }else if(/iPad|iPhone|iPod/.test(userAgent)){
            window.location.href = 'https://apps.apple.com/sg/app/cara-sg/id1553855834';
        }else{
            window.location.href = 'https://cara.sg';
        }
    }
 
    render(){
        return(
            <div></div>
        )
    }
}

export default AppStoresRedirect