import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledSummary = styled.div`
  background-color: white;
  .base-container {
    width: 1093px;
    margin: auto;
    padding-top: 20px;

    @media ${deviceSize.laptopL} {
      width: 70%;
    }

    @media ${deviceSize.tablet} {
      width: 90%;
    }
  }

  .title-summary {
    font-weight: bold;
    font-size: 32px;
    margin-top: 80px;
    color: #502773;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #758BA9;
    position: relative;
    bottom: 38px;
    z-index: 1;
  }

  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .circle-text {
    font-size: 14px;
    text-align: center;
  }

  .step-text {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #424242;
    margin-top: 5px;
  }

  .thank-you {
    font-weight: bold;
    font-size: 24px;
    color: #502773;
    margin-top: 60px;
  }

  .thank-you2 {
    font-size: 20px;
    color: #7F59A0;
  }

  .confirm-name {
    font-size: 24px;
    font-weight: bold;
    color: #424242;
  }
  
  .confirm-name2 {
    font-size: 20px;
    color: #758BA9;
    width: 395px;
  }

  .type {
    background: #FFFFFF;
    width: 100%;
    height: 50px;
    border: 1px solid #B1C2D8;
    box-sizing: border-box;
    border-radius: 15px;
    padding-left:30px;
    margin-right:auto
  }

  .please-text {
    font-weight: bold;
    font-size: 24px;
    color: #424242;
  }

  .please-text2 {
    font-size: 20px;
    color: #758BA9;
  }

  .circle-info {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #502773;
  }

  .confirm-text {
    font-weight: 600;
    font-size: 20px;
    color: #502773;
  }

  .confirm-text2 {
    font-size: 20px;
    color: #758BA9;
    margin-top: 32px;
    display: inline-block;
  }  content: "";

  .checkbox-container {
      display: flex;
      margin-top: 20px;
  }

  .input-title {
    font-weight: bold;
    font-size: 16px;
    color: #758BA9;
    margin-left: 8px;
  }

  /* Styling Checkbox Starts */
  .checkbox-label {
      display: block;
      position: relative;
      cursor: pointer;
      height: 24px;
      width: 24px;
      clear: both;
      color: white;
  }

  .checkbox-label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      color: white;
  }

  .checkbox-label .checkbox-custom {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 24px;
      width: 24px;
      background-color: #758BA9;
      color: white;
      border-radius: 5px;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      border: 2px solid #FFFFFF;
  }


  .checkbox-label input:checked ~ .checkbox-custom {
      background: #EFC900;
      border-radius: 5px;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity:1;
      color: white;
      border: 2px solid #FFFFFF;
  }


  .checkbox-label .checkbox-custom::after {
      position: absolute;
      content: "";
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 5px;
      border: solid #009BFF;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity:1;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      color: white;
  }


  .checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity:1;
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
    color: white;
  }

  /* For Ripple Effect */
  .checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);  
    color: white;
  }

  .checkbox-label input:checked ~ .checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    opacity:0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    color: white;
  }
`

export const Label = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #424242;
`