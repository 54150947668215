import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledLandingPage = styled.div`
  .header-container {
    // display: flex;
    
    @media (max-width: 500px) {
      flex-direction: column-reverse;
    }
  }

  .left-purple {
    background: rgba(80, 39, 115, 0.95);
    // flex: 1;
    // padding: 50px 0 50px 120px;
    padding: 7% 7%;
    // width: 50%;

    @media (max-width: 500px) {
      padding: 10% 10%;
    }
    
    h2 {
      font-family: "TruenoBold", Arial, sans-serif;
      font-weight: bold;
      font-size: 38px;
      color: #FFFFFF;
      // margin: 50px 0 20px 120px;
      margin-bottom: 20px;
      
     
      @media (max-width: 500px) {
        // margin: 20px 0 20px 20px;
        font-size: 36px;
      }
    }
  
    p {
      font-size: 18px;
      font-family: "TruenoRegular", Arial, sans-serif;
      color: #FFFFFF;
      // width: 300px;
      // font-weight: bold;
      // margin-left: 120px;
      margin-bottom: 40px;
      line-height: 26px;
      @media (max-width: 500px) {
        // margin-left: 20px;
        /* font-size: 14px; */
      }
    }

    .header-button {
      width: 143px;
      height: 40px;
      font-family: "TruenoRegular", Arial, sans-serif;
      color: #502773;
      font-size: 18px;
      background: #FFCF03;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // margin-left: 120px;
      // margin-bottom: 50px;
      @media (max-width: 500px) {
        // margin-left: 20px;
        /* font-size: 14px; */
      }
    }
  }


  .header-image-container {
    // flex: 1;

    .header-image { 
      width: 100%;
      // height: 50vh;
      height: 100%;
      object-fit: cover;
      @media (max-width: 810px) {
        height: 400px;
      }

      @media (max-width: 500px) {
        height: 200px;
      }
    }
  }

  .app-button-container {
    // margin-left: 20px;
    @media (max-width: 500px) {
      // margin-left: 0;
      text-align: center;
      // margin-bottom: 10%;
    }
  }
`