import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Logo from "../../../../assets/images/cara-logo.png";
import styles from "../styles";
import { useTranslation } from "react-i18next";
import { GetGtpById } from "../../../../api/gtpAPI";
import Route from "../../../../assets/images/Route.svg";
import { useWindowWidth } from "../../../../lib/GetWIndowSize";

const Detail = ({ isLanding }) => {
  // Hooks
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const windowWidth = useWindowWidth();

  // State untuk menyimpan data GTP
  const [gtpDetail, setGtpDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Ambil data GTP saat komponen di-mount
  useEffect(() => {
    const fetchGtpDetail = async () => {
      try {
        const response = await GetGtpById(id);
        setGtpDetail(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGtpDetail();
  }, [id]);

  // Fungsi untuk tombol kembali
  const handleBackClick = () => {
    history.goBack();
  };

  // Fungsi untuk redirect ke Google Maps
  const handleDirectionsClick = () => {
    if (gtpDetail) {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        gtpDetail.address
      )}`;
      window.open(url, "_blank");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div style={styles.desktopContainer(windowWidth)}>
      {/* Navbar */}
      {!isLanding && (
        <img
          onClick={() => (window.location.href = "/")}
          style={styles.caraLogo}
          src={Logo}
        />
      )}

      <div style={styles.mainContainer}>
        {/* Tombol Kembali */}
        <button className="btn-back" onClick={handleBackClick}>
          {t("Back")}
        </button>

        {/* Konten Card */}
        {gtpDetail && (
          <div style={styles.cardContainer}>
            <div style={styles.titleContainer}>
              <h2 style={styles.title}>{gtpDetail.name}</h2>
            </div>
            <div style={styles.details}>
              <p>
                <strong>Region:</strong> {gtpDetail.region}
              </p>
              <p>
                <strong>GTP Name:</strong> {gtpDetail.name}
              </p>
              <p>
                <strong>Address:</strong> {gtpDetail.address}
              </p>
              <p>
                <strong>Postal Code:</strong> {gtpDetail.postalCode}
              </p>
              <p>
                <strong>Opening Hours:</strong> {gtpDetail.openingHours}
              </p>
              <p>
                <strong>Contact Number:</strong> {gtpDetail.contactNumber}
              </p>
              <p>
                <strong>Special Note:</strong> {gtpDetail.specialNote}
              </p>
            </div>
            <button className="btn-showRoute" onClick={handleDirectionsClick}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <img src={Route} alt="Route" />
                {t("Directions")}
              </div>
            </button>
          </div>
        )}
      </div>

      {/* Footer */}
      {/* <div style={styles.footerContainer}>
        <div style={styles.tipContainer}>
          <p style={styles.emergencyText}>{t("IfThisIsAnEmergencyText")}</p>
          <p style={styles.helplineText}>{t("DementiaHelplineText")}</p>

          <div style={styles.contactContainer}>
            <p style={styles.contactNumberText}>6377 0700</p>

            <a href="/" style={styles.websiteText}>
              cara.sg
            </a>
          </div>
        </div>
        <ToggleLanguages />
      </div> */}
    </div>
  );
};

export default Detail;
