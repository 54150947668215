import React, { useState, useEffect, } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import GoogleLogin from 'react-google-login';
import Swal from 'sweetalert2'
import validator from 'validator'
import {jwtDecode} from 'jwt-decode';

import Header2 from '../../components/header2'
import Input from '../../components/input'
import Dialog from '../../components/dialog'

import google from '../../assets/images/google.png'

import { signupPartner, getAddress, verifyToken } from '../../api/UserAPI'
// import { getAddress } from '../../api/OneMapAPI'

import ProccessData from '../../lib/ProccessData'

import { StyledRegistrationPartner, InputStyle } from './Styles/StylesRegistrationPartner'

const defaultParamsSignup = {
  "credentialType": "USERNAME",
  "partnerDetails": {
      "organizationName": "",
      "pocName": "",
      "address": "",
      "blockNumber": "",
      "building": "",
      "streetName": "",
      "pocPhone": "",
      "pocEmail": "",
      "hotlineNumber": "",
      "postalCode": "",
      "jobTitle": "",
      "unitNumber": "",
      "branch": "",
      "createdBy": ""
  }
}

export const RegistrationPartner = ({ history }) => {
  const location = useLocation();
  const historyDom = useHistory();
  const [state, setState] = useState({
    paramsSignup: defaultParamsSignup,
    dialogSucess: false,
    loading: false,
  })

  const queryParams = new URLSearchParams(location.search);
  const tokenAdmin = queryParams.get('token');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if(token) {
      tokenCheck(token).then(res => {
        setToken(token)
      })
    }
  }, [])

  const setToken = async (token) => {
    try {
      const decodedToken = jwtDecode(token)

      if(decodedToken) {
        setState({
          ...state, 
          paramsSignup: {
            ...state.paramsSignup,
            partnerDetails: {
              ...state.paramsSignup.partnerDetails,
              createdBy: decodedToken.uid
            }
          } 
        })
      }
    } catch (err) {
      alertError(err, true)
    }
  }

  const tokenCheck = async (token) => {
    try {
      let resultCheck = {}
      resultCheck.payload = await verifyToken({ headers: {
        Authorization: `${token}`
      }})
      ProccessData(resultCheck  , 'check token')
      .then(res => {
        // console.log('res => ', res)
      })
      .catch(err => {
        // console.log('err => ', err)
        alertError(err, true)
      })
    } catch (e) {
      alertError(e, true)
    }
  }
  

  const signupUser =  async () => {
    try {
      let resultAdd = {}
      if (!validator.isEmail(state.paramsSignup.partnerDetails.pocEmail)) {
        alertError('Please input valid email')
      } else {
        setState(state => ({ ...state, loading: true }))
        resultAdd.payload = await signupPartner(state.paramsSignup)
        ProccessData(resultAdd, 'add partner')
        .then(res => {
          setState(state => ({ 
            ...state, 
            paramsSignup: defaultParamsSignup,
            dialogSucess: true,
          }))
        })
        .catch(err => {
          alertError(err)
        })
      }
    } catch (e) {
      alertError(e)
    }

    setState(state => ({ ...state, loading: false }))
  }

  const alertError = (e) => {
    Swal.fire({
      title: 'Error!',
      text: e,
      icon: 'error',
      confirmButtonText: 'OK'
    })
  }

  const onChangeText = (name, value) => {
    setState(state => {  
      const { partnerDetails } = state.paramsSignup;

      partnerDetails[name] = value;

      return {
        ...state,
        paramsSignup: {
          ...state.paramsSignup,
          partnerDetails,
        }
      }
    })
  }

  // const onGetAddress = async ({ value }) => {
  //   try {
  //     let resultGet = {}
  //     resultGet.payload = await getAddress({
  //       searchVal: `${value}`,
  //       returnGeom: `Y`,
  //       getAddrDetails: `Y`,
  //     })
  //     resultGet.payload.type = 'SUCCESS'
  //     ProccessData(resultGet, 'get address')
  //     .then(res => {
  //       if (res.results.length > 0) {
  //         setState(state => {
  //           const { partnerDetails } = state.paramsSignup;
  
  //           partnerDetails[`address`] = `${res.results[0].ADDRESS.replace(value, '')}`;
  //           partnerDetails[`streetName`] = `${res.results[0].ROAD_NAME}`;
  //           partnerDetails[`building`] = `${res.results[0].BUILDING}`;
  //           partnerDetails[`blockNumber`] = `${res.results[0].BLK_NO}`;
  
  //           return {
  //             ...state,
  //             paramsSignup: {
  //               ...state.paramsSignup,
  //               partnerDetails,
  //             }
  //           }
  //         })
  //       }
  //     })
  //     .catch(err => {
  //       alertError(err)
  //     })
  //   } catch (e) {
  //     alertError(e)
  //   }
  // }

  const onGetAddress = async ({ value }) => {
    try {
      let resultGet = {}
      resultGet.payload = await getAddress({
        searchVal: `${value}`,
        returnGeom: `Y`,
        getAddrDetails: `Y`,
      })
      resultGet.payload.type = 'SUCCESS'
      ProccessData(resultGet, 'get address')
      .then(res => {
        if (res.data.results.length > 0) {
          setState(state => {
            const { partnerDetails } = state.paramsSignup;
  
            partnerDetails[`address`] = `${res.data.results[0].ADDRESS.replace(value, '')}`;
            partnerDetails[`streetName`] = `${res.data.results[0].ROAD_NAME}`;
            partnerDetails[`building`] = `${res.data.results[0].BUILDING}`;
            partnerDetails[`blockNumber`] = `${res.data.results[0].BLK_NO}`;
  
            return {
              ...state,
              paramsSignup: {
                ...state.paramsSignup,
                partnerDetails,
              }
            }
          })
        }
      })
      .catch(err => {
        alertError(err)
      })
    } catch (e) {
      alertError(e)
    }
  }

  const { organizationName, branch, unitNumber, postalCode, pocName, jobTitle, address , pocPhone , pocEmail , hotlineNumber, blockNumber, building, streetName } = state.paramsSignup.partnerDetails

  const buttonDisabled = organizationName === '' || pocName === '' || address === '' || pocPhone === '' || pocEmail === '' || hotlineNumber === '' || postalCode === '' || state.loading

  return (
    <StyledRegistrationPartner buttonDisabled={buttonDisabled}>
      <Dialog
        isVisible={state.dialogSucess}
        title={"Your account application has been submitted!"}
        additionalStyle={{
          titleStyle: `
            width: 296px;
            text-align: center;
            margin: 0 auto;
          `,
          descriptionStyle: `
            width: 362px;
            text-align: center;
            margin: 0 auto;
            margin-top: 28px;
          `
        }}
        description={tokenAdmin ? `Registration completed successfully.` : `Please note that it will take our admin 5-7 working days to process your request. An onboarding email will be sent to you after approval to setup your credentials.`}
        showXClose={false}
        height={`auto`}
        okText="OK"
        okBordered={false}
        okBackgroundColor={`#512874`}
        okTextColor={`#FFF`}
        onOk={() => {
          setState(state => ({ ...state, dialogSucess: false }))
          history.push('/')
        }}
      />
      <Header2 />
      <div className="form-container">
        <p className="registration-title">Sign up as Partner</p>
        <div className="input-container">
          <div>
            <label for='organization' className="label-partner">Organisation Name</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'organization',
              onChange: (e) => onChangeText('organizationName', e.target.value),
              maxLength: 50,
              value: organizationName,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='branch' className="label-partner">Branch (<span style={{ color: '#FF5757' }}>if applicable</span>)</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'branch',
              onChange: (e) => {
                onChangeText('branch', e.target.value)
              },
              maxLength: 50,
              value: branch,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='postalCode' className="label-partner">Postal Code</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'postalCode',
              onChange: (e) => {
                onChangeText('postalCode', e.target.value)
                e.target.value !== '' && onGetAddress({ value: e.target.value })
              },
              maxLength: 50,
              type: `number`,
              value: postalCode,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='street-name' className="label-partner">Street Name</label>
          </div>
          <Input 
            containerStyle={InputStyle}
            inputProps={{
              id: 'street-name',
              onChange: (e) => onChangeText('streetName', e.target.value),
              value: streetName,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='block-number' className="label-partner">Block Number</label>
          </div>
          <Input 
            containerStyle={InputStyle}
            inputProps={{
              id: 'block-number',
              onChange: (e) => onChangeText('blockNumber', e.target.value),
              value: blockNumber,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='building' className="label-partner">Building</label>
          </div>
          <Input 
            containerStyle={InputStyle}
            inputProps={{
              id: 'building',
              onChange: (e) => onChangeText('building', e.target.value),
              value: building,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='address' className="label-partner">Address</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            multiline
            rows={3}
            inputProps={{
              id: 'address',
              onChange: (e) => onChangeText('address', e.target.value),
              maxLength: 200,
              value: address,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='unitNumber' className="label-partner">Unit Number (<span style={{ color: '#FF5757' }}>if applicable</span>)</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'unitNumber',
              onChange: (e) => {
                onChangeText('unitNumber', e.target.value)
              },
              maxLength: 50,
              value: unitNumber,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='pocname' className="label-partner">Name of main point of contact (P.O.C)</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'pocname',
              onChange: (e) => onChangeText('pocName', e.target.value),
              maxLength: 50,
              value: pocName,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='jobTitle' className="label-partner">Title of main P.O.C</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'jobTitle',
              onChange: (e) => onChangeText('jobTitle', e.target.value),
              maxLength: 50,
              value: jobTitle,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='pocnumber' className="label-partner">Phone Number of P.O.C</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'pocnumber',
              onChange: (e) => onChangeText('pocPhone', e.target.value),
              maxLength: 5,
              type: `number`,
              value: pocPhone,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='pocemail' className="label-partner">Email of P.O.C</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'pocemail',
              onChange: (e) => onChangeText('pocEmail', e.target.value),
              maxLength: 50,
              value: pocEmail,
          }}/>
        </div>
        <div className="input-container">
          <div style={{ width: '300px'  }}>
            <label for='hotlinenumber' className="label-partner">Centre Number</label>
            <label for='hotlinenumber' className="label-partner" style={{ fontSize: '14px',}}>Note: This number will be revealed to public when PLWD requires assistance</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'hotlinenumber',
              onChange: (e) => onChangeText('hotlineNumber', e.target.value),
              maxLength: 50,
              type: `number`,
              value: hotlineNumber,
          }}/>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '60px', }}>
          <div className="button-back" onClick={() => history.goBack()}>
            <div className="button-back-text">Back</div>
          </div>
          <div style={{ width: '15px', height: 1 }} />
          <div className="button-register" onClick={() => {
            buttonDisabled ? null : signupUser() 
          }}>
            <div className="button-register-text">{state.loading ? `Loading...` : `Register`}</div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '37px', }}>
          <a className="link-text" href='https://cara.sg/privacy-policy/'>
            Privacy Policy
          </a>
          <div style={{ width: `5px`, height: `5px`, borderRadius: `${5/2}px`, background: `#BBCADB`, position: 'relative', top: '2px', margin: '0 10px' }} />
          <a className="link-text" href='https://cara.sg/cara-membership-terms-condition/'>Membership Terms and Condition</a>
          <div style={{ width: `5px`, height: `5px`, borderRadius: `${5/2}px`, background: `#BBCADB`, position: 'relative', top: '2px', margin: '0 10px' }} />
          <a className="link-text" href='https://cara.sg/terms-of-use/'> Terms of Use</a>
        </div>
      </div>
      {/* <div className="oauth-container">
        <div style={{
          fontFamily: "TruenoRegular",
          fontSize: '20px',
          color: `#424242`,
          marginBottom: '20px',
        }}>
          Or sign up with
        </div>
        <div>
        <GoogleLogin
          clientId={REACT_APP_CLIENT_ID}
          render={renderProps => {
            return ( 
              <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="google"><img style={{margin:'auto'}} src={google}/></button>
            )
          }}
            // onSuccess={this.responseGoogle}
            // onFailure={this.responseGoogleFail}
          cookiePolicy={'single_host_origin'}
        />
        </div>
      </div> */}
    </StyledRegistrationPartner>
  )
}


export default RegistrationPartner