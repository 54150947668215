import React, { useState } from 'react'

import { Container, Row, Col, } from 'react-grid-system'
import Swal from 'sweetalert2'

import Header from '../../components/header'
import Button from '../../components/button'
import Dialog from '../../components/dialog'
import AppConfig from '../../config'

import { StyledPartnerConfirmation } from '../styles/StyledPartnerConfirmation'
import { tagAccount } from '../../api/UserAPI'

import ProccessData from '../../lib/ProccessData'

function PartnerConfirmation({
  history,
  location,
}) {
  console.log('location state => ', location)
  const initialState = location.state ? {
    uid:  location.state.uid,
    token:  location.state.token,
    streetName: location.state.streetName,
    unitNumber: location.state.unitNumber,
    postalCode: location.state.postalCode,
    cityCountry:  location.state.cityCountry,
    masterPartnerUid: location.state.masterPartnerUid,
    partnerToken: location.state.partnerToken
  } : {}

  const [state, setState] = useState({
    ...initialState,
    dialogSuccess: false,
  })

  const tagCG = () => { 
    history.push({pathname:'/registrationTagExistingCG',
      state:{
        uid: state.uid,
        token: state.token,
        streetName: state.streetName,
        unitNumber: state.unitNumber,
        postalCode: state.postalCode,
        cityCountry: state.cityCountry,
        masterPartnerUid: state.masterPartnerUid,
        partnerToken: state.partnerToken,
      }
    })

    return state
  }

  const tagAccountFunc = async () => {
    console.log('state => ', state)
    try {
      let resultCheck = {}
      resultCheck.payload = await tagAccount({ data: {
        "uidTags": state.masterPartnerUid,
        "uidTagged": state.uid,
        "status": "ACCEPTED",
        "relationship": "Care Partner",
        "isWeb": true
      }})
      ProccessData(resultCheck  , 'tag accounta')
      .then(res => {
        console.log('res => ', res)
        setState(state => ({
            ...state,
            dialogSuccess: true
          })
        )
      })
      .catch(err => {
        console.log('err => ', err)
        alertError(err)
      })
    } catch (e) {
      console.log('e =>', e)
      alertError(e)
    }
  }

  const alertError = (text) => {
    Swal.fire({
      title: 'Error!',
      text,
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }

  return (
    <StyledPartnerConfirmation>
      <Dialog
        isVisible={state.dialogSuccess}
        height="auto"
        showXClose={false}
      >
        <p className="dialog-text">Your Organisation has been appointed as PLWD’s Point of Contact</p>
        <Button
          text="OK"
          buttonStyle={` width: 100%; height: 60px; display: flex; justify-content: center; align-items: center; `}
          textSyle={` margin-top: 17px; `}
          onClick={() => {
            setState(state => ({
                ...state,
                dialogSuccess: false
              })
            )
            sessionStorage.clear();
            window.open(`${AppConfig.ADMIN_HOSTNAME}/users`, "_self")
            // history.replace('/')
          }}
        />
      </Dialog>
      <Container fluid style={{ margin: 0, padding: 0, }}>
        <div className="base-container">
          <Header title="Create PLWD Account" />
          <div>
            <p className="text">Organisation Staff or Next-of-Kin Information</p>

            <Row style={{ marginTop: '50px' }}>
              {/* <Col md={6} sm={12}>
                <Button
                  text="Look for an existing Organisation Staff"
                  buttonStyle={` width: 100%; height: 60px; display: flex; justify-content: center; align-items: flex-end; `}
                  textStyle={` margin-top: 10px; `}
                  backgroundColor='linear-gradient(316.97deg, #EFBA00 25.52%, #EFC900 82.61%)'
                  onClick={tagCG}
                />
                <p className="small-text">Connect your registration to an existing CARA Member - Organisation Staff</p>
              </Col> */}
              <Col md={12} sm={12}>
                <Button
                  text="Appoint Organisation as PLWD’s Point of Contact"
                  buttonStyle={` width: 100%; height: 60px; display: flex; justify-content: center; align-items: flex-end; `}
                  textSyle={` margin-top: 10px; `}
                  onClick={tagAccountFunc}
                />
                {/* <p className="small-text">Become the Organisation Staff for this PLWD Account</p> */}
              </Col>
            </Row>
          </div>
        
        </div>
      </Container>
    </StyledPartnerConfirmation>
  )
}


export default PartnerConfirmation

