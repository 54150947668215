import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledInput = styled.div`
  display: flex;
  width: 100%;
  background-color: ${Colors.color4};
  border-radius: 10px;
  margin: 15px 0;
  
  .input-field {  
    width: 100%;
    padding: 10px 18px;
    outline: none;
    cursor:  'pointer';
    font-family: "TruenoRegular", Arial, sans-serif;
    font-weight: bold;
    color: ${Colors.color};
    resize: none;
    ${Fonts.size.size24}};
  }

  input, textarea {
    background: white;
    border: none;
    outline: none;
    border: 1px solid #B1C2D8;
    border-radius: 15px;
  }
  
  ::placeholder {
    font-family: NunitoSans;
    color: ${Colors.color5};
    ${Fonts.size.size14}
  }

  .icon-right {
    width: 30px;
    object-fit: contain;
    margin-right: 14px;
    cursor: pointer;
    ${props => props.iconRightStyle}
  }

  ${props => props.containerStyle}
`

export const StyledDropdown = styled.div`  
  ul {
    background-color: ${Colors.color3};
    width: ${props => props.dropdownWidth};
    z-index: 2;
    position: absolute;
    height: ${props => props.showDropdown ? 'auto' : '0px'};
    padding: ${props => props.showDropdown ? '10px' : '0px'};
    overflow: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  ul li {
    ${Fonts.FontRegular}
    ${Fonts.size.size16}
    font-weight: 800;
    color: ${Colors.color2};
    margin-bottom: 10px;
  }

  ul li:hover {
    cursor: pointer;
  }
`