import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types'
import styled from "styled-components";

import { Colors, Fonts, Scale } from '../../themes';
function CardIcon(props) {
  
  const StyledCardIcon = styled.div`
    position: relative;
    width: 387px;
    height: 229px;
    padding:0;
    background-image:  url(${props.image}) ;

    background-repeat: no-repeat;

    background-size: 387px 229px;
    border-radius: 10px;

  }
  .title{
    position: absolute;
    bottom: 35px;
    left:35px;
    font-family: "TruenoRegular", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 20px;
    /* identical to box height, or 83% */
    display:flex;
    justify-content:center;
    align-items: center;

    color: #FFFFFF;
    text-shadow: 4px 1px 4px #1d1d1d;
  }
  .image{width:100%;
    margin:auto;
    border-radius: 10px;

  }
  .button-text {
    margin-top: 30px;
    margin-bottom: 50px;

    font-family: "TruenoRegular", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    color: ${Colors.color6};
  }
  @media (max-width:502px){
    width: 347px;
    height: 189px;

    background-size:347px 189px;
  }
`
  return (
    <StyledCardIcon>
      <h4 className="title">{props.title}</h4>
    </StyledCardIcon>
  )
}

export default CardIcon

