import React, { Component } from 'react';
import { Provider } from 'react-redux';

import AppNavigation from './containers/AppNavigation'


class App extends Component {
  render() {
    return (
      
       <AppNavigation />
      );
  }
}

export default App;
