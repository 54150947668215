import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledHeader = styled.div`
align-content:center;
 width:100%;
 padding:auto;
 margin:auto;

  h3{  
    font-family: ${Fonts.FontRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    /* identical to box height, or 142% */
    color: #502773;
  }
 
  
  
`