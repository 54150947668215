import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'

import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'

import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import colors from '../../themes/Colors';

import Select from 'react-select'
import Swal from 'sweetalert2'
import checklistborder from '../../assets/images/checklistborder.png'

import PopUpBox from '../../components/modal'
import axios from 'axios'

import PopUpBoxHandler from '../../components/modalhandler';

import AppConfig from '../../config'
import PopUpBoxConfirmation from '../../components/modalConfirmation';

const accountUrl = AppConfig.ACCOUNT_URL

const relationshipStatusData =
[
  {
    id: 0,
    name: `Nephew / Niece`,
    value: `Nephew / Niece`,
    label:  `Nephew / Niece`
  },
  {
    id: 1,
    name:  'Child',
    value: `Child`,
    label:  `Child`
  },
  {
    id: 2,
    name:  'Grandchild',
    value: `Grandchild`,
      label:  `Grandchild`
  },
  {
    id: 3,
    name:  'Spouse',
    value: `Spouse`,
    label:  `Spouse`
  },
  {
    id: 4,
    name:  'Parent',
    value: `Parent`,
    label:  `Parent`
  },
  {
    id: 5,
    name:  'Grandparent',
    value: `Grandparent`,
    label: `Grandparent`
  },
  {
    id: 6,
    name:  `Uncle / Aunt`,
    value: `Uncle / Aunt`,
    label: `Uncle / Aunt`
  },
  {
    id: 7,
    name: 'Others, please specify',
    isCustom: false,
    value: `Others, please specify`,
    label: `Others, please specify`
  }
]

import { getAddress } from '../../api/UserAPI'
import ProccessData from '../../lib/ProccessData'

export class createNewCGAddress extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            phone:this.props.location.state.phone,
            username:this.props.location.state.username,
            password:this.props.location.state.password,
            firstname: this.props.location.state.firstname,
            lastname:this.props.location.state.lastname,
            displayName:this.props.location.state.displayName,
            gender:this.props.location.state.gender,
            birth:this.props.location.state.birth,
            lastNric:this.props.location.state.lastNric,
            uid:this.props.location.state.uid,
            findCaraList: this.props.location.state.findCaraList,
            adminUid: this.props.location.state.adminUid,
            streetName:'',
            unitNumber:'',
            postalCode:'',
            cityCountry:'',
            building:this.props.location.building,
            blockNumber:this.props.location.blockNumber,
            relationship:this.props.location.state.relationship,
            relationshipStatus:this.props.location.state.relationshipStatus,
            showError:false,
            showConfirmation:false,
            errorMessage:'',
            agree: true,
            agree1: false,
            agree2: false,
        Switch: SwitchNo,
          show: false,
        }
      }
    handleShow = (e)=> {
    
     this.setState({show: true})
    }
    
    handleShowConfirm=()=>{
      if((this.state.streetName ==''  || window.sessionStorage.getItem("streetName") == '' || window.sessionStorage.getItem("streetName") == 'null') || (this.state.postalCode == '' || window.sessionStorage.getItem("postalCode") == '')  || (this.state.cityCountry =='' || window.sessionStorage.getItem("cityCountry") == '') ){
        Swal.fire({
          title: 'Warning!',
          text: 'please fill the required data',
          icon: 'info',
          confirmButtonText: 'OK'
        })
      }else{
      this.setState({showConfirmation:true})
      }
  }
  componentDidMount(){
    if(window.sessionStorage.getItem("streetName") != '' && window.sessionStorage.getItem("unitNumber") != 'null'){
      this.setState({streetName: window.sessionStorage.getItem("streetName")})
 
    }
     if(window.sessionStorage.getItem("unitNumber") != '' && window.sessionStorage.getItem("unitNumber") != 'null'){
      this.setState({unitNumber: window.sessionStorage.getItem("unitNumber")})
 
    }
    if(window.sessionStorage.getItem("postalCode") != '' && window.sessionStorage.getItem("postalCode") != 'null'){
      this.setState({postalCode: window.sessionStorage.getItem("postalCode")})
 
    }
    if(window.sessionStorage.getItem("cityCountry") != '' && window.sessionStorage.getItem("cityCountry") != 'null'){
      this.setState({cityCountry: window.sessionStorage.getItem("cityCountry")})
    }
  // if(window.sessionStorage.getItem("relationshipStatus") != ''){
  //   this.setState({relationshipStatus: window.sessionStorage.getItem("relationshipStatus")})
  // }
  // if(window.sessionStorage.getItem("relationshipStatus") != null){
  //   this.setState({relationship: relationshipStatusData[window.sessionStorage.getItem("relationshipStatus")].value})
  // }
  
  }
  
  handleNo = ()=>{
    window.sessionStorage.setItem("Switch",this.state.Switch),
    
    window.sessionStorage.setItem("streetName",this.state.streetName),
    window.sessionStorage.setItem("unitNumber",this.state.unitNumber),
    window.sessionStorage.setItem("postalCode",this.state.postalCode),
    window.sessionStorage.setItem("cityCountry",this.state.cityCountry),
    window.sessionStorage.setItem("building",this.state.building),
    window.sessionStorage.setItem("blockNumber",this.state.blockNumber),
    window.sessionStorage.setItem("relationshipStatus",this.state.relationshipStatus);

    this.props.history.push({pathname:'/registrationCreateNewCGDetail',
    
    state:{
      username: this.state.username,
    password: this.state.password,
    phone: this.state.phone,
    findCaraList: this.state.findCaraList,
    
    uid:this.state.uid,
    streetName:this.state.streetName,
    unitNumber:this.state.unitNumber,
    postalCode:this.state.postalCode,
    cityCountry:this.state.cityCountry,
    building: this.state.building,
    blockNumber: this.state.blockNumber,
    firstname: this.state.firstname,
    lastname:this.state.lastname,
    displayName:this.state.displayName,
    gender:this.state.gender,
    birth:this.state.birth,
    lastNric:this.state.lastNric,
    adminUid: this.state.adminUid,

      streetName:this.state.streetName,
      unitNumber:this.state.unitNumber,
      postalCode:this.state.postalCode,
      cityCountry:this.state.cityCountry,
      building: this.state.building,
      blockNumber: this.state.blockNumber,
    
    streetName:this.props.location.state.streetName,
    unitNumber:this.props.location.state.unitNumber,
    postalCode:this.props.location.state.postalCode,
    cityCountry:this.props.location.state.cityCountry,
    building: this.props.location.state.building,
    blockNumber: this.props.location.state.blockNumber,
    }})
    this.setState({showConfirmation:false})
  }
  handleYes = ()=>{
    this.setState({showConfirmation:false})
   this.handleShow()
  }
    handleClose = (e)=> {
        
        this.props.history.push({pathname:'/registrationNotification',
        state:{
            username: this.state.username,
            password: this.state.password,
            phone: this.state.phone,
            streetName:this.state.streetName,
            unitNumber:this.state.unitNumber,
            postalCode:this.state.postalCode,
            cityCountry:this.state.cityCountry,
            building: this.state.building,
            blockNumber: this.state.blockNumber,
            firstname: this.state.firstname,
            lastname:this.state.lastname,
            displayName:this.state.displayName,
            gender:this.state.gender,
            birth:this.state.birth,
            findCaraList: this.state.findCaraList
        
        
          }})
          
          console.log(this.state.username)
          console.log(this.state.password)
          console.log(this.state.phone)
          console.log(this.state.streetName)
          console.log(this.state.unitNumber)
          console.log(this.state.postalCode)
          console.log(this.state.cityCountry)
          console.log(this.state.firstname)
          console.log(this.state.lastname)
          console.log(this.state.gender)
          console.log(this.state.birth)
          return this.state
          
   
        this.setState({show: false})
    }  
 changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes,
                     streetName:this.props.location.state.streetName,
                     unitNumber:this.props.location.state.unitNumber,
                     postalCode:this.props.location.state.postalCode,
                     cityCountry:this.props.location.state.cityCountry,
                     building:this.props.location.state.building,
                     blockNumber:this.props.location.state.blockNumber
                    })
   }else if(this.state.Switch== SwitchYes ){ 
    this.setState({Switch: SwitchNo, 
     streetName:'',
     unitNumber:'',
     postalCode:'',
     cityCountry:'',
     building:'',
     blockNumber:''
    })
}
  }
  
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
  handleShow = ()=> {
     axios.post(`${accountUrl}/public/accounts/createcaregiver`, {
            uid: this.state.uid,
            relationship: this.state.relationship,
            caregiver: {
              credential : {
                credentialType: "USERNAME",
                credentialData: {
                  username:this.state.username,
                  password:this.state.password
                },
                accountData:{
                      prefixCountryNum: this.state.phone.slice(0,3),
                      phone: this.state.phone.slice(3,),
                      email: "",
                      firstname: this.state.firstname,
                      lastname: this.state.lastname,
                      displayName:this.state.displayName,
                      gender: this.state.gender,
                      birth: this.state.birth,
                      streetName: this.state.streetName,
                      unitNumber: this.state.unitNumber,
                      postalCode: this.state.postalCode,
                      cityCountry: this.state.cityCountry,
                      building: this.state.building,
                      blockNumber: this.state.blockNumber,
                      createdBy: this.state.adminUid,
                      interests: "-",
                      activityPreferences: "-",
                      homeNumber: '',
                      lastNric: this.state.lastNric,
                      registrationPlatform: "WEB",
                      metadata: {
                        MoP: false,
                        caraPro: true
                      },
                  }
              }
         }
       })
       .then(res => {
           console.log(res)
         if(res.status==200 && res.data.type=="SUCCESS"){
          sessionStorage.clear();
  this.setState({show: true})
         }else{
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
         };
       }, (error) => {
         console.log(error);
       });
 
 }
    
 handleChangeRelationship =(e)=> {
  this.setState({relationship:e.value})
  // console.log('relationship', this.state.relationship )
  this.setState({relationshipStatus:e.id})
}; 
 agreeSubmit = (e)=>{
       
  if(this.state.agree1){
      this.setState({agree1:false,agree:true})
  }else{
      this.setState({agree1:true}, () => {
          console.log(`agree1: ${this.state.agree1}`);
          this.agreeSubmit3()
      });
  }
}
agreeSubmit1  = (e)=>{
 
  if(this.state.agree2){
      this.setState({agree2:false,agree:true})
  }else{
  
      this.setState({agree2:true},() => {
          console.log(`agree2: ${this.state.agree2}`);
          this.agreeSubmit3()
      });
  }
}
agreeSubmit3 = ()=>{
 

  
  if (this.state.agree1 == true && this.state.agree2 == true){
      this.setState({agree:false})

  }
}

back=()=>{
  this.props.history.push({
    pathname: '/registrationCreateNewCGDetail',
    state: {
      streetName: this.state.streetName,
      unitNumber: this.state.unitNumber,
      postalCode: this.state.postalCode,
      cityCountry: this.state.cityCountry,
      building: this.state.building,
      blockNumber: this.state.blockNumber,
      phone:this.state.phone,
      username:this.state.username,
      password:this.state.password,
      firstname: this.state.firstname,
      lastname:this.state.lastname,
      displayName:this.state.displayName,
      gender:this.state.gender,
      birth:this.state.birth,
      lastNric:this.state.lastNric,
      uid:this.state.uid,
      findCaraList:this.state.findCaraList
    }
  });
}
handleCloseError =()=>{
  this.setState({showError:false})
}

onGetAddress = async ({ value }) => {
  try {
    let resultGet = {}
    resultGet.payload = await getAddress({
      searchVal: `${value}`,
      returnGeom: `Y`,
      getAddrDetails: `Y`,
    })
    resultGet.payload.type = 'SUCCESS'
    ProccessData(resultGet, 'get address')
    .then(res => {
      console.log('res address => ', res)
      if (res && res.data && res.data.results && res.data.results.length > 0) {
        this.setState({
          // streetName: `${res.results[0].ADDRESS.replace(value, '')}`
          streetName: `${res.data.results[0].ROAD_NAME}`,
          building: `${res.data.results[0].BUILDING}`,
          blockNumber: `${res.data.results[0].BLK_NO}`,
          cityCountry: 'Singapore'
        })
      }
    })
    .catch(err => {
      alert(err)
    })
  } catch (e) {
    alert(e)
  }
}
  render() {
      const {agree,Switch,streetName,unitNumber,postalCode,cityCountry,building,blockNumber} = this.state
    return (
      <>
        <StyledCG>
            <Header/>
            <h2 style={{marginTop:'6%',marginBottom:'6%'}}>Caregiver / Next-of-Kin Residing Address</h2>
                <Row>
                <Col>
                    <label>Same as Person Living with Dementia?</label>
                </Col>
                <Col style={{display:'flex', justifyContent:'flex-end'}}>
                    <button  onClick={this.changeSwitch}>
                            <img src={Switch} />
                    </button>
                </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Postal Code</label>
                    </Col>
                    <Col md={6}> 
                    <Input containerStyle="InputStyle" 
                      inputProps={{
                        placeholder:'Type your postal code here...',
                        name:"postalCode",
                        value:postalCode,
                        onChange: (e) => {
                          this.onChangeText('postalCode', e.target.value)
                          this.onGetAddress({ value: e.target.value })
                        }
                        }}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Street Name</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your street name here...',name:"streetName",
                                                                    value:streetName,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('streetName', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Building</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your building here...',name:"building",
                                                                    value:building,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('building', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Block Number</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your block number here...',name:"blockNumber",
                                                                    value:blockNumber,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('blockNumber', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Unit Number</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your unit number here...',name:"unitNumber",
                                                                    value:unitNumber,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('unitNumber', e.target.value),}}/>
                   
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>City, Country</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your city country here...',name:"cityCountry",
                                                                    value:cityCountry,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('cityCountry', e.target.value),}}/>
                    </Col>
                </Row>
                {/* <Row style={{marginTop:'20px'}}>
                    <Col md={6} style={{margin:'auto'}}>
                        <label>The Caregiver / Next-of-Kin is my </label>
                    </Col>
                    <Col md={6} >
                    <Select  onChange={(e) =>  this.handleChangeRelationship(e)} defaultValue={relationshipStatusData[window.sessionStorage.getItem("relationshipStatus")] } options={relationshipStatusData} />
                    </Col>
                    
                </Row> */}
                <Row style={{lineHeight:'18px'}}>
                  <Col md={6}></Col>
                  <Col md={6} className='p'>
                    <Row style={{paddingLeft:'5%',marginTop:'3%'}}>   <p style={{color:'#758BA9', fontWeight:'normal',textAlign:'start'}}>
                      * By clicking next, you consent to ADA collecting your information for the delivery of service. </p></Row>
                      <Row  style={{marginTop:'2%',paddingLeft:'5%', marginBottom:'2%'}}>   <p  style={{color:'#758BA9', fontWeight:'normal',textAlign:'start'}}> For more details on our terms of use and privacy policy, please  click </p>  <a href='https://cara.sg/privacy-policy/' target="_blank"  style={{color:'#758BA9',paddingLeft:'5px', fontWeight:'bold',}}>here</a>.</Row>
                  </Col>
                </Row>
                <Row style={{marginTop:'3%',marginBottom:'5%'}}>
                  <Col md={6}></Col>
                  <Col md={6}>
                      <Row style={{paddingLeft:'15px'}}><Col md={1}>  <label className="containerCheck"><input type="checkbox" onClick={this.agreeSubmit} /><span className="checkmark"></span></label></Col><Col md={10} ><p style={{color:'#758BA9',fontSize:'16px'}}>I agree to the terms of use.</p></Col>
                      </Row>
                      <Row style={{paddingLeft:'15px',marginTop:'3%'}}><Col md={1}>  <label  className="containerCheck" ><input type="checkbox" onClick={(e) => this.agreeSubmit1(e)}/><span className="checkmark"></span></label></Col><Col md={10} ><p style={{color:'#758BA9',fontSize:'16px'}}>I hereby declare that the details furnished above are true and correct to the best of my knowledge.</p></Col>
                      </Row>
                  </Col>
                </Row>
                
                <Row >
                <Col md={6}></Col>
                 <Col md={3}>
                <Button  onClick={this.back} buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} backgroundColor={colors.color3} textColor={colors.color11} bordered={colors.color11} text="Back" />
                </Col>
                <Col md={3}>
                <Button buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} disabled={agree} containerStyle={StyledButton} textStyle={StyledButton} text="Next" onClick={this.handleYes}/>
                {/* <PopUpBoxConfirmation showConfirmation={this.state.showConfirmation} handleNo={this.handleNo} handleYes={this.handleYes}/> */}
                <PopUpBox body='Thanks for submitting details for your Caregiver or Next-of-Kin!' show={this.state.show}  handleClose={this.handleClose}/>
                <PopUpBoxHandler show={this.state.showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
          
                </Col></Row>
           
            </StyledCG>

        
       
      </>
    )
  }
}


export default createNewCGAddress