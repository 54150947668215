import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types'

import { StyledCardImage } from './StyledCardImage'

function CardImage(props) {
  return (
    <StyledCardImage>
     <div style={{maxWidth:"427px"}} className="border-light text-white">
      <img src={props.image} className='image' alt="Card image" />
      <div className="title">{props.title}</div>
        <div className="text">{props.text}</div>   
      </div>
    </StyledCardImage>
  )
}

export default CardImage

