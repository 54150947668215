const ProccessData = (data, action) => {
  let processedData = {
    fetching: false,
    payload: null,
    error: null,
  }
  if (data.fetching) processedData.fetching = data.fetching
  if (data.payload) processedData.payload = data.payload
  if (data.error) processedData.error = data.error
  const { fetching, payload, error, } = processedData

  return new Promise((resolve, reject) => {
    if (!fetching) {
      if (payload) {
        if (payload.type === 'SUCCESS' || payload.success) {
          resolve(payload)
        } else {
          let errorMessage = payload.message ? payload.message : payload.data.message ? payload.data.message : payload.error ? payload.error : `Error ${action}` 
          reject(errorMessage)
        }
      }
      
      if (error) {
        reject(error.problem ? error.problem : 'ERROR')
      }
    }
  })
}

export default ProccessData