import React, { Component } from 'react'
import ReactGA from 'react-ga';

import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'

import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import colors from '../../themes/Colors';

import checklistborder from '../../assets/images/checklistborder.png'

import PopUpBox from '../../components/modal'

import Swal from 'sweetalert2'

import axios from 'axios'

import AppConfig from '../../config'
import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'
import PopUpBoxHandler from '../../components/modalhandler';

const accountUrl = AppConfig.ACCOUNT_URL
export class pwdresidingAddressTerms extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
         
      username: this.props.location.state.username,
      password: this.props.location.state.password,
      phone: this.props.location.state.phone,
      firstname: this.props.location.state.firstname,
      lastname: this.props.location.state.lastname,
      gender : this.props.location.state.gender,
      birth: this.props.location.state.birth,
      NIRC:this.props.location.state.NIRC,
      language: this.props.location.state.language,
      dementiaType: this.props.location.state.dementiaType,
      dementiaStage: this.props.location.state.dementiaStage,
      uid:this.props.location.state.uid,

      agree: true,
      agree1: false,
      agree2: false,
      errorMessage:'',
      showError:false,
      streetName:'',
      unitNumber:'',
      postalCode:'',
      cityCountry:'',
        Switch: SwitchNo,
          show: false,
        }
      }
      
      componentDidMount(){
      
        if(window.sessionStorage.getItem("streetName") != '' && window.sessionStorage.getItem("streetName") != 'null'){
          this.setState({streetName: window.sessionStorage.getItem("streetName")})
        }
         if(window.sessionStorage.getItem("unitNumber") != '' && window.sessionStorage.getItem("unitNumber") != 'null'){
         this.setState({unitNumber: window.sessionStorage.getItem("unitNumber")})
       }
        if( window.sessionStorage.getItem("postalCode") != '' && window.sessionStorage.getItem("postalCode") != 'null'){
         this.setState({postalCode: window.sessionStorage.getItem("postalCode") })
       }
       if( window.sessionStorage.getItem("cityCountry") != '' && window.sessionStorage.getItem("cityCountry") != 'null'){
        this.setState({cityCountry: window.sessionStorage.getItem("cityCountry") })
      }
      }
 changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes,
        streetName:this.props.location.state.streetName,
        unitNumber:this.props.location.state.unitNumber,
        postalCode:this.props.location.state.postalCode,
        cityCountry:this.props.location.state.cityCountry,})
   }else if(this.state.Switch== SwitchYes ){ 
       this.setState({Switch: SwitchNo, 
        streetName:'',
        unitNumber:'',
        postalCode:'',
        cityCountry:'',})
   }
  }
  submitForm = ()=>{
    axios.post(`${accountUrl}/public/accounts/createdementia`, {
        uid: this.state.uid,
        relationship: "Friend",
        dementia: {
          credential : {
            credentialType: "USERNAME",
            credentialData: {
              username:this.state.username,
              password:this.state.password
            },
            accountData:{
              firstname: this.state.firstname,
              lastname: this.state.lastname,
              gender: this.state.gender,
              prefixCountryNum: "+62",
              phone: this.state.phone,
              birth: this.state.birth,
              lastNric: this.state.NIRC,
              streetName: this.state.streetName,
              unitNumber: this.state.unitNumber,
              postalCode: this.state.postalCode,
              cityCountry: this.state.cityCountry,
              dementiaDetail: {
                  language: this.state.language,
                  typeDementia: this.state.dementiaType,
                  stageDementia: this.state.dementiaStage
              },
              dementiaHospital: {
                  hospitalName: "-",
                  hospitalDepartment: "-",
                  appointment: "DAILY",
                  symptoms: "-"
              }
            }
          }
        }
      })
      .then(res => {
        console.log('res add pwd by caregiver => ', res)
        // ReactGA.event({
        //   uid: res.data.data.account.uid,
        //   action: 'Web landing dementia registered',
        //   typeRegisteed: 'DEMENTIA',
        //   registerType: 'BY_CAREGIVER'
        // });
        if(res.status==200   && res.data.type=="SUCCESS"){
          
          this.setState({show: true})
        }else{
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        };
      }, (error) => {
        console.log(error);
      });
  }
  handleClose = () =>{ 
    this.setState({show: false}) 
};

handleCloseError =()=>{
  this.setState({showError:false})
}
onChangeText = (stateName, value) => {
  this.setState({ [stateName]: value });
}; 
agreeSubmit = (e)=>{
   
    if(this.state.agree1){
        this.setState({agree1:false,agree:true})
    }else{
        this.setState({agree1:true}, () => {
            console.log(`agree1: ${this.state.agree1}`);
            this.agreeSubmit3()
        });
    }
}
agreeSubmit1  = (e)=>{
   
    if(this.state.agree2){
        this.setState({agree2:false,agree:true})
    }else{
    
        this.setState({agree2:true},() => {
            console.log(`agree2: ${this.state.agree2}`);
            this.agreeSubmit3()
        });
    }
}
agreeSubmit3 = ()=>{
   

    
    if (this.state.agree1 == true && this.state.agree2 == true){
        this.setState({agree:false})

    }
}
back=()=>{
  this.props.history.goBack()
}

  render() {
      
    const {Switch,streetName,unitNumber,postalCode,cityCountry,showError,agree} = this.state
    return (
      <>
        <StyledCG>
            <Header/>
            <h2>Residing Address</h2> 
            <Container>
                <Row>
                <Col>
                    <label>Same as Organisation Staff?</label>
                </Col>
                <Col>
                    <button  onClick={this.changeSwitch}>
                            <img src={Switch} />
                    </button>
                </Col>
                </Row>
            </Container>
            <Container>
            <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Street Name</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your street name here...',name:"streetName",
                                                                    value:streetName,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('streetName', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Unit Number</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your unit number here...',name:"unitNumber",
                                                                    value:unitNumber,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('unitNumber', e.target.value),}}/>
                   
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Postal Code</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your postal code here...',name:"postalCode",
                                                                    value:postalCode,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('postalCode', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>City,Country</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your city country here...',name:"cityCountry",
                                                                    value:cityCountry,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('cityCountry', e.target.value),}}/>
                    </Col>
                </Row>
                <Row style={{lineHeight:'18px'}}>
                    <Col md={6}></Col>
                    <Col md={6} className='p'>
                    <Row style={{paddingLeft:'5%',marginTop:'3%'}}> <p style={{color:'#758BA9', fontWeight:'normal',textAlign:'start'}}>* By clicking next, you consent to ADA collecting your information for the delivery of service.</p> </Row>
                       <Row style={{marginTop:'2%',paddingLeft:'5%', marginBottom:'2%'}}> <p style={{color:'#758BA9', fontWeight:'normal',textAlign:'start'}}>For more details on our terms of use and privacy policy, please click here.</p></Row>

                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                       <Row style={{paddingLeft:'15px'}}> <label className="containerCheck"><input type="checkbox" onClick={this.agreeSubmit}  /><span className="checkmark"></span></label><p style={{color:'#758BA9',fontSize:'16px'}}>I agree to the terms of use.</p>
                       </Row>
                       <Row style={{paddingLeft:'15px',marginTop:'3%'}}><Col md={2}>  <label  className="containerCheck" ><input type="checkbox" onClick={(e) => this.agreeSubmit1(e)}/><span className="checkmark"></span></label></Col><Col md={10} ><p style={{color:'#758BA9',fontSize:'16px'}}>I hereby declare that the details furnished above are true and correct to the best of my knowledge.</p></Col>
                       </Row>
                    </Col>
                </Row>
                
                <Row style={{marginTop:'20px'}}>
            <Col md={6}></Col>
                 <Col md={3}> 
                <Button containerStyle={StyledButton} textStyle={StyledButton}  onClick={this.back} buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} backgroundColor={colors.color3} textColor={colors.color11} bordered={colors.color11} text="Back" />
                </Col>
                <Col  md={3}>
                <Button containerStyle={StyledButton} textStyle={StyledButton}  buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} disabled={agree} onClick={this.submitForm} text="Next"  />
                <PopUpBox  show={this.state.show}  handleClose={this.handleClose}/>
                <PopUpBoxHandler  show={showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
                </Col></Row>
                
            </Container>
            </StyledCG>

        
       
      </>
    )
  }
}


export default pwdresidingAddressTerms