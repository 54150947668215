import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledTabsIndicator = styled.div`
  .nav {
    display: flex;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    border-bottom: 1px solid #B1C2D8;
  }

  .nav-item {
    color: #758BA9;
    text-decoration: none;
    transition: .3s;
    margin-right: 27px;
    padding-bottom: 16px;
    z-index: 1;
    font-family: 'DM Sans', sans-serif;
    position: relative;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    font-size: 24px;
    cursor: pointer;
    
    &:before {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: #dfe2ea;
      border-radius: 8px 8px 0 0;
      opacity: 0;
      transition: .3s;
    }
  }

  .nav-item:not(.is-active):hover:before {
    opacity: 1;
    bottom: 0;
  }


  .nav-item:not(.is-active):hover { color: #333; }

  .nav-indicator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    transition: .4s;
    height: 5px;
    z-index: 1;
    border-radius: 8px 8px 0 0;
  }
`