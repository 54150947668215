import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from './english.json'
import mandarin from './mandarin.json'

const resources = {
    en: {
        translation: english
      },
    md: {
        translation: mandarin
    }
};

function changeLanguage(newLanguage) {
  i18n.changeLanguage(newLanguage);
}


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",

    interpolation: {
      escapeValue: false
    }
  });

  export { i18n, changeLanguage };