import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import { Link } from 'react-router-dom';
import Button from '../../components/button';

import confirrmation from '../../assets/images/confirrmation.png';
import AppS from '../../assets/images/GPlay.png'
import Gplay from '../../assets/images/AppS.png'



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

export class confirmationRegistration extends Component {
  render() {
    return (
      <>
        <StyledCG>
            <Header/>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '8%', marginBottom: '10%'}}>
                <img className="img-notif" style={{ width: '10%' }} src={confirrmation}/>
                <p className="style-text-notif mt-5" style={{width: '60%', fontSize: 25, lineHeight: 1.5}}>Your registration is now being processed. You will receive a notification to inform you of the outcome of your registration within 7 working days. </p>
                <label style={{textAlign: 'center', width: '35%', fontSize: 15}} className="style-text-desc-notif">If you have questions, please reach us at <span style={{color: '#502773'}}>cara@dementia.org.sg</span></label>
                <div style={{ height: '60px' }}>
                  <Button buttonStyle={{height:'60px', width:'300px', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', marginTop: 30,}} containerStyle={StyledButton} textStyle={StyledButton} text="OK" onClick={(e) => this.props.history.push({pathname:'/'})}/>
                </div>
            </div>
            {/* <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '8%', marginBottom: '10%'}}>
                <img className="img-notif" src={confirrmation}/>
                <p className="style-text-notif">Your registration is now being processed. You will receive a notification to inform you of the outcome of your registration within 7 working days. </p>
                <p  className="style-text-notif">Please download the CARA mobile application to enjoy membership perks and receive notifications.</p>
                <div style={{display: 'flex', marginTop: '30px'}}>
                  <button style={{background:'none',border:'none', height: 100}} ><a href='https://play.google.com/store/apps/details?id=com.embreo.carasg'><img src={AppS}/></a></button>
                  <button  style={{background:'none',border:'none', height: 100}} ><a href='https://apps.apple.com/id/app/cara-sg/id1553855834'><img src={Gplay}/></a></button>
                </div>
                <label style={{textAlign: 'center',}} className="style-text-desc-notif">If you have questions, please reach us at <div style={{color: '#502773'}}>cara@alz.org.sg</div></label>
                <div style={{ height: '60px' }}>
                  <Button buttonStyle={{height:'60px', width:'300px', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', marginTop: 30,}} containerStyle={StyledButton} textStyle={StyledButton} text="OK" onClick={(e) => this.props.history.push({pathname:'/'})}/>

                </div>
            </div> */}
        </StyledCG>
      </>
    )
  }
}


export default confirmationRegistration