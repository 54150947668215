import React, { Component } from 'react';
import Navigation from '../components/navigation';
import Section1 from '../components/content/Section1'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './LandingPage.css'
import  '../components/navigation/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {StyledFaq} from './styles/StyledFaq'

import Faq from "react-faq-component";
class FAQ extends Component {
 
    constructor(props) {
        super(props)
        
        this.state = {
          data : {   title: "FAQ (How it works)",
          rows: [
            {
                title: "Lorem ipsum dolor sit amet,",
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
                  ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
                  In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
                  Fusce sed commodo purus, at tempus turpis.`,
            },
            {
                title: "Nunc maximus, magna at ultricies elementum",
                content:
                    "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.",
            },
            {
                title: "Curabitur laoreet, mauris vel blandit fringilla",
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: "What is the package version",
                content: <p>current version is 1.2.1</p>,
            },
        ],
    }
        }
        this.state = {isValid: false};
      }

    render(){
        
const styles = {
    // bgColor: 'white',
    titleTextColor: "#502773",
    rowTitleColor: "#502773",
    
    // rowContentColor: 'grey',
    // arrowColor: "red"
    rowTitleTextSize: '20px',
    rowTitleTextWeight:'700',
    fontFamily: `"Times New Roman", Arial, sans-serif`,
};

const data = {
    rows: [
      {
        title: "I was a member of the Safe Return Card (SRC). Do I need to register again?  ",
        content: "<p style={{paddingBottom:'10px'}}>Yes, please register for your CARA membership through the CARA mobile application or the webpage at cara.sg.  This is so that we can capture information updates and keep the most current version of your details, to facilitate a good user experience for you.</p> "
      },
      {
        title: "How long will the approval of membership take?  ",
        content: "<p style={{paddingBottom:'10px'}}>We aim to approve your membership within 7 working days.  However, we seek your patience that in the event of an influx of applications, it may take our team more time to approve your application. </p>"
      },
      {
        title: "Can I register more than one caregiver for a person with dementia?   ",
        content: "<p style={{paddingBottom:'10px'}}>Yes, you may!  This is dependent on the account approval of the person living with dementia, and his/her approval of the caregiver tag.  All subsequent caregivers will be approved once the tagging is accepted by the person living with dementia.</p> "
      },
      {
        title: "Can I register my Foreign Domestic Worker (FDW)? ",
        content: "<p style={{paddingBottom:'10px'}}>At this juncture, we do not encourage the caregiver to be a FDW as they may be short-term helpers.  For a better care experience, we wish to ensure we are able to contact a long-term caregiver when the need arises.</p>"
      },
      {
        title: "If I don’t have a mobile phone, what can I do?  ",
        content: "<p style={{paddingBottom:'10px'}}> At this point, we are unable to accommodate those without a mobile phone.  The current application processes are done through the CARA mobile application. However, please do call the hotline or email <b>cara@dementia.org.sg</b> so that we can further assist you, and keep you informed of subsequent phases that can accommodate non-mobile-phone users. <b>(For Caregivers with a phone, it is still possible to register for your loved one with dementia) </b></p> "
      },
      {
        title: "I need help with downloading and registration, where can I go for help?",
        content: "<p style={{paddingBottom:'10px'}}>If you require further assistance, please email <b>cara@dementia.org.sg</b> or contact our hotline.</p> "
      },
      {
        title: "Is Dementia Singapore a new organization?  ",
        content: "<p style={{paddingBottom:'10px'}}>No, we are not! Formerly known as Alzheimer's Disease Association (ADA), the organisation has been around since 1990, and has been Singapore’s leading establishment in dementia care.  To serve the larger dementia community in Singapore, ADA was rebranded to become Dementia Singapore. With our new branding, we hope to continue and improve on the current work we do and expand on existing services to further benefit the wider society. </p> "
      },
      {
        title: "What is CARA? ",
        content: "<p style={{paddingBottom:'10px'}}>CARA is a membership programme that offers members-only benefits from our list of partners such as: Gardens by the Bay, Singapore Zoo, Eu Yan Sang and many more. CARA also serves to enhance the Safe Return Card programme by providing members who are persons living with dementia, a unique QR code identifier so that they can continue to move freely and independently within a safe community, giving caregivers a greater peace of mind. CARA advocates for inclusion and facilitates a dementia-ready future so that persons living with dementia and their caregivers feel respected, supported and accepted by society. </p>"
      },
      {
        title: "How do I sign up as a member?",
        content: "<p style={{paddingBottom:'10px'}}>Please download the CARA mobile app and select the tab “sign-up” and you will be taken through the sign-up process. You will need to input details of both person living with dementia and caregiver for the sign-up process.</p>"
      },
      {
        title: "Why is my membership not approved yet after 7 working days?   ",
        content: "<p style={{paddingBottom:'10px'}}>There may be a few reasons such as technical issues or incomplete applicant information. There may be a need for our team to contact you to follow up on details that you have submitted.  Please do give us some time. </p>"
      },
      {
        title: "Do I need to provide medical documents?   ",
        content: "<p style={{paddingBottom:'10px'}}>No, you don’t! However, there is a section where you need to provide information (relevant to the persons living with dementia) on your medical appointments and hospital visits for your membership application. </p> "
      },
      {
        title: "Is the membership free?  ",
        content: "<p style={{paddingBottom:'10px'}}>Yes, it is! Memberships are free and available to persons living with dementia and caregivers. </p> "
      },
      {
        title: "Can my SRC still be used?  ",
        content: "<div style={{,marginBottom:'40px'}}><p style={{paddingBottom:'10px'}}>Yes, existing SRC members will still be able to enjoy the member benefits and the card can still be used as an identifier. However, CARA benefits will not extend to existing SRC members unless they register for a CARA membership.</p> </div>"
      },
      
    ]
  };
const config = {
    animate: false,
    arrowIcon: "+",
    // tabFocus: true
};
    return(
        <div style={{marginBottom:'40px'}}>
            <Navigation Home="/" Why="/" How="/" Social="/" Faq="/"/>
                <StyledFaq>
                    
                        <h3>Frequently Asked Questions</h3>
              <Faq
                data={data}
                styles={styles}
                config={config}
              />
                   
            </StyledFaq>
        </div>
        )
    }
}

export default FAQ