import React, { Component } from 'react'
import queryString from 'query-string';

import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import ReactDOM from 'react-dom'
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import PopUpBoxOtp from '../../components/modalotp';
import MultiSelect from '../../components/multiSelectDropdown'
import axios from 'axios'
import Indonesia from '../../assets/images/Indonesia.png'
import Singapore from '../../assets/images/Singapore.png'
import Malaysia from '../../assets/images/Malaysia.png'
import { Colors, Fonts, Scale } from '../../themes';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import PopUpBoxHandler from '../../components/modalhandler';

import Select, { components } from 'react-select'
import colors from '../../themes/Colors';
import Swal from 'sweetalert2'
import GoogleLogin from 'react-google-login';
import AppConfig from '../../config'
import { Link } from 'react-router-dom';

import { verifyPartnerToken, } from '../../api/UserAPI'

import ProccessData from '../../lib/ProccessData'

const countryCodeData =[
  
  {
    id: 0,
    name : '+65',
    value: '+65',
    label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Singapore} />+65 </Row>
  },{
    id: 1,
    name : '+62',
    value: '+62',
    label: <Row style={{ width:'200px', display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Indonesia} />+62 </Row>
  },
  
  {
    id: 2,
    name : '+60',
    value: '+60',
    label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Malaysia}  />+60  </Row>
  },
]

const stylesCountryCode = {
  
  control: base => ({
    ...base,
    fontFamily: Fonts.FontRegular,
    fontColor: Colors.color,
    fontSize: '14px',
    fontWeight:'bold',
    padding:'0',margin:'0'
  }),
  menu: base => ({
    ...base,
    fontFamily: Fonts.FontRegular,
    padding:'0',margin:'0',
    fontColor: Colors.color,
    fontSize: '14px',
    fontWeight:'bold'
  })
};

const accountUrl = AppConfig.ACCOUNT_URL

const clientID = AppConfig.CLIENT_ID
const eye = <FontAwesomeIcon icon={faEye} />;

export class RegistrationCG extends Component {
    constructor(props) {
        super(props)
        this.state = {
          credentialType: "USERNAME",
          satu:'',dua:'',tiga:'',empat:'',lima:'',enam:'',
          code:'',
          username:'',
          password :'',
          firstname: '',
          lastname:'',
          email:'',
          confirmPassword:'',
          phone:'',
            type: 'password',
            type2: 'password',
          show: false, 
          showError:false,
          errorMessage:'',
          enableBtn:false,
          idTokenGoogle:'',
          countryCode:'+65',
          defaultCountryCode:'+65',
          masterPartnerUid: null,
          partnerToken: queryString.parse(this.props.location.search).partnerToken,
          isInvited: queryString.parse(this.props.location.search).invitationId || null,
          howToFindCaraSelected: false,
          passwordShown: false,
          passwordShown2: false,
          findCaraList: [
            {
              id: 1,
              name: 'Search Engine (Google, Yahoo, etc)',
              display: 'Search Engine (Google, Yahoo, etc)',
              isActive: false
            },
            {
              id: 2,
              name:  'Recommendation by friend or collague',
              display: 'Recommendation by friend or collague',
              isActive: false
            },
            {
              id: 3,
              name: 'Social Media',
              display: 'Social Media',
              isActive: false
            },
            {
              id: 4,
              name:  'Poster at MRT / Bus interchange',
              display: 'Poster at MRT / Bus interchange',
              isActive: false
            },
            {
              id: 5,
              name: 'Others',
              display: 'Others',
              isActive: false
            }
          ],
          uidAdmin: this.props.location.state.uidAdmin
        }
      }
   handleHide = (e)=> {
    
     this.setState({show: false})
 } 

  componentDidMount() {
    const partnerToken = queryString.parse(this.props.location.search).partnerToken
    if (partnerToken) this.checkToken(partnerToken)  
  }

  checkToken = async (token) => {
    try {
      let resultCheck = {}
      resultCheck.payload = await verifyPartnerToken({ headers: {
        Authorization: `bearer ${token}`
      }})
      ProccessData(resultCheck  , 'check partner token')
      .then(res => {
        console.log('res => ', res)
        this.setState({
          masterPartnerUid: res.data.account.uid
        })
      })
      .catch(err => {
        console.log('err => ', err)
        this.alertError(err)
      })
    } catch (e) {
      this.alertError(e)
    }
  }

  alertError = (text, exit = true) => {
    Swal.fire({
      title: 'Error!',
      text,
      icon: 'error',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        console.log('exit => ', exit)
        if (exit) {
          this.props.history.replace('/')
        } else null
      },
    })
  }

 handleCountryCode =(e)=> {
  this.setState({countryCode:e.value}, ()=>{

    
    console.log('countryCode', this.state.countryCode )
  })
  
this.setState({defaultCountryCode:e.id})
}; 
 validatePass=()=>{
   if(this.state.password.length >5){
     this.setState({enableBtn:false})
   }
 }
 togglePasswordVisiblity = () => {
    if(this.state.passwordShown==false){

      this.setState({passwordShown :true});
      
      this.setState({type:'text'})

    }else{
      this.setState({type:'password'})
      this.setState({passwordShown :false});
      
    }
  };

  togglePasswordVisiblity2 = () => {
    if(this.state.passwordShown2==false){

      this.setState({passwordShown2 :true});
      
      this.setState({type2:'text'})

    }else{
      this.setState({type2:'password'})
      this.setState({passwordShown2 :false});
      
    }
  };
    
  responseGoogleFail = ()=> {
    Swal.fire({
      title: 'Warning!',
      text: 'This website uses cookies to help ensure you receive an optimal experience. Please activate cookies.',
      icon: 'info',
      confirmButtonText: 'OK'
    })
    
  }
 responseGoogle = (response) => {
   this.setState({credentialType: "GOOGLE"})
Swal.fire({
  title: 'Please enter your mobile number',
  html:
    // `<label style="color: #424242;">Username </label><input   id="swal-input1" class="swal2-input">` +
    `
      <div style="display: flex; align-items: center;">
        <div style="flex:2;">
          <select id="swal-select2" class="swal2-input" style="border-right: none; border-top-right-radius: 0; border-bottom-right-radius: 0;">
            <option value="+65">+65</option>
            <option value="+62">+62</option>
            <option value="+60">+60</option>
          </select>
        </div>
        <div style="flex: 7;">
          <input id="swal-input2" class="swal2-input" style="border-left: none; border-top-left-radius: 0; border-bottom-left-radius: 0;">  
        </div>
      </div>
    `,
    
    showCancelButton: true,
  preConfirm: () => {
     this.setState({username : '-'})
     this.setState({phone :document.getElementById('swal-input2').value})

     if(this.state.username =='' ||this.state.phone=='') {
      Swal.fire({ title: 'Warning!',
      text: 'Username or phone cannot be empty',
      icon: 'info',
      confirmButtonText: 'OK'})
    }else{
      this.setState(() => ({
        countryCode: document.getElementById('swal-select2').value,
        phone: document.getElementById('swal-input2').value,
      }))
      axios.post(`${accountUrl}/public/credential/checkCaregiverRegistration`, {
        username : this.state.username,
        phone: document.getElementById('swal-select2').value,
   })
   .then(res => {
       console.log(res)
     if(res.status==200 && res.data.data.registered==true){
       
      Swal.fire({
        title: 'Warning!',
        text: 'This google account is already exist',
        icon: 'info',
        confirmButtonText: 'OK'
      })
     }else{ 
       this.setState({idTokenGoogle:response.tokenId, firstname: response.profileObj.givenName,
          lastname:response.profileObj.familyName,email:response.profileObj.email})

      this.submitForm(); 
      
     }
     
    })
  }


  }
})
 
    
  
  console.log(response);
}
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value }
  //     , () => {
      // if((this.state.password.length >5) &&(this.state.password == this.state.confirmPassword)){

            //  this.setState({enableBtn:false})
      
    //  } else{
    //    this.setState({enableBtn:true})
    //  };
    // }
    );
  };
  onChangeOTP = (stateName, value)=>{
    let go =false
    if (value.length <= 1) {
      this.setState({[stateName]: value})
    }
    if (value.length === 1) {
      this.input2.focus();
    }
    if(this.input2.value.length==1){
      this.input3.focus();
    }
    if(this.input3.value.length==1){
      this.input4.focus();
    }
    if(this.input4.value.length==1){
      this.input5.focus();
    }
    if(this.input5.value.length==1){
      this.input6.focus();
    }
    
  }

  onChangeList = (data) => {
    const { findCaraList } = this.state
    let newData = findCaraList

    for(let i = 0; i < newData.length; i++){
      if(newData[i].id === data.id) {
        newData[i] = data
      }
    }

    this.setState({howToFindCaraSelected: false})

    for(let i = 0; i < newData.length; i++) {
      if(newData[i].isActive === true && (newData[i].name !== '' &&  newData[i].name !== 'Others')) {
        this.setState({howToFindCaraSelected: true})
        break;
      }
    }

    this.setState({findCara: newData})
  }

  onChangeOtherDropdown = (data) => {
    const { findCaraList } = this.state
    let newData =  findCaraList

    newData[4].name = data

    if((newData[4].name !== 'Others') || (newData[4].name !== '')) {
      for(let i = 0; i < newData.length; i++) {
        if(newData[i].isActive === true) {
          if((newData[4].name !== '' && newData[4].name !== 'Others')) {
            this.setState({...this.state, howToFindCaraSelected: true, findCara: newData})
          } else {
            this.setState({...this.state, howToFindCaraSelected: false, findCara: newData})
          }
          break;
        } else {
          this.setState({...this.state, howToFindCaraSelected: false, findCara: newData})
        }
      }
    } else {
      this.setState({...this.state, findCara: newData})
    }
  }

  submitForm = () => { 
    if(!this.state.username && this.state.credentialType !== 'GOOGLE') {   
      Swal.fire({
        title: 'Warning!',
        text: `'username' is required`,
        icon: 'info',
        confirmButtonText: 'OK'
      }) 
    } else if(this.state.username.length < 6 && this.state.credentialType !== 'GOOGLE') {   
      Swal.fire({
        title: 'Warning!',
        text: `'username' cannot be less than 6 characters`,
        icon: 'info',
        confirmButtonText: 'OK'
      }) 
    } else if(!this.state.password && this.state.credentialType !== 'GOOGLE') {   
      Swal.fire({
        title: 'Warning!',
        text: `'password' is required`,
        icon: 'info',
        confirmButtonText: 'OK'
      }) 
    } else if(!this.state.confirmPassword && this.state.credentialType !== 'GOOGLE') {   
      Swal.fire({
        title: 'Warning!',
        text: `'confirm password' is required`,
        icon: 'info',
        confirmButtonText: 'OK'
      }) 
    } else if(this.state.password !== this.state.confirmPassword && this.state.credentialType !== 'GOOGLE') {   
      Swal.fire({
        title: 'Warning!',
        text: `password and confirm password does not match`,
        icon: 'info',
        confirmButtonText: 'OK'
      }) 
    } else if(this.state.password.length < 6 && this.state.credentialType !== 'GOOGLE') {   
      Swal.fire({
        title: 'Warning!',
        text: 'password cannot be less than 6 characters',
        icon: 'info',
        confirmButtonText: 'OK'
      }) 
    } else if(!this.state.phone) {   
      Swal.fire({
        title: 'Warning!',
        text: `'phone' is required`,
        icon: 'info',
        confirmButtonText: 'OK'
      }) 
    } else if(this.state.phone.length < 6) {   
      Swal.fire({
        title: 'Warning!',
        text: `'phone' cannot be less than 6 characters`,
        icon: 'info',
        confirmButtonText: 'OK'
      }) 
    } else if(!this.state.howToFindCaraSelected) {   
      Swal.fire({
        title: 'Warning!',
        text: `'How did you find about CARA' is required`,
        icon: 'info',
        confirmButtonText: 'OK'
      }) 
    } else {
      console.log(this.state.countryCode.concat(this.state.phone))
    const isPartner = queryString.parse(this.props.location.search).partnerToken
    window.sessionStorage.setItem("isInvited", this.state.isInvited);
    axios.post(`${accountUrl}/public/credential/checkCaregiverRegistration`, {
      username : this.state.username,
      // phone: this.state.countryCode.concat(this.state.phone)
    })
    .then(res => {
     console.log(res.data)
    if(res.status==200 && res.data.data.registered==true){
     
      Swal.fire({
        title: 'Warning!',
        text: 'This username already exists',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    } else {
      if (isPartner) {
        window.sessionStorage.setItem("firstname", this.state.firstname);
        window.sessionStorage.setItem("lastname", this.state.lastname);
        window.sessionStorage.setItem("email",this.state.email);
        window.sessionStorage.setItem("countryCode",this.state.countryCode);
        if (this.state.masterPartnerUid) window.sessionStorage.setItem("masterPartnerUid",this.state.masterPartnerUid);
        if (this.state.partnerToken) window.sessionStorage.setItem("partnerToken",this.state.partnerToken);
        this.props.history.push({pathname:'/CareGiverPersonDetail',
        state:{
          username: this.state.username,
          password: this.state.password,
          phone: this.state.phone,
          credentialType: this.state.credentialType,
          idTokenGoogle:this.state.idTokenGoogle,
          masterPartnerUid: this.state.masterPartnerUid,
          partnerToken: this.state.partnerToken,
          countryCode: this.state.countryCode,
        }})
      } else {
        axios.post(`${accountUrl}/public/credential/generateCaregiverSignUpOTP`, {
          phone : this.state.phone,
          prefixCountryNum: this.state.countryCode,
          channel: "sms",
          username: this.state.username
        })
        .then(res => {
          console.log(res)
           if(res.status==200 && res.data.type=="SUCCESS"){
            this.props.history.push('/registrationCG/otp', { ...this.state })
               console.log(res.data.data.otp)
        
          } else{
           
            Swal.fire({
              title: 'Warning!',
              text: `${res.data.data.message}`,
              icon: 'info',
              confirmButtonText: 'OK'
            }) 
          };
        }, (error) => {
          console.log(error);
        });
      }
   };
  })
    }
  }
    
    
  handleClose = () => {
    axios.post(`${accountUrl}/public/credential/verifyCaregiverSignUpOTP`, {
      phone : this.state.phone,
      prefixCountryNum: this.state.countryCode,
      code: `${this.state.satu}${this.state.dua}${this.state.tiga}${this.state.empat}${this.state.lima}${this.state.enam}`
      })
      .then(res => {
       
        console.log(res)
        if(res.status==200 && (res.data.data.valid.status=='approved' || res.data.data.valid.valid) ){
         
  this.setState({show: false})
  
  window.sessionStorage.setItem("firstname", this.state.firstname);
  window.sessionStorage.setItem("lastname", this.state.lastname);
  window.sessionStorage.setItem("email",this.state.email);
  window.sessionStorage.setItem("countryCode",this.state.countryCode);
  if (this.state.masterPartnerUid) window.sessionStorage.setItem("masterPartnerUid",this.state.masterPartnerUid);
  if (this.state.partnerToken) window.sessionStorage.setItem("partnerToken",this.state.partnerToken);
  this.props.history.push({pathname:'/CareGiverPersonDetail',
  state:{
    username: this.state.username,
    password: this.state.password,
    phone: this.state.phone,
    credentialType: this.state.credentialType,
    idTokenGoogle:this.state.idTokenGoogle,
    masterPartnerUid: this.state.masterPartnerUid,
    partnerToken: this.state.partnerToken,
    countryCode: this.state.countryCode,
  }})
  console.log(this.state.username)
  console.log(this.state.password)
  console.log(this.state.credentialType)

        if (this.state.password == this.state.confirmPassword) {
          console.log("Password Match")
        }
        return this.state
      } else {
        Swal.fire({
          title: 'Warning!',
          text: ` OTP CODE IS NOT VALID`,
          icon: 'info',
          confirmButtonText: 'OK'
        }) 
      };
    }, (error) => {
      console.log(error);
    });  
  }
    handleCloseError =()=>{
      this.setState({showError:false})
    }
    back=()=>{
      this.props.history.goBack()
    }

  render() {
      const {type,username,password,phone,confirmPassword,enableBtn,showError,errorMessage, type2, findCaraList} = this.state
      const isPartner = queryString.parse(this.props.location.search).partnerToken
      console.log('this.state.invitationId => ', this.state.isInvited)
    return (
      <>
        <StyledCG>
        <Header title={isPartner ? ` ` : null} />
          <h2 style={{marginTop:'5%'}}>{isPartner ? `Create Organisation Staff Account` : `Sign up as Caregiver`}</h2>    
          <Row style={{textAlign:'start',marginTop:'6%'}}>
            <Col md={6} style={{margin:'auto'}}>
                <label>Username</label>
            </Col>
            <Col md={6} style={{margin:'auto'}}>
              <Input containerStyle="InputStyle" inputProps={{
                placeholder:'Type your username name here... ', 
                name:"username", 
                type:'text',
                value:username,
                onChange: (e) => this.onChangeText('username', e.target.value),}} 
              />
            </Col>
          </Row>
          <Row>
            <Col style={{margin:'auto'}}>
                <label>Password</label>
            </Col>
            <Col md={6}>              
            <Input containerStyle="InputStyle" inputProps={{
              placeholder:' Type your password here...',
              name:"password",
              type:type,
              value:password,
              onChange: (e) => this.onChangeText('password', e.target.value)}} />
              <i onClick={this.togglePasswordVisiblity}>{eye}</i>
            </Col>
          </Row>
          <Row>
            <Col style={{margin:'auto'}}>
              <label>Confirm Password</label>
            </Col>
            <Col md={6} > 
            <Input containerStyle="InputStyle" inputProps={{ 
              placeholder:' Type your confirm password here... ',
              name:"confirmPassword",
              type:type2,
              value:confirmPassword,
              onChange: (e) => this.onChangeText('confirmPassword', e.target.value)}}  
            />
              <i onClick={this.togglePasswordVisiblity2}>{eye}</i>
            </Col>
          </Row>
          <Row>
            <Col style={{margin:'auto'}}>
              <label>Mobile Number</label>
            </Col>
            <Col md={2} style={{margin:'auto'}} >
              <Select placeholder='Country Code' className="select"  onChange={(e) =>  this.handleCountryCode(e)} styles={stylesCountryCode} defaultValue={{
                id: 0,
                name : '+65',
                value: '+65',
                label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Singapore} />+65 </Row>
              }} options={countryCodeData} />
            </Col>
            <Col md={4} style={{margin:'auto',padding:'0'}}>
              <Input containerStyle="InputStyle"  inputProps={{
                placeholder:' Type your phone here...',
                name:"phone",
                value:phone,
                onChange: (e) => this.onChangeText('phone', `${(e.target.value).replace(/[^0-9]/g, '')}`)}}  
              />
            </Col>
          </Row>
          <Row>
            <Col style={{marginTop:'0px'}}>
              <label>How did you find about CARA?</label>
            </Col>
            <Col md={6} style={{margin:'auto',padding:'0 15px'}}>
              < MultiSelect 
                data={findCaraList}
                onChangeList={this.onChangeList}
                onChangeOtherDropdown={this.onChangeOtherDropdown}
              />
            </Col>
          </Row>
          <Row style={{justifyContent:'flex-end',marginRight:'1%',marginTop:'10%'}}>
            <Col md={3}>
              <Button onClick={this.back} containerStyle={StyledButton} textStyle={StyledButton} buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} backgroundColor={colors.color3} textColor={colors.color11} bordered={colors.color11} text="Back" />
            </Col>
            <Col md={3}>
              <Button disabled={enableBtn} buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} text="Register" onClick={this.submitForm}/>
            </Col>
          </Row>
          <PopUpBoxOtp 
            close={this.handleHide} 
            prefix={this.state.countryCode} 
            lastDigit={this.state.phone.slice(7)} 
            show={this.state.show}  
            first={<input ref= {(input) => this.input1 = input} 
            selectTextOnFocus={true} 
            maxLength='1' 
            style={{ width:'50px',height:'50px',border:'1px solid #B1C2D8',borderRadius:'10px',textAlign:'center',outline: 'none',cursor:  'pointer', fontFamily: 'NunitoSans',fontWeight: 'bold',color: '#000000',resize: 'none', fontSize: '18px'}} 
            type='text' 
            value={this.state.satu} 
            onChange={(e) => this.onChangeOTP('satu', e.target.value)}/> } 
            second={<input ref= {(input) => this.input2 = input}   
            selectTextOnFocus={true} 
            maxLength='1'  
            style={{width:'50px',height:'50px',border:'1px solid #B1C2D8',borderRadius:'10px',textAlign:'center',outline: 'none',cursor:  'pointer', fontFamily: 'NunitoSans',fontWeight: 'bold',color: '#000000',resize: 'none', fontSize: '18px'}} 
            type='text' 
            value={this.state.dua} 
            onChange={(e) => this.onChangeOTP('dua', e.target.value)}/>} 
            third={<input  ref= {(input) => this.input3 = input} 
            selectTextOnFocus={true}  
            maxLength='1'  
            style={{width:'50px',height:'50px',border:'1px solid #B1C2D8',borderRadius:'10px',textAlign:'center',outline: 'none',cursor:  'pointer', fontFamily: 'NunitoSans',fontWeight: 'bold',color: '#000000',resize: 'none', fontSize: '18px'}} 
            type='text' 
            value={this.state.tiga} 
            onChange={(e) => this.onChangeOTP('tiga', e.target.value)}/>}
            fourth={<input  ref= {(input) => this.input4 = input}  
            selectTextOnFocus={true} 
            maxLength='1'  
            style={{width:'50px',height:'50px',border:'1px solid #B1C2D8',borderRadius:'10px',textAlign:'center',outline: 'none',cursor:  'pointer', fontFamily: 'NunitoSans',fontWeight: 'bold',color: '#000000',resize: 'none', fontSize: '18px'}} 
            type='text' 
            value={this.state.empat} 
            onChange={(e) => this.onChangeOTP('empat', e.target.value)}/>} 
            fifth={ <input  ref= {(input) => this.input5 = input}  
            selectTextOnFocus={true}
            maxLength='1'  
            style={{width:'50px',height:'50px',border:'1px solid #B1C2D8',borderRadius:'10px',textAlign:'center',outline: 'none',cursor:  'pointer', fontFamily: 'NunitoSans',fontWeight: 'bold',color: '#000000',resize: 'none', fontSize: '18px'}} 
            type='text' 
            value={this.state.lima} 
            onChange={(e) => this.onChangeOTP('lima', e.target.value)}/>} 
            sixth={<input  ref= {(input) => this.input6 = input}  
            selectTextOnFocus={true} 
            maxLength='1'  
            style={{width:'50px',height:'50px',border:'1px solid #B1C2D8',borderRadius:'10px',textAlign:'center',outline: 'none',cursor:  'pointer', fontFamily: 'NunitoSans',fontWeight: 'bold',color: '#000000',resize: 'none', fontSize: '18px'}} 
            type='text' 
            value={this.state.enam} 
            onChange={(e) => this.onChangeOTP('enam', e.target.value)}/>}
            size="modal-160w" 
            handleClose={this.handleClose} 
          />
          <PopUpBoxHandler show={showError} text={errorMessage} handleClose={this.handleCloseError}/>

          <Row style={{float:'right',marginTop:'15px'}}>
              <a style={{marginRight: '10px'}} href="https://cara.sg/privacy-policy/"><p >Privacy Policy</p></a>
              <a style={{marginLeft: '10px',marginRight: '20px'}} href='https://cara.sg/cara-membership-terms-condition/'>
                <p>Membership Terms and Condition</p>
              </a>
              <a style={{marginLeft: '10px',marginRight: '20px'}} href='https://cara.sg/terms-of-use/'>
                <p>Terms of Use</p>
              </a>
          </Row>
          <hr style={{marginTop:'110px',border: "1px solid #758BA9"}}/>
          {/* {
            !isPartner &&
              <Row style={{marginBottom:'50px'}}>
                <Col md={4}><a href=""><label className="signUp" >Or sign up with </label></a></Col>
                <Col md={8} style={{textAlign:'end'}}>  
                  <GoogleLogin
                    clientId={AppConfig.APP_CLIENT_ID}
                    render={renderProps => ( 
                      <button onClick={renderProps.onClick} disabled={renderProps.disabled}      className="google"><img style={{margin:'auto'}} src={google}/></button>
                    )}
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogleFail}
                    cookiePolicy={'single_host_origin'}
                  />                  
                </Col>
              </Row>
          } */}
        </StyledCG>       
      </>
    )
  }
}


export default RegistrationCG