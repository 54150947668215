import { useEffect } from "react";
import { changeLanguage } from "../../../../language/i18n";
import LanguageIcon from "../../../../assets/images/language.png";
import { Colors } from "../../../../themes";

const ToggleLanguages = ({ lang, setLang }) => {
  // Effects
  useEffect(() => {
    changeLanguage("en");
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
      }}
    >
      <img
        src={LanguageIcon}
        style={{
          width: "24px",
          aspectRatio: "1/1",
          objectFit: "contain",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <p
          onClick={() => setLang("en")}
          style={{
            margin: 0,
            fontWeight: "bold",
            color: lang === "en" ? Colors.color11 : "#B1C2D8",
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          English
        </p>
        <div
          style={{
            borderRightWidth: "1px",
            borderRightColor: Colors.color19,
            borderRightStyle: "solid",
          }}
        />
        <p
          onClick={() => setLang("md")}
          style={{
            margin: 0,
            fontWeight: "bold",
            color: lang === "md" ? Colors.color11 : "#B1C2D8",
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          普通话
        </p>
      </div>
    </div>
  );
};

export default ToggleLanguages;
