// Deps
import Modal from "react-awesome-modal";
import { useEffect, useState } from "react";

// Constants
import { Colors } from "../../../../themes";

// i18n Stuffs
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";

// Images
import Clear from "../../../../assets/images/clear_24px.png";
import Language from "../../../../assets/images/language.png";

const ModalInformation = ({ isVisible, onSetVisible }) => {
  const [lang, setLang] = useState("en");

  const { t } = useTranslation();

  useEffect(() => {
    changeLanguage(lang);
  }, [lang]);

  return (
    <Modal visible={isVisible} onClickAway={() => onSetVisible(false)}>
      <div
        style={{
          padding: "30px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {/* Header */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              fontSize: 24,
              margin: 0,
              fontWeight: 800,
              color: Colors.color2,
            }}
          >
            {t("InformationText")}
          </h1>
          <img
            src={Clear}
            onClick={() => onSetVisible(false)}
            style={{
              height: 24,
              aspectRatio: 1 / 1,
              cursor: "pointer",
            }}
          />
        </div>

        {/* Content */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: "14px",
              color: Colors.color2,
            }}
          >
            {t("IfThisIsAnEmergency2Text")}
          </p>
          <h3
            style={{
              margin: 0,
              fontSize: "20px",
              fontWeight: 700,
              color: Colors.color2,
            }}
          >
            {t("DementiaHelplineText")}
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <a
              href="tel:+63770700"
              style={{
                color: Colors.color11,
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              6377 0700
            </a>
            <a
              href="/"
              style={{
                color: Colors.color11,
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              cara.sg
            </a>
          </div>
        </div>

        {/* Divider */}
        <hr
          style={{
            margin: 0,
          }}
        />

        {/* Change lang */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <img
            src={Language}
            style={{
              height: "25px",
              aspectRatio: 1 / 1,
            }}
          />
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <h3
              style={{
                userSelect: "none",
                margin: 0,
                fontWeight: 700,
                fontSize: "14px",
                cursor: "pointer",
                color: lang === "en" ? Colors.color11 : Colors.color7,
              }}
              onClick={() => setLang("en")}
            >
              English
            </h3>

            <div
              style={{
                width: "1px",
                backgroundColor: Colors.color7,
              }}
            />
            <h3
              style={{
                userSelect: "none",
                margin: 0,
                fontWeight: 700,
                fontSize: "14px",
                cursor: "pointer",
                color: lang === "md" ? Colors.color11 : Colors.color7,
              }}
              onClick={() => setLang("md")}
            >
              普通话
            </h3>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalInformation;
