import React, { useState, useEffect, } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import GoogleLogin from 'react-google-login';
import Swal from 'sweetalert2'
import validator from 'validator'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select, { components } from 'react-select'
import {jwtDecode} from 'jwt-decode';

import Header2 from '../../components/header2'
import Input from '../../components/input'
import Dialog from '../../components/dialog'
import MultiSelect from '../../components/multiSelectDropdown'
import { Colors, Fonts, Scale } from '../../themes';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import google from '../../assets/images/google.png'
import { faEye } from "@fortawesome/free-solid-svg-icons";

import Indonesia from '../../assets/images/Indonesia.png'
import Singapore from '../../assets/images/Singapore.png'
import Malaysia from '../../assets/images/Malaysia.png'

import { signupMoP, verifyToken } from '../../api/UserAPI'

import ProccessData from '../../lib/ProccessData'

import { StyledRegistrationPartner, InputStyle } from './Styles/StylesRegistrationPartner'

const stylesCountryCode = {
    control: base => ({
      ...base,
      fontFamily: Fonts.FontRegular,
      fontColor: Colors.color,
      fontSize: '14px',
      fontWeight:'bold',
      padding:'0px',
      marginTop:'16px',
      marginRight: '10px',
      height: '44px',
      width: '140px'
    }),
    menu: base => ({
      ...base,
      fontFamily: Fonts.FontRegular,
      padding:'0',margin:'0',
      fontColor: Colors.color,
      fontSize: '14px',
      fontWeight:'bold'
    })
  };

const countryCodeData =[
    {
      id: 0,
      name : '+65',
      value: '+65',
      label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Singapore} />+65 </Row>
    },{
      id: 1,
      name : '+62',
      value: '+62',
      label: <Row style={{ width:'200px', display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Indonesia} />+62 </Row>
    },
    
    {
      id: 2,
      name : '+60',
      value: '+60',
      label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Malaysia}  />+60  </Row>
    },
  ]
  
const defaultParamsSignup = {
  "credentialType": "USERNAME",
  "accountData": {
      "email": "",
      "phone": "",
      "lastname": "",
      "metadata":{
        "MoP": true,
        "caraPro": false,
        "fromMoP": true
      },
      "password": "",
      "username": "",
      "firstname": "",
      "confirmPassword": "",
      "displayName": "",
      "alreadyLoggedin": false,
      "prefixCountryNum": "+65"
  },
  "credentialData": {
    "password": "",
    "username": ""
  }
}

const eye = <FontAwesomeIcon icon={faEye} />;

export const RegistrationPartner = ({ history }) => {
  const location = useLocation();
  const historyDom = useHistory();
  const [state, setState] = useState({
    paramsSignup: {
        credentialType: "USERNAME",
        accountData: {
            email: "",
            phone: "",
            lastname: "",
            metadata:{
              MoP: true,
              caraPro: false,
              fromMoP: true
            },
            password: "",
            username: "",
            firstname: "",
            confirmPassword: "",
            displayName: "",
            alreadyLoggedin: false,
            prefixCountryNum: "+65",
            createdBy: null
        },
        credentialData: {
          username: "",
          password: ""
        }
    },
    dialogSucess: false,
    loading: false,
    type: 'password',
    typeConfirm: 'password',
    passwordShown: false,
    passwordConfirmShown: false,
    howToFindCaraSelected: false,
    findCaraList: [
      {
        id: 1,
        name: 'Search Engine (Google, Yahoo, etc)',
        display: 'Search Engine (Google, Yahoo, etc)',
        isActive: false
      },
      {
        id: 2,
        name:  'Recommendation by friend or collague',
        display: 'Recommendation by friend or collague',
        isActive: false
      },
      {
        id: 3,
        name: 'Social Media',
        display: 'Social Media',
        isActive: false
      },
      {
        id: 4,
        name:  'Poster at MRT / Bus interchange',
        display: 'Poster at MRT / Bus interchange',
        isActive: false
      },
      {
        id: 5,
        name: 'Others',
        display: 'Others',
        isActive: false
      }
    ]
  })

  const queryParams = new URLSearchParams(location.search);
  const tokenAdmin = queryParams.get('token');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if(token) {
      tokenCheck(token).then(res => {
        setToken(token)
      })
    }
  }, [])

  const setToken = async (token) => {
    try {
      const decodedToken = jwtDecode(token)

      if(decodedToken) {
        setState({
          ...state, 
          paramsSignup: {
            ...state.paramsSignup,
            accountData: {
              ...state.paramsSignup.accountData,
              createdBy: decodedToken.uid
            }
          } 
        })
      }
    } catch (err) {
      alertError(err, true)
    }
  }

  const tokenCheck = async (token) => {
    try {
      let resultCheck = {}
      resultCheck.payload = await verifyToken({ headers: {
        Authorization: `${token}`
      }})
      ProccessData(resultCheck  , 'check token')
      .then(res => {
        // console.log('res => ', res)
      })
      .catch(err => {
        // console.log('err => ', err)
        alertError(err, true)
      })
    } catch (e) {
      alertError(e, true)
    }
  }
  


  const signupUser =  async () => {
    try {
      let resultAdd = {}
      if (!validator.isEmail(state.paramsSignup.accountData.email)) {
        alertError('Please input valid email')
      } else if (state.paramsSignup.accountData.password !== state.paramsSignup.accountData.confirmPassword) {
        alertError('Password & confirm password do not match')
      } else {
        setState(state => ({ ...state, loading: true }))
        resultAdd.payload = await signupMoP({credential: {
          ...state.paramsSignup,
          accountData: {
            ...state.paramsSignup.accountData,
            howFindCara: state.findCaraList.filter(item => item.isActive).map(item => item.name)
          },
          credentialData: {
            username: state.paramsSignup.accountData.username,
            password: state.paramsSignup.accountData.password
          }
        }})
        ProccessData(resultAdd, 'add MoP')
        .then(res => {
          setState(state => ({ 
            ...state, 
            paramsSignup: defaultParamsSignup,
            dialogSucess: true,
          }))
        })
        .catch(err => {
          alertError(err)
        })
      }
    } catch (e) {
      alertError(e)
    }

    setState(state => ({ ...state, loading: false }))
  }

  const alertError = (e, exit = false) => {
    Swal.fire({
      title: 'Error!',
      text: e,
      icon: 'error',
      confirmButtonText: 'OK',
      preConfirm: () => {
        if (exit) {
          historyDom.replace('/')
        } else null
      },
    })
  }

  const onChangeText = (name, value) => {
    setState(state => {  
      const { accountData } = state.paramsSignup;

      accountData[name] = value;

      return {
        ...state,
        paramsSignup: {
          ...state.paramsSignup,
          accountData,
        }
      }
    })
  }

  const togglePasswordVisiblity = (field) => {
    if(field === 'password') {
        if(state.passwordShown === false){
            setState({
                ...state,
                passwordShown: true,
                type: 'text',
            })
        }else{
            setState({
                ...state,
                passwordShown: false,
                type: 'password'
            })
        }
    } else if (field = 'confirmPassword') {
        if(state.passwordConfirmShown === false){
            setState({
                ...state,
                passwordConfirmShown: true,
                typeConfirm: 'text',
            })
        }else{
            setState({
                ...state,
                passwordConfirmShown: false,
                typeConfirm: 'password'
            })
        }
    }
  };

  const handleCountryCode =(e)=> {
    setState({
        ...state, 
        paramsSignup : {
            ...state.paramsSignup,
            accountData: {
                ...state.paramsSignup.accountData,
                prefixCountryNum:e.value
            }
        }}, ()=>{
  })}

  const onChangeList = (data) => {
    const { findCaraList } = state
    let newData =  findCaraList

    for(let i = 0; i < newData.length; i++){
      if(newData[i].id === data.id) {
        newData[i] = data
      }
    }

    setState({...state, howToFindCaraSelected: false})

    for(let i = 0; i < newData.length; i++) {
      if(newData[i].isActive === true && (newData[i].name !== '' &&  newData[i].name !== 'Others')) {
        setState({...state, howToFindCaraSelected: true})
        break;
      }
    }

    setState({...state, findCara: newData})
  }

  const onChangeOtherDropdown = (data) => {
    const { findCaraList } = state
    let newData =  findCaraList

    newData[4].name = data

    if((newData[4].name !== 'Others')) {
      for(let i = 0; i < newData.length; i++) {
        if(newData[i].isActive === true) {
          if(i === 4 && (newData[4].name !== '' &&  newData[4].name !== 'Others')) {
            setState({...state, howToFindCaraSelected: true, findCara: newData})
          } else {
            setState({...state, howToFindCaraSelected: false, findCara: newData})
          }
          break;
        } else {
          setState({...state, howToFindCaraSelected: false, findCara: newData})
        }
      }
    } else {
      setState({...state, findCara: newData})
    }
  }

  // const onGetAddress = async ({ value }) => {
  //   try {
  //     let resultGet = {}
  //     resultGet.payload = await getAddress({
  //       searchVal: `${value}`,
  //       returnGeom: `Y`,
  //       getAddrDetails: `Y`,
  //     })
  //     resultGet.payload.type = 'SUCCESS'
  //     ProccessData(resultGet, 'get address')
  //     .then(res => {
  //       if (res.results.length > 0) {
  //         setState(state => {
  //           const { partnerDetails } = state.paramsSignup;
  
  //           partnerDetails[`address`] = `${res.results[0].ADDRESS.replace(value, '')}`;
  //           partnerDetails[`password`] = `${res.results[0].ROAD_NAME}`;
  //           partnerDetails[`email`] = `${res.results[0].email}`;
  //           partnerDetails[`confirmPassword`] = `${res.results[0].BLK_NO}`;
  
  //           return {
  //             ...state,
  //             paramsSignup: {
  //               ...state.paramsSignup,
  //               partnerDetails,
  //             }
  //           }
  //         })
  //       }
  //     })
  //     .catch(err => {
  //       alertError(err)
  //     })
  //   } catch (e) {
  //     alertError(e)
  //   }
  // }

  const { firstname, lastname, username, phone, confirmPassword, email, password } = state.paramsSignup.accountData
  const { type, typeConfirm, findCaraList, howToFindCaraSelected } = state

  let isFindCaraSelected = state.findCaraList.filter(item => item.isActive).map(item => item.name)
  console.log("isFindCaraSelected => ", isFindCaraSelected)
  if(isFindCaraSelected.length > 1) {
    console.log("IF")
    isFindCaraSelected = true
  } else if (isFindCaraSelected.length === 1) {
    if(isFindCaraSelected[0] === 'Others' || isFindCaraSelected[0] === '') {
      console.log("IF 2")
      isFindCaraSelected = false
    } else {
      console.log("ELSE")
      isFindCaraSelected = true
    }
  } else if (isFindCaraSelected.length < 1) {
    isFindCaraSelected = false
  }

  const buttonDisabled = firstname === '' || lastname === '' || phone === '' || username === '' || password === '' || confirmPassword === '' || password.length < 6 || confirmPassword.length < 6 || email === '' || !isFindCaraSelected || state.loading


//   useEffect(() => {
//     setState({
//         ...state,
//         accountData: {
//             ...state.accountData,
//             displayName: state.paramsSignup.accountData.firstname + state.paramsSignup.accountData.lastname
//         },
//         credentialData: {
//             username: state.paramsSignup.accountData.username,
//             password: state.paramsSignup.accountData.password,
//         }
//     })
//   }, [state.paramsSignup.accountData.password, state.paramsSignup.accountData.username, state.paramsSignup.accountData.firstname, state.paramsSignup.accountData.lastname])
  
  return (
    <StyledRegistrationPartner buttonDisabled={buttonDisabled}>
      <Dialog
        isVisible={state.dialogSucess}
        title={"Your account application has been submitted!"}
        additionalStyle={{
          titleStyle: `
            width: 296px;
            text-align: center;
            margin: 0 auto;
          `,
          descriptionStyle: `
            width: 362px;
            text-align: center;
            margin: 0 auto;
            margin-top: 28px;
          `
        }}
        description={tokenAdmin ? `Registration completed successfully.` : `Please note that it will take our admin 5-7 working days to process your request. An onboarding email will be sent to you after approval to setup your credentials.`}
        showXClose={false}
        height={`auto`}
        okText="OK"
        okBordered={false}
        okBackgroundColor={`#512874`}
        okTextColor={`#FFF`}
        onOk={() => {
          setState(state => ({ ...state, dialogSucess: false }))
          history.push('/')
        }}
      />
      <Header2 />
      <div className="form-container">
        <p className="registration-title">Create my account</p>
        <div className="input-container">
          <div>
            <label for='firstname' className="label-partner">First Name</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'firstname',
              onChange: (e) => onChangeText('firstname', e.target.value),
              maxLength: 50,
              value: firstname,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='lastname' className="label-partner">Last Name</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'lastname',
              onChange: (e) => {
                onChangeText('lastname', e.target.value)
              },
              maxLength: 50,
              value: lastname,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='username' className="label-partner">Username</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'username',
              onChange: (e) => {
                onChangeText('username', e.target.value)
              },
              maxLength: 50,
              value: username,
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='password' className="label-partner">Password</label>
          </div>
          <Input 
            containerStyle={InputStyle}
            inputProps={{
              id: 'password',
              onChange: (e) => onChangeText('password', e.target.value),
              value: password,
              type: type
          }}/>
           <i onClick={()=>togglePasswordVisiblity('password')} style={{marginLeft: -30, marginRight: 12, marginTop: 25}}>{eye}</i>
        </div>
        <div className="input-container">
          <div>
            <label for='confirmPassword' className="label-partner">Confirm Password</label>
          </div>
          <Input 
            containerStyle={InputStyle}
            inputProps={{
              id: 'confirmPassword',
              onChange: (e) => onChangeText('confirmPassword', e.target.value),
              value: confirmPassword,
              type: typeConfirm
          }}/>
          <i onClick={()=>togglePasswordVisiblity('confirmPassword')} style={{marginLeft: -30, marginRight: 12, marginTop: 25}}>{eye}</i>
        </div>
        <div className="input-container">
            <div>
            <label for='phone' className="label-partner">Mobile Number</label>
            </div>
            <Select placeholder='Country Code' className="select"  onChange={(e) =>  handleCountryCode(e)} styles={stylesCountryCode} defaultValue={{
                id: 0,
                name : '+65',
                value: '+65',
                label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Singapore} />+65 </Row>
              }} options={countryCodeData} />
            <Input containerStyle="InputStyle"  inputProps={{
                name:"phone",
                value:phone,
                onChange: (e) => onChangeText('phone', `${(e.target.value).replace(/[^0-9]/g, '')}`)}}  
              />
        </div>
        <div className="input-container">
          <div>
            <label for='email' className="label-partner">Email Address</label>
          </div>
          <Input 
            containerStyle={InputStyle}
            inputProps={{
              id: 'email',
              onChange: (e) => onChangeText('email', e.target.value),
              value: email,
          }}/>
        </div>
        <div className="input-container">
          <div style={{marginTop: '-10px', marginBottom: '20px'}}>
            <label for='email' className="label-partner">How did you find about CARA?</label>
          </div>
          < MultiSelect 
            data={findCaraList}
            onChangeList={onChangeList}
            onChangeOtherDropdown={onChangeOtherDropdown}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '60px', }}>
          <div className="button-back" onClick={() => history.goBack()}>
            <div className="button-back-text">Back</div>
          </div>
          <div style={{ width: '15px', height: 1 }} />
          <div className="button-register" onClick={() => {
            buttonDisabled ? null : signupUser() 
          }}>
            <div className="button-register-text">{state.loading ? `Loading...` : `Register`}</div>
          </div>
        </div>



        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '37px', }}>
          <a className="link-text" href='https://cara.sg/privacy-policy/'>
            Privacy Policy
          </a>
          <div style={{ width: `5px`, height: `5px`, borderRadius: `${5/2}px`, background: `#BBCADB`, position: 'relative', top: '2px', margin: '0 10px' }} />
          <a className="link-text" href='https://cara.sg/cara-membership-terms-condition/'>Membership Terms and Condition</a>
          <div style={{ width: `5px`, height: `5px`, borderRadius: `${5/2}px`, background: `#BBCADB`, position: 'relative', top: '2px', margin: '0 10px' }} />
          <a className="link-text" href='https://cara.sg/terms-of-use/'> Terms of Use</a>
        </div>
      </div>
      {/* <div className="oauth-container">
        <div style={{
          fontFamily: "TruenoRegular",
          fontSize: '20px',
          color: `#424242`,
          marginBottom: '20px',
        }}>
          Or sign up with
        </div>
        <div>
        <GoogleLogin
          clientId={REACT_APP_CLIENT_ID}
          render={renderProps => {
            return ( 
              <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="google"><img style={{margin:'auto'}} src={google}/></button>
            )
          }}
            // onSuccess={this.responseGoogle}
            // onFailure={this.responseGoogleFail}
          cookiePolicy={'single_host_origin'}
        />
        </div>
      </div> */}
    </StyledRegistrationPartner>
  )
}


export default RegistrationPartner