import ReactGA from 'react-ga';

export default () => {
  return (
    ReactGA.initialize('G-1BJW2CLZXE', {
      debug: true,
      titleCase: false,
      gaOptions: {
        userId: 123
      }
    })
  )
};
