import React , { useState } from 'react';

import Modal from 'react-bootstrap/Modal'

import {StyledModalHandler} from "./StyledModalHandler";

import Button from 'react-bootstrap/Button'
import ButtonBlue from '../button'


function PopUpBoxHandler(props) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <StyledModalHandler>
        <Modal style={{borderRadius:'40px'}} show={props.show} onHide={props.handleClose}>
          <Modal.Header >
            <Modal.Title style={{margin:'auto',fontSize:'20px'}}>{props.text}</Modal.Title>
          </Modal.Header>
          <div style={{width:'auto',paddingBottom:'20px',paddingTop:'10px',margin:'auto'}}>
          <Button  onClick={props.handleClose} variant="warning">Ok</Button>
            
              </div>
        </Modal>
        </StyledModalHandler>
      </>
    );
  }
  export default PopUpBoxHandler