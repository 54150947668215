import React , { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'

import {StyledModal,StyledButton} from "./StyledModal";

import Button from '../button'


function PopUpBox(props) {
    const [show, setShow] = useState(false);
    const { t, i18n } = useTranslation();
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const lists = [
      t('ThePosibilityText'),
      t('ReceivingAFollowupText')
    ]

    return (
      <>
        <StyledModal>
        <Modal style={{borderRadius:'40px'}} show={props.show}>
          <Modal.Header  style={{borderBottom:'none'}}>
            <Modal.Title style={{margin:'auto', fontFamily: `"TruenoBold", Arial, sans-serif`, }}>
              <h2 style={{ fontSize: '24px' }}>
               {t('ThankYouForYourHelpText')}
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{textAlign:'left', fontFamily: `"TruenoRegular", Arial, sans-serif`, paddingLeft: 40, paddingRight: 40}}>
            <h4 style={{ fontSize: '15px' }}>
              {t('ByClicking1Text')} <span style={{color: '#522975', fontWeight: 'bold'}}>{t('ProceedText')}</span>{t('ByClicking2Text')} :
            </h4>
            <ul>
              {lists.map((item, index) => (
                <Fragment key={String(index)}>
                  <li style={{ fontSize: '15px' }}>{item}</li>
                </Fragment>
              ))}
            </ul>
          </Modal.Body>
          
          <div style={{width:'200px',paddingBottom:'20px',paddingTop:'10px',display:'flex',justifyContent:'center', alignItems: 'center', margin: '0 auto'}}>
               <CustomButton color='#62318C' onClick={props.handleClose}>{t('ProceedText')}</CustomButton>
              </div>
        </Modal>
        </StyledModal>
      </>
    );
  }
  export default PopUpBox

  const CustomButton = styled.button`
  background-color: ${props => props.color} !important;
  font-size: 18px !important;
  color: white !important;
  border-radius: 10px !important;
  border: none;
  width: 100% !important;
  height: 50px !important;
  box-shadow: -3px -3px 10px #FFFFFF, 3px 3px 10px #C1CAD7 !important;
  font-weight: bold !important;
  cursor: pointer !important; 
`;