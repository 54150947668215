import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton,InputStyle} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'

import Button from '../../components/button'

import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'
import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import Polygon from '../../assets/images/Polygon.png'
import Indonesia from '../../assets/images/Indonesia.png'
import Singapore from '../../assets/images/Singapore.png'
import Malaysia from '../../assets/images/Malaysia.png'
import Step1Plwd from '../../assets/images/step1Plwd.png'

import validator from 'validator';
import moment from 'moment'
import styled from "styled-components";

import { Images, Colors, Fonts } from '../../themes';
import { Link } from 'react-router-dom';
import Select from 'react-select'

import memoize from "memoize-one";
import { connect } from 'react-redux';


import PopUpBox from '../../components/modal'

import axios from 'axios'

import AppConfig from '../../config'
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import PopUpBoxHandler from '../../components/modalhandler';
const accountUrl = AppConfig.ACCOUNT_URL

const InputDateStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`
const languageData = [
    {
      id: 0,
      value: 'English',
      label: 'English'
    },
    {
      id: 1,
      value: 'Mandarin',
      label: 'Mandarin'
    },
    {
      id: 2,
      value: 'Malay',
      label: 'Malay'
    },
    {
      id: 3,
      value: 'Tamil',
      label: 'Tamil'
    },
    {
      id: 4,
      value: 'Hokkien',
      label: 'Hokkien',
    },
    {
      id: 5,
      value: 'Cantonese',
      label: 'Cantonese',
    },
    {
      id: 6,
      value: 'Teochew',
      label: 'Teochew',
    },
    {
      id: 7,
      name: 'Others, please specify',
      value: 'Others, please specify',
      label: 'Others, please specify',
    },
  ];
  const stageofDementiaData =[
    {
      id: 0,
      name : 'MILD',
      value: 'MILD',
      label: 'MILD'
    },
    {
      id: 1,
      name : 'MODERATE',
      value: 'MODERATE',
      label: 'MODERATE'
    },
    {
      id: 2,
      name : 'SEVERE',
      value: 'SEVERE',
      label: 'SEVERE'
    },
  ]
  const typeOfDementiaData = [
    {
      id: 0,
      name: `Alzheimer's Disease`,
      value: `Alzheimer's Disease`,
      label: `Alzheimer's Disease`
    },
    {
      id: 1,
      name: 'Vascular Dementia',
      value: `Vascular Dementia`,
      label: `Vascular Dementia`
    },
    {
      id: 2,
      name: 'Frontotemporal Dementia (FTD)',
      value: `Frontotemporal Dementia (FTD)`,
      label:  `Frontotemporal Dementia (FTD)`
    },
    {
      id: 3,
      name: 'Lewy Body Dementia (LBD)',
      value: `Lewy Body Dementia (LBD)`,
      label: `Lewy Body Dementia (LBD)`
    },
    {
      id: 4,
      name: 'Mild Cognitive Impairment (MCI)',
      value: `Mild Cognitive Impairment (MCI)`,
      label: `Mild Cognitive Impairment (MCI)`
    },
    {
      id: 5,
      value: 'Others, please specify',
      label: 'Others, please specify',
    },
  ];

  const countryCodeData =[
  
    {
      id: 0,
      name : '+65',
      value: '+65',
      label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Singapore} />+65 </Row>
    },{
      id: 1,
      name : '+62',
      value: '+62',
      label: <Row style={{ width:'200px', display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Indonesia} />+62 </Row>
    },
    
    {
      id: 2,
      name : '+60',
      value: '+60',
      label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Malaysia}  />+60  </Row>
    },
  ]

  const stylesCountryCode = {
  
    control: base => ({
      ...base,
      fontFamily: Fonts.FontRegular,
      fontColor: Colors.color,
      fontSize: '14px',
      fontWeight:'bold',
      padding:'0',margin:'0'
    }),
    menu: base => ({
      ...base,
      fontFamily: Fonts.FontRegular,
      padding:'0',margin:'0',
      fontColor: Colors.color,
      fontSize: '14px',
      fontWeight:'bold'
    })
  };
  
const CustomInput = React.forwardRef((props, ref) => {
  return (
    <CustomDatePickDiv>
    <Row>
   
      <label onClick={props.onClick} ref={ref}>
      <Col >
        {props.value || props.placeholder}
        </Col> 
      </label>
     
      <Col style={{display:'flex',alignSelf:'center',justifyContent:'flex-end',marginRight:'10px'}}>
      <img src={Polygon} onClick={props.onClick} />
</Col>
      </Row>
    </CustomDatePickDiv>
  );
});

const CustomDatePickDiv = styled.div`
  background-color: white;
 
  border-radius: 10px;
  label {
    padding-left:5px;
    font-family: ${Fonts.FontRegular};
    font-weight: bold;
    color: ${Colors.color2};
    resize: none;
    ${Fonts.size.size20}
  }
`
  
const InputReadOnlyStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin: 10px 0 25px 0; 
.input-field { cursor: pointer; }
`;

const InputTypeStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin-top: 10px; 
.input-field { cursor: pointer; }
`;

export class PersonDetailPWD extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          username: this.props.location.state.username,
          password: this.props.location.state.password,
          
          streetName:this.props.location.state.streetName,
          unitNumber:this.props.location.state.unitNumber,
          postalCode:this.props.location.state.postalCode,
          cityCountry:this.props.location.state.cityCountry,


          credentialType: this.props.location.state.credentialType,
          idTokenGoogle: this.props.location.state.idTokenGoogle,
          masterPartnerUid: this.props.location.state.masterPartnerUid,
          partnerToken: this.props.location.state.partnerToken,
          partnerAddress: this.props.location.state.partnerAddress,
          partnerBranch: this.props.location.state.partnerBranch,
          partnerPostalCode: this.props.location.state.partnerPostalCode,

          findCaraList: this.props.location.state.findCaraList,

          token: this.props.location.state.token,
          
          firstname: '',
          lastname:'',
          displayName: '',
          email:'',
          gender:'',
          NIRC:'',
          birth: '',
          setLanguage: null,
          language:'',
          dementiaType: '',
          dementiaStage:'',
          phone:'',
          homeNumber:'',
          selectLanguage: languageData,
          usedService:false,
          dateNow:'',
          date: '',
          defaultStage:'',
          defaultLanguage:'',
          defaultType:'',
          showError:false,
          errorMessage:'',
          Switch: SwitchNo,
        SwitchGenderM:TypeInactive,
        SwitchGenderF:TypeInactive,
        dateSend:'',
        languageDropdown: false,
        languageType: {
            id: 1,
            name: 'English',
        },
        typeOfDementiaDropdown: false,
        typeOfDementia: {
        id: 1,
        name: `Alzheimer's Disease`,
      },
      typeOfDementiaFiltered: true,
        languageFiltered: true,
        show: false,
        delivery: '',
        dateSend: moment().format('YYYY-MM-DD'),
        countryCode:'',
        defaultCountryCode: '', 
        }
        this.handleChangeLanguange = this.handleChangeLanguange.bind(this)
      }
   handleShow = (e)=> {
    
     this.setState({show: true})
 }
 componentDidMount(){
   console.log('streetName'+this.state.streetName)
   
   console.log('postalCode'+this.state.postalCode)
   
   console.log('cityCountry'+window.sessionStorage.getItem("defaultType"))

  if(window.sessionStorage.getItem("gender") == 'male'){
    this.setState({SwitchGenderM: TypeActive,gender:window.sessionStorage.getItem("gender")})
   }
   else if(window.sessionStorage.getItem("gender") == 'female'){
    this.setState({SwitchGenderF: TypeActive,gender:window.sessionStorage.getItem("gender")})
   }
   if(window.sessionStorage.getItem("firstname") != '' && window.sessionStorage.getItem("firstname") != 'null'){
     this.setState({firstname: window.sessionStorage.getItem("firstname")})
   }
    if(window.sessionStorage.getItem("lastname") != '' && window.sessionStorage.getItem("lastname") != 'null'){
      this.setState({lastname: window.sessionStorage.getItem("lastname")})
    }
    if(window.sessionStorage.getItem("displayName") != '' && window.sessionStorage.getItem("displayName") != 'null'){
      this.setState({displayName: window.sessionStorage.getItem("displayName")})
    }
   if(  this.props.location.state.NIRC){
    this.setState({NIRC:  this.props.location.state.NIRC})
  }
  
  if(window.sessionStorage.getItem("dateNow")!= ''){
    this.setState({dateNow:window.sessionStorage.getItem("dateNow"),birth:window.sessionStorage.getItem("dateNow")})
  }
  
  if(window.sessionStorage.getItem("email") != ''){
    this.setState({email: window.sessionStorage.getItem("email")})
  }
  
  if(window.sessionStorage.getItem("homeNumber") != null){
    this.setState({homeNumber: window.sessionStorage.getItem("homeNumber")})
  }
  
  if(window.sessionStorage.getItem("phone") != null){
    this.setState({phone: window.sessionStorage.getItem("phone")})
  }
  if(window.sessionStorage.getItem("defaultType") != ''){
    this.setState({defaultType: window.sessionStorage.getItem("defaultType")})
  }
  
  if(languageData[window.sessionStorage.getItem("defaultType")]!= null){
    this.setState({dementiaType:typeOfDementiaData[window.sessionStorage.getItem("defaultType")].value})
  }
  if(window.sessionStorage.getItem("defaultLanguage") != ''){
    this.setState({defaultLanguage: window.sessionStorage.getItem("defaultLanguage")})
  }
  
  if(languageData[window.sessionStorage.getItem("defaultLanguage")]!= null){
    this.setState({language:languageData[window.sessionStorage.getItem("defaultLanguage")].value})
  }
   if(window.sessionStorage.getItem("defaultStage") != ''){
    this.setState({defaultStage: window.sessionStorage.getItem("defaultStage")})
  }
  if(stageofDementiaData[window.sessionStorage.getItem("defaultStage")]!= null){
    this.setState({dementiaStage: stageofDementiaData[window.sessionStorage.getItem("defaultStage")].value})
  }

  if(window.sessionStorage.getItem("masterPartnerUid") != ''){
    this.setState({masterPartnerUid: window.sessionStorage.getItem("masterPartnerUid")})
  }

  if(window.sessionStorage.getItem("partnerToken") != ''){
    this.setState({partnerToken: window.sessionStorage.getItem("partnerToken")})
  }

  if(window.sessionStorage.getItem("partnerAddress") != ''){
    this.setState({partnerAddress: window.sessionStorage.getItem("partnerAddress")})
  }
  if(window.sessionStorage.getItem("partnerBranch") != ''){
    this.setState({partnerBranch: window.sessionStorage.getItem("partnerBranch")})
  }
  if(window.sessionStorage.getItem("partnerPostalCode") != ''){
    this.setState({partnerPostalCode: window.sessionStorage.getItem("partnerPostalCode")})
  }
  if(window.sessionStorage.getItem("countryCode") != '') {
    this.setState({countryCode: window.sessionStorage.getItem("countryCode")})
  }
  if(window.sessionStorage.getItem("defaultCountryCode") != '') {
    this.setState({defaultCountryCode: window.sessionStorage.getItem("defaultCountryCode")})
  }
  
 }
 
handleChangeLanguange =(e)=> {
  if(e.id !== 7) {
    this.setState({language:e.value, defaultLanguage: e.id})
  } else {
    this.handleChangeCustomLanguange(this.state.language)
  }
  
  // this.setState({language:e.value})
  // console.log('language', this.state.language )

  // this.setState({defaultLanguage:e.id})
}; 
handleChangeTypeDementia =(e)=> {
  if(e.id !== 5) {
    this.setState({dementiaType:e.value})
  } else {
    this.handleChangeCustomTypeDementia(this.state.dementiaType)
  }

  this.setState({defaultType:e.id})
}; 
handleChangeStageDementia =(e)=> {
  this.setState({dementiaStage:e.value})
  console.log('stageDementia', this.state.dementiaStage )
  this.setState({defaultStage:e.id})
}; 
  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state })
  }
 
  changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes,usedService:true})
   }else{ 
       this.setState({Switch: SwitchNo,usedService:false})
   }
  }
  changeGenderM=()=>{
    if(this.state.SwitchGenderM == TypeInactive ){
        this.setState({SwitchGenderM: TypeActive,
                        gender: 'male',
                        SwitchGenderF:TypeInactive})
        
     console.log(this.state.gender)
     }else{ 
         this.setState({SwitchGender: TypeInactive,
                        SwitchGenderM: TypeActive,})
                        
     }
  }
  changeGenderF=()=>{
    if(this.state.SwitchGenderF == TypeInactive ){
        this.setState({SwitchGenderF: TypeActive,
                        gender: 'female', 
                        SwitchGenderM:TypeInactive})
                        
     console.log(this.state.gender)
        
     }else{ 
         this.setState({SwitchGenderM: TypeInactive,SwitchGenderF: TypeActive,})
     }
     
  }

  handleCountryCode =(e)=> {
    this.setState({countryCode:e.value})
    
  this.setState({defaultCountryCode:e.id})
  }; 
  
  renderInputDropdown(props) {
    const { value, onClick, filtered } = props;
    return (
      <Input
        inputProps={{
          readOnly: true,
          onClick: filtered ? onClick : null,
          value,
        }}
        ic
        containerStyle={`
          ${InputReadOnlyStyle}
          .input-field {
            cursor: ${filtered ? 'pointer' : 'not-allowed'}
          }
          input {
            color: ${filtered ? Colors.color2 : Colors.color34} !important;
          }
          `}
        iconRight={Polygon}
        {...props}
        dropdownWidth={'100%'}
      />
    );
  }
  handleClose = ()=>{
  
    this.props.history.push({pathname:'/registrationCreateCGorTagCG',
    state:{
      uid:this.state.uid,
      token: this.state.token,
      streetName:this.state.streetName,
      unitNumber:this.state.unitNumber,
      postalCode:this.state.postalCode,
      cityCountry:this.state.cityCountry,
      idTokenGoogle:this.state.idTokenGoogle,
      credentialType: this.state.credentialType,
      phone:this.state.phone,
      findCaraList: this.state.findCaraList
    }})
    console.log(`${this.state.uid}`)
    console.log(this.state.streetName)
    console.log(this.state.unitNumber)
    console.log(this.state.postalCode)
    console.log(this.state.cityCountry)
    
    console.log(this.state.phone)
    return this.state
  }
  submitForm = ()=>{
    console.log('Nric'+this.state.NIRC)
      if(this.state.usedService){
        
        axios.post(`${accountUrl}/public/credential/signupDementia`, {
          credential : {
              credentialType: "USERNAME",
              credentialData: {
                  username:this.state.username,
                  password:this.state.password
              },
              accountData:{
                  email: this.state.email,
                  firstname: this.state.firstname,
                  lastname: this.state.lastname,
                  displayName: this.state.displayName,
                  gender: this.state.gender,
                  birth: this.state.birth,
                  streetName: '-',
                  unitNumber: '-',
                  postalCode: '-',
                  cityCountry: '-',
                  homeNumber: this.state.homeNumber,
                  phone: parseInt(this.state.phone),
                  lastNric: this.state.NIRC,
                  dementiaHospital: {
                      hospitalName: '-',
                      hospitalDepartment: '-',
                      appointment: 'DAILY',
                      symptoms: '-'
                  },
                dementiaDetail:{
                      language: this.state.language,
                      typeDementia: this.state.dementiaType,
                      stageDementia: this.state.dementiaStage
                  },
                  countryCode: this.state.countryCode,
              }
          }
        })
        .then(res => {
          if(res.status==200 && res.data.type=="SUCCESS"){
            this.setState({show: true})
              this.setState({uid:res.data.data.account.uid,
                              token:res.data.data.account.token})
          }else{
            this.setState({showError: true})
            this.setState({errorMessage: res.data.data.message})
           
          };
        }, (error) => {
          console.log("masuk error", error);
        });
        
      }else{
        var letters = /^[A-Za-z]+$/;
        let result = {}
        
      if((this.state.firstname ==''  && window.sessionStorage.getItem("firstname") == '') || (this.state.lastname ==''  && window.sessionStorage.getItem("lastname") == '') || (this.state.gender ==''  && window.sessionStorage.getItem("gender") == '') || (this.state.NIRC ==''  && window.sessionStorage.getItem("NIRC") == 'null') || 
        (this.state.birth ==''  && window.sessionStorage.getItem("birth") == '') || this.state.birth === null  || (this.state.language =='') || (this.state.typeOfDementia =='') || (this.state.dementiaStage=='') ){
          Swal.fire({
            title: 'Warning!',
            text: 'please fill the required data and choose date',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }else if ((this.state.email != '' && !validator.isEmail(this.state.email) ))  {
          Swal.fire({
            title: 'Warning!',
            text: 'please fill email and phone number correctly',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }else  if (this.state.NIRC.length < 4) {
          Swal.fire({
            title: 'Warning!',
            text: 'Last NRIC must be 4 character.',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        } else if (this.state.phone > 0 && (this.state.countryCode == '' || this.state.countryCode == 'null')) {
          Swal.fire({
            title: 'Warning!',
            text: 'please fill phone number correctly',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }
      
        else if ( 
          ( (!isNaN(this.state.NIRC[0]) || this.state.NIRC[0] >= 0 || this.state.NIRC[0] <= 9) ) && 
          ( (!isNaN(this.state.NIRC[1]) || this.state.NIRC[1] >= 0 || this.state.NIRC[1] <= 9) ) && 
          ( (!isNaN(this.state.NIRC[2]) || this.state.NIRC[2] >= 0 || this.state.NIRC[2] <= 9) ) && 
          this.state.NIRC[3].match(letters)
        ) {

          axios.get(`${accountUrl}/public/checkValidation`, {
            params: {
              lastname: this.state.lastname,
              birth: this.state.birth,
              lastNric: this.state.NIRC,
              type: 'DEMENTIA'
            }
          }).then(res => {
            console.log("data resnya adalah", res)
            if (res.data && res.data.type === 'SUCCESS') {
              if (res.data.data === null || !res.data.data){
                window.sessionStorage.setItem("firstname", this.state.firstname);
                window.sessionStorage.setItem("lastname", this.state.lastname);
                window.sessionStorage.setItem("displayName", this.state.displayName);
                window.sessionStorage.setItem("gender", this.state.gender);
                window.sessionStorage.setItem("birth",this.state.birth);
                window.sessionStorage.setItem("NIRC",this.state.NIRC)
                window.sessionStorage.setItem("defaultType",this.state.defaultType)
                window.sessionStorage.setItem("defaultStage",this.state.defaultStage)
                window.sessionStorage.setItem("defaultLanguage",this.state.defaultLanguage)
                window.sessionStorage.setItem("countryCode", this.state.countryCode)
                window.sessionStorage.setItem("defaultCountryCode", this.state.defaultCountryCode)
                window.sessionStorage.setItem("dateNow",this.state.dateNow)
                 
                if(this.state.email !== null){ window.sessionStorage.setItem("email",this.state.email)}
                
                window.sessionStorage.setItem("homeNumber",this.state.homeNumber)
                window.sessionStorage.setItem("phone",this.state.phone)


                this.props.history.push({pathname:`/registrationResidingAddressPWD`,
                  state:{
                    username: this.state.username,
                    password: this.state.password,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    displayName: this.state.displayName,
                    gender : this.state.gender,
                    NIRC: this.state.NIRC,
                    birth: this.state.birth,
                    language: this.state.language,
                    dementiaType: this.state.dementiaType,
                    dementiaStage: this.state.dementiaStage,
                    email: this.state.email,
                    phone:this.state.phone,
                    homeNumber:this.state.homeNumber,
                    idTokenGoogle:this.state.idTokenGoogle,
                    credentialType: this.state.credentialType,
                    findCaraList: this.state.findCaraList,
                    token: this.state.token,
                    
                    streetName:this.state.streetName,
                    unitNumber:this.state.unitNumber,
                    postalCode:this.state.postalCode,
                    cityCountry:this.state.cityCountry,
                    masterPartnerUid: this.state.masterPartnerUid,
                    partnerToken: this.state.partnerToken,
                    partnerAddress: this.state.partnerAddress,
                    partnerBranch: this.state.partnerBranch,
                    partnerPostalCode: this.state.partnerPostalCode,
                    countryCode: this.state.countryCode,
                  }})
                } else {
                  Swal.fire({
                    title: 'Warning!',
                    text: 'A user with the same last name, NRIC and date of birth already exists',
                    icon: 'info',
                    confirmButtonText: 'OK'
                  })
                }
              } else {
                Swal.fire({
                  title: 'Warning!',
                  text: `${res.data.data.message}`,
                  icon: 'info',
                  confirmButtonText: 'OK'
                })
              }

          }, (error) => {
            console.log("masuk errornya aja", error);
          });
          
    console.log(this.state.username)
    console.log(this.state.firstname)
    console.log(this.state.lastname)
    
    console.log(this.state.gender)
    console.log(this.state.NIRC)
    console.log(this.state.birth)
    console.log(this.state.language)
    console.log(this.state.dementiaType)
    console.log(this.state.dementiaStage)
    console.log(this.state.email)
    
    console.log(this.state.phone)
    return this.state
  
  } else {
  
    Swal.fire({
      title: 'Warning!',
      text: 'Incorrect NRIC format, please input last 3 numbers of NRIC and final alphabet. For e.g If NRIC is S1234123A enter 123A.',
      icon: 'info',
      confirmButtonText: 'OK'
    })
  }
}
  }
  
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
  handleCloseError =()=>{
    this.setState({showError:false})
  }

  // popup modal 
  handleChangeCustomLanguange = (language) => {
    Swal.fire({
     //  title: 'Please fill username and phone number',
      html:
        `<label style="color: #424242;">Please Specify </label><input ${language === languageData[languageData.length - 1].value && `value="${language}"`} id="swal-input-language" class="swal2-input">`,
        showCancelButton: true,
        onOpen: () => {
         const input = document.getElementById('swal-input-language')
   
         if(input.value === '') {
           Swal.getConfirmButton().setAttribute('disabled', '')
         }
   
         input.addEventListener('keyup', (e) => {
           if(e.target.value.trim() === '') {
             Swal.getConfirmButton().setAttribute('disabled', '')
           } else {
             Swal.getConfirmButton().removeAttribute('disabled')
           }
         })
       },
      preConfirm: () => {
        const value = document.getElementById('swal-input-language').value;
        languageData.splice(languageData.length - 1, 1, { id: 6, value: value.trim(), label: value.trim() })
         this.setState({language : value.trim(), defaultLanguage: 7})
      }
    })
  }

  handleChangeCustomTypeDementia = (dementiaType) => {
    Swal.fire({
      //  title: 'Please fill username and phone number',
      html:
        `<label style="color: #424242;">Please Specify </label><input ${dementiaType === typeOfDementiaData[typeOfDementiaData.length - 1].value && `value="${dementiaType}"`} id="swal-input-type-dementia" class="swal2-input">`,
        showCancelButton: true,
        onOpen: () => {
          const input = document.getElementById('swal-input-type-dementia')

          if(input.value === '') {
            Swal.getConfirmButton().setAttribute('disabled', '')
          }

          input.addEventListener('keyup', (e) => {
            if(e.target.value.trim() === '') {
              Swal.getConfirmButton().setAttribute('disabled', '')
            } else {
              Swal.getConfirmButton().removeAttribute('disabled')
            }
          })
        },
      preConfirm: () => {
        const value = document.getElementById('swal-input-type-dementia').value;
        typeOfDementiaData.splice(typeOfDementiaData.length - 1, 1, { id: 5, value: value.trim(), label: value.trim() })
          this.setState({dementiaType : value.trim()})
      }
    })
  }
  render() {
    const {typeOfDementia,homeNumber,phone,NIRC,firstname,lastname, displayName, email,birth,dementiaStage, typeOfDementiaDropdown, languageDropdown,dateSend,delivery,language,setLanguage, languageType,languageFiltered,enderData, genderDropdown,Switch,gender,SwitchGenderF,SwitchGenderM, masterPartnerUid } = this.state
      
    return (
      <>
        <StyledCG>
            <Header title={masterPartnerUid ? `Create PLWD Account` : null} />
            <div style={{marginLeft: '15px'}}>
              <h2 style={{marginTop:'3%'}}> Please input personal details of PLWD</h2>
              <img src={Step1Plwd} style={{marginTop:'40px', width: '800px', marginLeft: 'auto', marginRight: 'auto'}}/>
              {/* <p style={{marginBottom:'3%'}} className="signUp">Please note that all fields in the form are mandatory</p> */}


              <h2 style={{marginTop:'3%', color: 'black'}}>I have Dementia</h2>
              <p style={{ color: 'gray' }}>Information provided will help use process your membership. All fields are mandatory (unless stated optional)</p>
            </div>
            <hr/>
            <Row> 
              <Col  style={{margin:'auto'}}>
                <label>Name displayed for identification purposes</label></Col></Row>
                <Row style={{textAlign:'start'}}>
                    <Col  style={{margin:'auto'}}>
                        
                        <label>First Name (Given name(s) as per NRIC)</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your first name here...',name:"firstname",
                                                                    value:firstname,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('firstname', e.target.value),}} />
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Last Name (Surname as per NRIC) </label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type your last name here... ',name:"lastname",
                                                                    value:lastname,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('lastname', e.target.value),}}/>
                   
                    </Col>
                </Row>
                {/* <Row>
                  <Col  style={{margin:'auto'}}>
                    <label>Display Name</label>
                  </Col>
                  <Col md={6}>
                    <Input 
                      containerStyle="InputStyle"  
                      inputProps={{
                        placeholder:'Type your display name here... ',
                        name:"displayName", 
                        value:displayName,
                        onChange: (e) => this.onChangeText('displayName', e.target.value),}}/>
                   
                  </Col>
                </Row> */}

                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Date of Birth</label>
                    </Col>
                    <Col md={6} style={{margin:'auto'}}>
                    <Row style={{margin:'auto', border: '1px solid #B1C2D8',boxSizing: 'border-box',borderRadius: '15px', marginBottom: '15px'}}>
                    <DatePicker peekNextMonth  showMonthDropdown   showYearDropdown  customInput={<CustomInput />}   filterDate = {(date) => {return moment() > date;}}  dropdownMode= 'select' dateFormat="dd-MM-yyyy" selected={this.state.dateNow ? new Date(this.state.dateNow) : null} onChange={date => { this.setState({dateNow:date.toISOString().slice(0,10),birth:date.toISOString().slice(0,10),})
                                                                                                                      
                                                                                                                      console.log(date.toISOString().slice(0,10))}} placeholderText="Please select a date" />
 
                         
                  </Row>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Gender </label>
                    </Col>
                    <Col md={6}>
                    <Row>
                    
                    <Col md={6} style={{margin:'0px'}}>
                    <button  className="type" onClick={this.changeGenderM}><Row style={{displa:'flex',alignItems:'center'}}><img src={SwitchGenderM} style={{marginRight:'15px'}} /> Male</Row></button>
                    </Col>
                    <Col md={6}>
                    <button className="type"  onClick={this.changeGenderF}><Row style={{displa:'flex',alignItems:'center'}}><img src={SwitchGenderF} style={{marginRight:'15px'}}/> Female</Row></button>
                    </Col>
                    </Row>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Last 4 characters of NRIC</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your NRIC here... ',name:"NIRC",
                                                                    value:NIRC,maxLength:4,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('NIRC', e.target.value),}}/>
                   
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Preferred Language of Communication</label>
                    </Col>
                    <Col md={6}>
                          <Select 
                            onChange={this.handleChangeLanguange} 
                            value={languageData.filter(item => String(item.value) === String(this.state.language))}
                            defaultValue={languageData[window.sessionStorage.getItem("defaultLanguage")] } 
                            options={languageData} 
                          />
                        </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Type of Dementia</label>
                    </Col> 
                    <Col md={6}>
                      <Select 
                      onChange={(e) =>  this.handleChangeTypeDementia(e)} defaultValue={typeOfDementiaData[window.sessionStorage.getItem("defaultType")] }  
                      value={typeOfDementiaData.filter(item => String(item.value) === this.state.dementiaType)}
                      options={typeOfDementiaData} 
                      />
                    </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Stage of Dementia</label>
                    </Col>
                    <Col md={6}>
                    <Select  onChange={(e) =>  this.handleChangeStageDementia(e)} defaultValue={stageofDementiaData[window.sessionStorage.getItem("defaultStage")] } options={stageofDementiaData} />
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Email Address (optional)</label>
                        <p style={{fontSize:'12px', textAlign: 'left'}}>(Email is our preferred communication as it allows us to assist you with account issues quickly. If you have one, please include it)</p>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"   inputProps={{ placeholder:'Type your email here... ', name:"email",
                                        value:email, type:'email',
                                        onChange: (e) =>
                                        this.onChangeText('email', e.target.value)}}  />
                   
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Mobile Number (optional)</label>
                    </Col>
                    <Col md={2} style={{margin:'auto'}} >
                      <Select placeholder='Country Code' className="select"  onChange={(e) =>  this.handleCountryCode(e)} styles={stylesCountryCode} defaultValue={countryCodeData[window.sessionStorage.getItem("defaultCountryCode")] } options={countryCodeData} />
                    </Col>
                    <Col md={4}>
                    <Input containerStyle="InputStyle" inputProps={{ placeholder:'Type your phone number here... ', name:"phone",
                                        value:phone,
                                        onChange: (e) =>
                                        this.onChangeText('phone', `${(e.target.value).replace(/[^0-9]/g, '')}`)}}/>
                   
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Home Number (optional)</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{ placeholder:'Type your home number here... ', name:"homeNumber",
                                        value:homeNumber,
                                        onChange: (e) =>
                                        this.onChangeText('homeNumber', e.target.value)}}/>
                   
                    </Col>
                </Row>
                <Row style={{marginTop:'5%',marginBottom:'5%'}}>
                <Col md={8}></Col>
                <Col md={4} style={{display:'flex',justifyContent:'flex-end'}}>
                  <Button  buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} text="Next" onClick={this.submitForm}/>
                </Col>
              </Row>
                <PopUpBox  show={this.state.show}  handleClose={this.handleClose}/>
               
              
            
            </StyledCG>

        
       
      </>
    )
  }
}



export default PersonDetailPWD
