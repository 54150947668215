import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledTerms = styled.div`
   width:80%;
  margin:auto;
    .active{

        font-family: "TruenoRegular", Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        /* identical to box height, or 83% */
        /* PRIMARY VIOLET */

        color: #502773;
    }
    h4{
        
  padding-top: 50px;
  font-family: "TruenoRegular", Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        /* identical to box height, or 83% */
        /* TXT SECONDARY */    
        color: #758BA9;
    } h3{
  padding-top: 50px;
        margin-bottom:40px;
        font-family: "TruenoRegular", Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        /* identical to box height, or 44% */
        display: flex;
        align-items: center;
        /* TXT STD */
        color: #424242;
    }
    h5{
        font-family: ${Fonts.FontRegular};
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 71% */            
        /* PRIMARY VIOLET */
        color: #502773;
    }
    p{
        
        font-family: "TruenoUltralight", Arial, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        /* or 125% */
        /* TXT STD */     
        color: #424242;
    }
    ul{
        padding-left:20px;
    }
    li{ margin-left:15px;
        padding-bottom:10px;
        font-family: "TruenoUltralight", Arial, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        /* or 125% */
        /* TXT STD */     
        color: #424242;
    }
    @media (max-width: 768px) {
        width:95%;
  margin:auto;
        .active{

            font-family: "TruenoUltralight", Arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height, or 83% */
            /* PRIMARY VIOLET */

            color: #502773;
        }
        h4{
        
        font-family: "TruenoUltralight", Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 83% */
        /* TXT SECONDARY */    
        color: #758BA9;
    }
    h3{width:90%;
  margin:auto;
        margin-bottom:40px;
        font-family: "TruenoUltralight", Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height, or 44% */
        display: flex;
        align-items: center;
        /* TXT STD */
        color: #424242;
    }
    h5{
        font-family: ${Fonts.FontRegular};
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height, or 71% */            
        /* PRIMARY VIOLET */
        color: #502773;
    }
    p{
        
        font-family: "TruenoUltralight", Arial, sans-serif;
      }
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        /* or 125% */
        /* TXT STD */     
        color: #424242;
    }
  }
    .row{
        
        margin:0;
        padding:0;}
   
.faq-row-wrapper {
    .faq-title {
    }

    .faq-body {
        .faq-row {
            .row-title {
                
                font-family: ${Fonts.FontRegular};
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 20px;
                /* identical to box height, or 71% */            
                /* PRIMARY VIOLET */
                color: #502773;

            .icon-wrapper{
                padding:0;margin:0;
                display:flex;
                justify-content:center;
                align-items:center;
                width:25px;
                height:25px;
                color:#502773;
                border-radius: 25px;
                border: 2px solid #7F59A0;
                box-sizing: border-box;
            }
        }
            

            .row-content {
                .row-content-text {
                }
            }
        }
    }
}

`
