import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Logo from '../../assets/images/LogoCara.png'

import { StyledHeader2 } from './StyledHeader2'

function Header2({ title }) {
  return (
    <StyledHeader2>
      <div>
        <a href="https://cara.sg/">
          <img src={Logo} alt="cara" />
        </a>
      </div>
      <div>
        <p className="title"> {title ? title : `Member Registration`}</p>
      </div>
    </StyledHeader2>
  )
}

export default Header2
