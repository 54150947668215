import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledCG = styled.div`
    align-content:center;
     width:80%;
     padding:auto;
     margin:auto;
     .containerCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select img, object {
  max-height:'20px'
}
/* Hide the browser's default checkbox */
.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color:#EFC900;
}

/* On mouse-over, add a grey background color */
.containerCheck:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheck input:checked ~ .checkmark {
  background-color: #EFC900;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheck input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCheck .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
  h2{     
    font-family: ${Fonts.FontRegular};
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 34px;
    /* identical to box height, or 106% */
    color: #502773;
  }
  label{          
        font-family: ${Fonts.FontRegular};
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 34px;
        /* identical to box height, or 170% */
        /* TXT STD */

        color: #424242;
  }
  p{      
    font-family: ${Fonts.FontRegular};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    /* or 243% */
    display: flex;
    align-items: center;
    text-align: center;
    /* PRIMARY VIOLET */
    color: #502773;
  }
 .InputStyle { 
        /* TXT PASSIVE */

        border: 1px solid #B1C2D8;
        box-sizing: border-box;
        border-radius: 15px;
 }
 .signUp{
    font-family: ${Fonts.FontRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 170% */


    /* TXT STD */

    color: #424242;
 }
 .facebook{
    width: 148px;
    background: #2E85EA;  
    height: 40px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    margin-right:10px;margin-bottom:10px
 }
 .google{
    width: 148px;
    height: 40px;
    background: #F5F6F8;
    /* TXT PASSIVE */

    border: 1px solid #B1C2D8;
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    
    margin-right:10px;margin-bottom:10px
 }
 .apple{
   
    width: 148px;
    height: 40px;
background: #424242;
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
border-radius: 15px;
 }
 
.SwitchNo{
   width:200px;
  background: url('../../assets/images/NO.png')
}

.SwitchYes{width:auto;
   background: url('../../assets/images/YES.png')
}

.type{
background: #FFFFFF;
/* TXT PASSIVE */

width: 100%;
height: 50px;
border: 1px solid #B1C2D8;
box-sizing: border-box;
border-radius: 15px;
padding-left:30px;
margin-right:auto
}
h3{

   font-family: ${Fonts.FontRegular};
   font-style: normal;
   font-weight: 800;
   font-size: 20px;
   line-height: 34px;
   /* identical to box height, or 170% */

   color: #FFFFFF;

}
.e-card{
  width:auto;
   margin-bottom:3%;
   border: 1px solid #B1C2D8;
   border-radius:5%
}
.e-card label{
  
font-family: ${Fonts.FontRegular};
font-style: normal;
font-weight: 600;
font-size: 11px;
line-height: 14px;
/* or 127% */

display: flex;
align-items: center;

color: #758BA9;
}
h4{
font-family: ${Fonts.FontRegular};
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 34px;
/* or 243% */

display: flex;
align-items: center;

color: #424242;}
.headerCard{


      /* SECONDAY YELLOW */
      background: #502773;

      border-radius: 15px 15px 0px 0px;
}


.headerCard p{

font-family: ${Fonts.FontRegular};
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 34px;
/* identical to box height, or 243% */


/* TXT STD */

color: #424242;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
  background-color:none;
  background-image: url('../../assets/images/Polygon.png') ;
  background-position: 1px 1px;
    background-repeat: no-repeat;
  border-radius: 10px;
  margin: 3px 0;
  border:none;padding: 5px 9px;
  padding: 3px 5px;
    outline: none;
    cursor:  'pointer';
    font-family: NunitoSans;
    font-weight: bold;
    color: ${Colors.color};
    resize: none;
}

.react-datepicker__navigation--next {
  width: 0; 
  height: 0; 
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  
  border-left: 6px solid #62318C;
}

.react-datepicker__navigation--previous {
  width: 0; 
  height: 0; 
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  
  border-right: 6px solid #62318C;
}
.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}
button{
  border: none;
  background:none
}
i {
  position: absolute;
  top: 34%;
  right: 8%;
}
i:hover {
  color: #00fcb6;
  cursor: pointer;
}
.input{width: 100%;
    padding: 10px 18px;
    outline: none;
    cursor:  'pointer';font-family: NunitoSans;
    font-weight: bold;
    color: ${Colors.color};
    resize: none;
    ${Fonts.size.size24}}
 `
 
export const StyledButton = styled.div`
  width:200px;
  height:20px;margin-top:20px;margin-bottom:50px;
  float:right;
`

export const InputStyle = ` 
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field {  
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
  }
`;
