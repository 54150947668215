import React, { Component } from 'react';
import Navigation from '../components/navigation';
import Section1 from '../components/content/Section1'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './LandingPage.css'
import  '../components/navigation/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {StyledTerms} from './styles/StyledTerms'

import Faq from "react-faq-component";
import { Link } from 'react-router-dom';
class TermsOfUse extends Component {
    componentDidUpdate() {
        window.scrollTo(0,0);
      }
    render(){
   
    return(
        <div>
            <Navigation Home="/" Why="/" How="/" Social="/" Faq="/"/>
                <StyledTerms>
                    
                    <Row style={{borderBottom: '1px solid #758BA9'}}>
                        <Col xs={4} style={{borderBottom: '3px #7F59A0 solid', display:'flex',justifyContent:'center'}}>
                            <h4 className='active'>Terms of Use</h4>
                        </Col>
                        <Col xs={3} style={{display:'flex',justifyContent:'center'}}>
                            <a to='https://cara.sg/privacy-policy/'>
                                <h4>Privacy Policy</h4>
                            </a>
                        </Col>
                        <Col xs={5}  style={{display:'flex',justifyContent:'center',width: '440px'}}>
                            <a to='/https://cara.sg/cara-membership-terms-condition/'>
                                <h4>Membership Terms and Condition</h4>
                            </a>
                        </Col>
                    </Row>
                  
                        <h3>Terms of Use</h3>
                        <p>Thank you for visiting the Dementia Singapore CARA Mobile Application and/or website (“platform”). By accessing and using this platform and it’s associated website www.cara.sg, you shall be deemed to have accepted to be legally bound by these Terms of Use. If you do not agree to these Terms of Use, please do not use this platform.</p>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>General</h5></Row>
                            <Row>
                                <p>Our Terms of Use may be altered from time to time. Changes will be posted on this page and your use of this platform after such changes have been posted will constitute your agreement to the modified Terms of Use and all of the changes.</p>
                                <p>Your privacy is important to us. We are committed to maintaining the confidentiality of the personal information that you give us, whether through this platform or otherwise.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>General Terms</h5></Row>
                            <Row>
                                <p>By accessing and using the platform, you confirm that you are in agreement with and bound by the terms of service contained in the Terms & Conditions outlined below. These terms apply to the entire application and website and any email or other type of communication between you and the CARA platform.</p>
                                <p>Under no circumstances shall the CARA team be liable for any direct, indirect, special, incidental or consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the inability to use, the materials on this site, even if the CARA team or an authorized representative has been advised of the possibility of such damages. If your use of materials from this site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof.</p>
                                <p>CARA (“platform”) will not be responsible for any outcome that may occur during the course of usage of our resources. We reserve the rights to change prices and revise the resources usage policy at any moment.</p>
                            </Row>
                        
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>License</h5></Row>
                            <Row>
                                <p>The platform grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the app strictly in accordance with the terms of this Agreement.</p>
                                <p>These Terms & Conditions are a contract between you and Dementia Singapore (referred to in these Terms & Conditions as "CARA", "us", "we" or "our"), the provider of the CARA platform, website and the services accessible from the CARA website (which are collectively referred to in these Terms & Conditions as the "CARA Programme").</p>
                                <p>You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms & Conditions, please do not use the CARA Programme. In these Terms & Conditions, "you" refers both to you as an individual and to the entity you represent. If you violate any of these Terms & Conditions, we reserve the right to cancel your account or block access to your account without notice.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Meanings</h5></Row>
                            <Row>
                                <p>For this Terms & Conditions:</p>
                                    <ul>
                                        <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                                        <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Dementia Singapore, (20 Bendemeer Road #01-02, BS Bendemeer Centre, Singapore 339914) that is responsible for your information under this Privacy Policy.</li>
                                        <li>Country: where CARA (“platform”) or the owners of the platform are based, in this case is Singapore</li>
                                        <li>Client: refers to the company, organization or person that signs up to use the Dementia Membership Programme Service to manage the relationships with your consumers or service users.</li>
                                        <li>Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit the platform and use the services.</li>
                                        <li>IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.</li>
                                        <li>Personnel: refers to those individuals who are employed by Dementia Singapore, the platform, or are under contract to perform a service on behalf of one of the parties.</li>
                                        <li>Personal Data: any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</li>
                                        <li>Service: refers to the service provided by the platform as described in the relative terms (if available) and on this platform.</li>
                                        <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>
                                        <li>Website: The CARA site, which can be accessed via this URL: <a style={{color:'black'}} href='www.cara.sg'> www.cara.sg</a></li>
                                        <li>You: a person or entity that is registered with CARA to use the Services.</li>
                                    </ul>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Restrictions</h5></Row>
                            <Row>
                                <p>You agree not to, and you will not permit others to:</p>
                                <ul>
                                    <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the app or make the platform available to any third party.</li>
                                    <li>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the app.</li>
                                    <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of the CARA Programme or its affiliates, partners, suppliers or the licensors of the app.</li>
                                </ul>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Links to Other Websites</h5></Row>
                            <Row><p>These Terms & Conditions apply only to the CARA Programme. The platform may contain links to other websites not operated or controlled by the CARA Programme. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the platform to another website, our Terms & Conditions are no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</p></Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Cookies</h5></Row>
                            <Row><p>CARA uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our app but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the app as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.</p></Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Changes To Our Terms & Conditions</h5></Row>
                            <Row>
                                <p>You acknowledge and agree that CARA may stop (permanently or temporarily) providing the Programme(or any features within the Programme) to you or to users generally at CARA’s sole discretion, without prior notice to you. You may stop using the Programme at any time. You do not need to specifically inform CARA when you stop using the Programme. You acknowledge and agree that if CARA  disables access to your account, you may be prevented from accessing the Programme, your account details or any files or other materials which are contained in your account. </p>
                                <p>If we decide to change our Terms & Conditions, we will post those changes on this page, and/or update the Terms & Conditions modification date below.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Modifications to Our app</h5></Row>
                            <Row><p>CARA reserves the right to modify, suspend or discontinue, temporarily or permanently, the app or any service to which it connects, with or without notice and without liability to you.</p></Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Updates to Our app</h5></Row>
                            <Row>
                                <p>CARA may from time to time provide enhancements or improvements to the features/ functionality of the app, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").</p>
                                <p>Updates may modify or delete certain features and/or functionalities of the app. You agree that CARA has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the app to you.</p> 
                                <p>You further agree that all Updates will be (i) deemed to constitute an integral part of the app, and (ii) subject to the terms and conditions of this Agreement.</p>   
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Third-Party Services</h5></Row>
                            <Row>
                                <p>We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").</p>
                                <p>You acknowledge and agree that CARA shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. </p> 
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Third-Party Services</h5></Row>
                            <Row>
                                <p>Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</p> 
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Term and Termination</h5></Row>
                            <Row>
                                <p>This Agreement shall remain in effect until terminated by you or CARA. CARA may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
                                <p>This Agreement will terminate immediately, without prior notice from CARA, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the app and all copies thereof from your computer.</p> 
                                <p>Upon termination of this Agreement, you shall cease all use of the app and delete all copies of the app from your computer.</p>
                                <p>Termination of this Agreement will not limit any of CARA's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Copyright Infringement Notice</h5></Row>
                            <Row>
                                <p>If you are a copyright owner or such owner’s agent and believe any material on our app constitutes an infringement on your copyright, please contact us setting forth the following information: (a) a physical or electronic signature of the copyright owner or a person authorized to act on his behalf; (b) identification of the material that is claimed to be infringing; (c) your contact information, including your address, telephone number, and an email; (d) a statement by you that you have a good faith belief that use of the material is not authorized by the copyright owners; and (e) the a statement that the information in the notification is accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Indemnification</h5></Row>
                            <Row>
                                <p>You agree to indemnify and hold CARA, Dementia Singapore and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the app, website and listed services; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>No Warranties</h5></Row>
                            <Row>
                                <p>The app is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, CARA, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the app, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, CARA provides no warranty or undertaking, and makes no representation of any kind that the app will meet your requirements, achieve any intended results, be compatible or work with any other software, , systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                                <p>Without limiting the foregoing, neither CARA nor any of CARA's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the app, or the information, content, and materials or products included thereon; (ii) that the app will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the app; or (iv) that the app, its servers, the content, or e-mails sent from or on behalf of CARA are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
                                <p>Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Limitation of Liability</h5></Row>
                            <Row>
                                <p>Notwithstanding any damages that you might incur, the entire liability of CARA and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the app.</p>
                                <p>To the maxiSome states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.mum extent permitted by applicable law, in no event shall CARA or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the app, third-party software and/or third-party hardware used with the app, or otherwise in connection with any provision of this Agreement), even if CARA or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                                <p>Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Severability</h5></Row>
                            <Row>
                                <p>If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
                                <p>This Agreement, together with the Privacy Policy and any other legal notices published by Dementia Singapore on the CARA Programme, shall constitute the entire agreement between you and Dementia Singapore concerning the Programme. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and Dementia Singapore’s failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision. YOU AND DEMENTIA SINGAPORE AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Waiver</h5></Row>
                            <Row>
                                <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.</p>
                                <p>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this Agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Amendments to this Agreement</h5></Row>
                            <Row>
                                <p>CARA reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                                <p>By continuing to access or use our app after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use CARA.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Entire Agreement</h5></Row>
                            <Row>
                                <p>The Agreement constitutes the entire agreement between you and CARA regarding your use of the app and supersedes all prior and contemporaneous written or oral agreements between you and CARA.</p>
                                <p>You may be subject to additional terms and conditions that apply when you use or purchase other CARA’s services, which CARA will provide to you at the time of such use or purchase.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Updates to Our Terms</h5></Row>
                            <Row>
                                <p>We may change our Programme and policies, and we may need to make changes to these Terms so that they accurately reflect our Programme and policies. Unless otherwise required by law, we will notify you (for example, through our Programme) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Programme, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Intellectual Property</h5></Row>
                            <Row>
                                <p>The app and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by CARA, its licensors or other providers of such material and are protected by Singapore and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole or in part, without the express prior written permission of CARA, unless and except as is expressly provided in these Terms & Conditions. Any unauthorized use of the material is prohibited.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Agreement to Arbitrate</h5></Row>
                            <Row>
                                <p>This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR CARA’s" INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any dispute, action, or other controversy between you and CARA concerning the Services or this agreement, whether in contract, warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the broadest possible meaning allowable under law.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Notice of Dispute</h5></Row>
                            <Row>
                                <p>In the event of a dispute, you or CARA must give the other a Notice of Dispute, which is a written statement that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to: dpo@dementia.org.sg. CARA will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email address. You and CARA will attempt to resolve any dispute through informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty (60) days, you or CARA may commence arbitration.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Binding Arbitration</h5></Row>
                            <Row>
                                <p>If you and CARA don’t resolve any dispute by informal negotiation, any other effort to resolve the dispute will be conducted exclusively by binding arbitration as described in this section. You are giving up the right to litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute shall be settled by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of competent jurisdiction, as necessary to protect the party’s rights or property pending the completion of arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing party.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Submissions and Privacy</h5></Row>
                            <Row>
                                <p>In the event that you submit or post any ideas, creative suggestions, designs, photographs, information, advertisements, data or proposals, including ideas for new or improved products, services, features, technologies or promotions, you expressly agree that such submissions will automatically be treated as non-confidential and non-proprietary and will become the sole property of CARA without any compensation or credit to you whatsoever. CARA and its affiliates shall have no obligations with respect to such submissions or posts and may use the ideas contained in such submissions or posts for any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing products and services using such ideas.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Promotions</h5></Row>
                            <Row>
                                <p>CARA may, from time to time, include contests, promotions, sweepstakes, or other activities (“Promotions”) that require you to submit material or information concerning yourself. Please note that all Promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. You are responsible to read all Promotions rules to determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide by and to comply with all Promotions Rules.</p>
                                <p>Additional terms and conditions may apply to purchases of goods or services on or through the Services, which terms and conditions are made a part of this Agreement by this reference.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Miscellaneous</h5></Row>
                            <Row>
                                <p>If for any reason a court of competent jurisdiction finds any provision or portion of these Terms & Conditions to be unenforceable, the remainder of these Terms & Conditions will continue in full force and effect. Any waiver of any provision of these Terms & Conditions will be effective only if in writing and signed by an authorized representative of CARA. CARA will be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you. Dementia Singapore operates and controls CARA from its offices in Singapore. The Programme is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation.</p>
                                <p>Accordingly, those persons who choose to access CARA from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. These Terms & Conditions (which include and incorporate the CARA Privacy Policy) contains the entire understanding, and supersedes all prior understandings, between you and CARA concerning its subject matter, and cannot be changed or modified by you. The section headings used in this Agreement are for convenience only and will not be given any legal import.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Disclaimer</h5></Row>
                            <Row>
                                <p>CARA is not responsible for any content, code or any other imprecision. CARA does not provide warranties or guarantees. In no event shall CARA be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Programme or the contents of the Programme. CARA reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</p>
                                <p>The CARA Programme  and its contents are provided "as is" and "as available" without any warranty or representations of any kind, whether express or implied. CARA is a distributor and not a publisher of the content supplied by third parties; as such, CARA exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the CARA Programme. </p>
                                <p>Without limiting the foregoing, CARA specifically disclaims all warranties and representations in any content transmitted on or in connection with the CARA Programme or on sites that may appear as links on the Programme, or in the products provided as a part of, or otherwise in connection with, the CARA Programmee, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by CARA or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, CARA does not warrant that the Programme be uninterrupted, uncorrupted, timely, or error-free.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>Contact Us</h5></Row>
                            <Row>
                                <p>Don't hesitate to contact us if you have any questions.</p>
                                <ul>
                                    <li>Via Email:  dpo@dementia.org.sg</li>
                                    <li>Via Phone Number:  63770700</li>
                                    <li>Via this Link:  alz.org.sg</li>
                                    <li>Via this Address: 20 Bendemeer Road, #01-02 BS Bendemeer Centre, Singapore 339914</li>
                                </ul>
                            </Row>
                        </Col>
            </StyledTerms>
        </div>
        )
    }
}

export default TermsOfUse