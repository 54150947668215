import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledModal = styled.div`
 
  margin:auto
  
`
export const StyledButton = styled.div`
    width:200px;
      height:20px;
    font-family: ${Fonts.FontRegular};
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    margin-top:auto;
    align-items: center;
    text-align: center;
    h2{

      font-family: ${Fonts.FontRegular};
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      /* or 121% */

      text-align: center;

      color: #424242;
    }
    h4{
      
      font-family: ${Fonts.FontRegular};
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 34px;
      /* or 121% */

      text-align: center;

      color: #424242;

    }
`