import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import PopUpBoxHandler from '../../components/modalhandler';
import Indonesia from '../../assets/images/Indonesia.png'
import Singapore from '../../assets/images/Singapore.png'
import Malaysia from '../../assets/images/Malaysia.png'

import back from '../../assets/images/icon-back.png'
import Swal from 'sweetalert2'
import AppConfig from '../../config'
import axios from 'axios'

import { Colors, Fonts, Scale } from '../../themes';
const accountUrl = AppConfig.ACCOUNT_URL

import Select, { components } from 'react-select'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;


const countryCodeData =[
  
  {
    id: 0,
    name : '+65',
    value: '+65',
    label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Singapore} />+65 </Row>
  },{
    id: 1,
    name : '+62',
    value: '+62',
    label: <Row style={{ width:'200px', display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Indonesia} />+62 </Row>
  },
  
  {
    id: 2,
    name : '+60',
    value: '+60',
    label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Malaysia}  />+60  </Row>
  },
]

const stylesCountryCode = {
  
  control: base => ({
    ...base,
    fontFamily: Fonts.FontRegular,
    fontColor: Colors.color,
    fontSize: '14px',
    fontWeight:'bold',
    padding:'0',margin:'0'
  }),
  menu: base => ({
    ...base,
    fontFamily: Fonts.FontRegular,
    padding:'0',margin:'0',
    fontColor: Colors.color,
    fontSize: '14px',
    fontWeight:'bold'
  })
};

export class createNewCG extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          phone:'',
          countryCode:'',
          defaultCountryCode:'',
          username:'',
          password:'',
          confirmPassword:'',
          type: 'password',
          showError:false,
          errorMessage:'',
          enableBtn:true,
          uid:this.props.location.state.uid,
          streetName:this.props.location.state.streetName,
          unitNumber:this.props.location.state.unitNumber,
          postalCode:this.props.location.state.postalCode,
          cityCountry:this.props.location.state.cityCountry,
          building: this.props.location.state.building,
          blockNumber: this.props.location.state.blockNumber,
          partnerToken:this.props.location.state.partnerToken,
          findCaraList:this.props.location.state.findCaraList,
          adminUid: this.props.location.state.adminUid,
          show: false,
        }
      }
      togglePasswordVisiblity = () => {
        if(this.state.passwordShown==false){

          this.setState({passwordShown :true});
          
          this.setState({type:'text'})

        }else{
          this.setState({type:'password'})
          this.setState({passwordShown :false});
          
        }
      };
   handleShow = (e)=> {
    
     this.setState({show: true})
 } 
 
 handleCountryCode =(e)=> {
  this.setState({countryCode:e.value}, ()=>{

    
    console.log('countryCode', this.state.countryCode )
  })
  
this.setState({defaultCountryCode:e.id})
}; 
 submitForm = ()=>{
      
    axios.post(`${accountUrl}/public/credential/checkCaregiverRegistration`, {
      username : this.state.username,
      phone: this.state.phone
 })
 .then(res => {
     console.log(res)
   if(res.status==200 && res.data.data.registered==true){
     
    Swal.fire({
      title: 'Warning!',
      text: 'Username or phone already exist',
      icon: 'info',
      confirmButtonText: 'OK'
    })
   }else{
    if((this.state.username =='' ) || (this.state.phone =='' )){
      Swal.fire({
        title: 'Warning!',
        text: 'please fill the username and phone',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    }else{
      
    this.props.history.push({pathname:'/registrationCreateNewCGDetail',
    state:{
      username: this.state.username,
      password: this.state.password,
      phone:this.state.countryCode.concat(this.state.phone),
      uid:this.state.uid,
      streetName:this.state.streetName,
      unitNumber:this.state.unitNumber,
      postalCode:this.state.postalCode,
      cityCountry:this.state.cityCountry,
      building: this.state.building,
      blockNumber: this.state.blockNumber,
      findCaraList:this.state.findCaraList,
      adminUid: this.state.adminUid
    }})
    
    console.log(this.state.uid)
    console.log(this.state.username)
    console.log(this.state.password)
    console.log(this.state.phone)
    console.log(this.state.streetName)
    console.log(this.state.unitNumber)
    console.log(this.state.postalCode)
    console.log(this.state.cityCountry)
    return this.state
   
  }};
  })
 
}
    
handleCloseError =()=>{
  this.setState({showError:false})
}
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value }, () => {
      if((this.state.password.length >5) &&(this.state.password == this.state.confirmPassword)){

             this.setState({enableBtn:false})
      
     } else{
       this.setState({enableBtn:true})
     };
  })
}

  render() {
      const {type,username,phone,password,enableBtn,showError,confirmPassword}= this.state
    return (
      <>
        <StyledCG>
            <Header/>
            <div onClick={() => this.props.history.goBack()} style={{  width: '70px', height: '30px', background: '#502773', borderRadius: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
              <div><img src={back} alt="back" style={{ width: '9px', objectFit: 'contain' }} /></div>
              <div style={{ fontWeight: 800, fontSize: '13px', color: 'white', marginLeft: '5px' }}>Back</div>
            </div>
            <h2  style={{marginTop:'20px',marginBottom:'4%'}}>Create new Caregiver / Next-of-Kin account</h2>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Username</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your username name here...',name:"username",
                                                                    value:username,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('username', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Password</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" 
                          inputProps={{placeholder:'Type your password here...', name:"password", type:type,value:password,
                                                                  onChange: (e) =>
                                                                     this.onChangeText('password', e.target.value)}}  />
                          <i onClick={this.togglePasswordVisiblity}>{eye}</i>
      
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Confirm Password</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" 
                          inputProps={{placeholder:'Type your confirmation password here...',value:confirmPassword, name:"confirmPassword", type:type,
                          onChange: (e) =>
                                                                     this.onChangeText('confirmPassword', e.target.value)}}  />
                          <i onClick={this.togglePasswordVisiblity}>{eye}</i>
      
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Mobile Number</label>
                    </Col>
                    <Col md={2} style={{margin:'auto'}} >
                    <Select placeholder='Country Code' className="select"  onChange={(e) =>  this.handleCountryCode(e)} styles={stylesCountryCode} defaultValue={countryCodeData[window.sessionStorage.getItem("defaultCountryCode")] } options={countryCodeData} />
                   
                    </Col>
                    <Col md={4}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your phone number here...',name:"phone",
                                                                    value:phone,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('phone', e.target.value),}} />
                    </Col>
                </Row>
                
                <Row style={{justifyContent:'flex-end',marginRight:'1%',marginTop:'5%'}}>
               
                <div style={{ width: '230px' }}>
                  <Button disabled={enableBtn} containerStyle={StyledButton} textStyle={StyledButton} buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} onClick={this.submitForm}  text="Register" />
                  
                </div>
                </Row>
            
          <PopUpBoxHandler show={showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>

            </StyledCG>

        
       
      </>
    )
  }
}


export default createNewCG