import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledRegis = styled.div`
  align-content:center;
  width:80%;
  padding:auto;
  margin:auto;

  .option-container {
    transition: all .3;
    opacity: 0;
    animation: fade 1.2s forwards;
  }

  @keyframes fade {
    from { 
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`