import React from 'react';
import Logo from '../../assets/images/LogoCara.png';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useLocation } from 'react-router-dom';
import { Colors } from '../../themes';

function Navigation(props) {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <Navbar expand="lg" sticky="top" className="nav-container bg-white justify-content-between flex-row-reverse flex-lg-row">
        <Navbar.Brand className='row-xl-7 logo-container' href={props.Home}>
          <img src={Logo} alt="Logo" />
        </Navbar.Brand>
        <div>
          <Navbar.Toggle className='navbar-toggler' data-toggle="collapse" data-target="#Nav" aria-controls="Nav" aria-expanded="false" />
          <Navbar.Collapse id="Nav">
            <Nav className="ml-auto menu sidebar" style={{ alignItems: "center" }}>
              <Navbar.Toggle className='navbar-toggler' data-toggle="collapse" data-target="#Nav" aria-controls="Nav" aria-expanded="true" />
              <Nav.Link href={props.Why}><h6 className="menu">About</h6></Nav.Link>
              <Nav.Link href={props.How}><h6 className="menu">How it Works</h6></Nav.Link>
              <Nav.Link href={props.Social}><h6 className="menu">Benefits</h6></Nav.Link>
              <Nav.Link href={props.Download}><h6 className="menu">Download Now</h6></Nav.Link>
              <Nav.Link href={props.Faq}><h6 className="menu">FAQ</h6></Nav.Link>
              <Nav.Link href={props.DFL} style={{ maxWidth: "178px" }}>
                <h6 className="menu" style={{
                  color: currentPath === "/go-to-points" ? Colors.color11 : undefined
                }}>
                  Dementia-Friendly Locator
                </h6>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}

export default Navigation;
