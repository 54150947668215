import styled, { css } from "styled-components";

import { Fonts, Colors, } from '../../../themes/'

import { deviceSize } from "../../../constants/deviceSize";

export const StyledSigninPartner = styled.div`
  animation: fade 1.2s forwards;
  padding-bottom: 100px;
  overflow: hidden;
  width: 57%;
  margin: 0 auto;
  overflow: hidden;
  font-family: TruenoRegular;

  @media ${deviceSize.tabletL} {
    width: 80%;
  }

  .title-submit {
    ${Fonts.FontRegular}
    font-size: 32px;
    color: ${Colors.color11};
    padding-top: 50px;
  }

  .title-submit2 {
    ${Fonts.FontRegular}
    font-size: 18px;
    color: ${Colors.color12};
  }

  .form-container {
    margin-top: 30px;
  }

  .input-container {
    display: flex;
    align-items: flex-start;

    @media ${deviceSize.smMaterialUI} {
      flex-direction: column;
    }
  }

  .label-partner {
    font-family: "TruenoRegular", Arial, sans-serif;
    ${Fonts.size.size20}
    color: #424242;
    width: 233px;
    position: relative;
    top: 20px;
    @media ${deviceSize.smMaterialUI} {
      padding: 0;
    }
  }

  .button-register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 290px;
    height: 56px;
    background: ${props => props.buttonDisabled ? Colors.color16 : `linear-gradient(132.58deg, #62318C 26.06%, #512874 80.3%)`};
    border-radius: 10px;
    cursor: ${props => props.buttonDisabled ? `not-allowed` : 'pointer'};
    
  }

  .flex_endDesktop_centerMobile{
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }

  .button-register-text {
    ${Fonts.FontRegular};
    font-style: normal;
    display: inline-block;
    font-size: 20px;
    color: #fff;
    font-weight: 800;
  }

  .small-info-container {
    display: flex;
    margin-bottom: 20px;
  }

  .small-info-image {
    width: 14px;
    height: 14px;
    object-fit: contain;
    position: relative;
    top: 3px;
    margin-right: 4px;
  }

  .small-info-text {
    ${Fonts.FontRegular}
    font-size: 12px;
    color: ${Colors.color11};
  }

  .agree-container {
    border-top: 1px solid #B1C2D8;
    padding-top: 42px;
    margin-top: 50px;
  }

  .checkbox-label {
    margin-right: 8px;
  }

  .checkbox-container {
      display: flex;
      margin-top: 20px;
  }

  .agree-text {
    ${Fonts.FontRegular}
    font-size: 16px;
    color:  #758BA9;
  }

  .input-title {
    font-weight: bold;
    font-size: 16px;
    color: #758BA9;
    margin-left: 8px;
  } 

  @keyframes fade {
    from { 
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

   /* Styling Checkbox Starts */
   .checkbox-label {
      display: block;
      position: relative;
      cursor: pointer;
      height: 24px;
      width: 24px;
      clear: both;
      color: white;
  }

  .checkbox-label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      color: white;
  }

  .checkbox-label .checkbox-custom {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 24px;
      width: 24px;
      background-color: #758BA9;
      color: white;
      border-radius: 5px;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      border: 2px solid #FFFFFF;
  }


  .checkbox-label input:checked ~ .checkbox-custom {
      background: #EFC900;
      border-radius: 5px;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity:1;
      color: white;
      border: 2px solid #FFFFFF;
  }


  .checkbox-label .checkbox-custom::after {
      position: absolute;
      content: "";
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 5px;
      border: solid #009BFF;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity:1;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      color: white;
  }


  .checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity:1;
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
    color: white;
  }

  /* For Ripple Effect */
  .checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);  
    color: white;
  }

  .checkbox-label input:checked ~ .checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    opacity:0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    color: white;
  }
`

export const InputStyle = `
  background-color: #FFFFFF;
`