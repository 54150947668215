import React from 'react'
import ReactPlayer from 'react-player'
import "./MediaPlayer.css"


class MediaPlayerScreen extends React.Component {
    render() {
        const url = window.location.href;

        let video = url.split("?video=").pop();
        
        return (
            <div className="player-wrapper">
                <ReactPlayer
                url={video}
                className="react-player"
                width="100%"
                height="100%"
                controls={true}
                />
            </div>
        )
    }
}

export default MediaPlayerScreen;