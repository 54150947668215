// Deps
import ReactMapGL, { AttributionControl, Marker } from "react-map-gl/maplibre";

// Assets
import MarkerLocation from "../../../../assets/images/ion_location-sharp.svg";
import CurrentLocation from "../../../../assets/images/current-location.png";

const Map = ({
  gtps,
  reactMapRef,
  activeMarker,
  setMapLoaded,
  setActiveMarker,
  currentCoordinate,
}) => {
  return (
    <ReactMapGL
      onLoad={() => setMapLoaded(true)}
      ref={reactMapRef}
      width="100%"
      height="100%"
      attributionControl={false}
      maxBounds={[103.596, 1.1443, 104.1, 1.4835]}
      mapStyle="https://www.onemap.gov.sg/maps/json/raster/mbstyle/Default.json"
    >
      {currentCoordinate.latitude && currentCoordinate.longitude && (
        <Marker
          key={"current-location-marker"}
          latitude={currentCoordinate.latitude}
          longitude={currentCoordinate.longitude}
        >
          <img
            src={CurrentLocation}
            style={{
              width: "25px",
              aspectRatio: 1 / 1,
            }}
          />
        </Marker>
      )}

      {gtps.map((gtp) => (
        <Marker
          latitude={gtp.lat}
          longitude={gtp.lng}
          key={`gtp-point-${gtp.id}`}
          onClick={() => {
            setActiveMarker(gtp.id);

            document.getElementById(`gtp-data-${gtp.id}`)?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <img
            src={MarkerLocation}
            style={
              activeMarker === gtp.id
                ? {
                    objectFit: "contain",
                    width: 35,
                    height: 48,
                  }
                : {
                    objectFit: "contain",
                    width: 25,
                    height: 35,
                  }
            }
          />
        </Marker>
      ))}

      <AttributionControl
        position="top-right"
        customAttribution={
          '<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
        }
      />
    </ReactMapGL>
  );
};

export default Map;
