import React, { useState, useEffect, } from 'react'
import Swal from 'sweetalert2'

import { StyledResetPassword, Wrapper,  buttonAllAccounts, buttonAllAplicants } from './styles/StyledResetPassword'
import { Typography, Colors } from '../themes'
import Container from '../components/container'
import Input from '../components/input'
import Button from '../components/button2'

import { resetPassword, verifyTokenResetPassword } from '../api/UserAPI'
import ProccessData from '../lib/ProccessData'

import AppConfig from '../config'

const { Title, SubTitle, ErrorText } = Typography

function ResetPassword({
  location,
  match,
  history,
}) { 
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    errorText: false,
  })

  const { errorText, password, confirmPassword, } = state

  const onSetState = (newState) => {
    setState(state => {
      const valueNewState =  typeof newState == "function" ? newState(state) : newState
      typeof newState == "function" && newState(state)
      return {
        ...state,
        ...valueNewState
      }
    })
  }

  useEffect(() => {
    onVerifyToken()
  }, [])

  const onVerifyToken = async () => {
    try {
      const { token } = match.params
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = await verifyTokenResetPassword({ data: {
          token,
        },
      })
      ProccessData(resultGet, 'reset password')
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        alert({ icon: 'error', title: 'Error!', text: err, exit: true, })
        onSetState({ errorText: JSON.stringify(err) })
      })
    } catch (e) {
      alert({ icon: 'error', title: 'Error!', text: e, exit: true })
      onSetState({ errorText: e.message })
    }
  }

  const onSubmit = async () => {
    try {
      if (password !== confirmPassword) {
        alert({ icon: 'error', title: 'Error!', text: 'Password & Confirm Password not match', })
        return false
      }

      if (password.length < 6 || password.length < 6) {
        alert({ icon: 'error', title: 'Error!', text: 'Password length minimum is 6 characters', })
        return false
      }

      const { token } = match.params
      let result = {}
      result.fetching = false
      result.payload = await resetPassword({ data: {
          password,
          token,
        },
      })
      ProccessData(result, 'reset password')
      .then(res => {
        if (res.type === "SUCCESS") alert({ icon: "success", title: "Success!", text: "Reset password success", exit: true, })
      })
      .catch(err => {
        alert({ icon: 'error', title: 'Error!', text: err, })
        onSetState({ errorText: JSON.stringify(err) })
      })
    } catch (e) {
      alert({ icon: 'error', title: 'Error!', text: e, })
      onSetState({ errorText: e.message })
    }
  }

  const alert = ({ icon, title, preConfirm = () => null, text, exit = false }) => {
    Swal.fire({
      title,
      text,
      icon,
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        exit ? window.open(`${AppConfig.ADMIN_HOSTNAME}/login`, '_self') : preConfirm()
      },
    })
  }

  return (
    <Container>
      <StyledResetPassword>
        <Wrapper>
          <Title customStyle={` color: black; `}>Reset Password</Title>
          <SubTitle customStyles={` color: black; margin-top: 10px; `}>{`Input your new password`}</SubTitle>
          <Input 
            inputProps={{ 
              placeholder: "New Password", 
              onChange: (e) => onSetState({ password: e.target.value }), value: password, maxLength: 50, type: 'password' 
            }} 
            containerStyle={` margin-top: 33px; `} />
          <Input 
            inputProps={{ 
              placeholder: "Confirm New Password", 
              onChange: (e) => onSetState({ confirmPassword: e.target.value }), value: confirmPassword, maxLength: 50, type: 'password'
            }} 
            containerStyle={` margin-bottom: 33px; `} 
          />
          {
            errorText !== '' &&
              <ErrorText customStyles={`margin-bottom: 15px;`}>
                {errorText}
              </ErrorText>
          }
          <div className="buttonContainer">
            <div style={{ flex: 1, }}>
              <Button 
                text={`Reset Password`}
                onClick={onSubmit}
                disabled={password === '' || confirmPassword === ''}
              />
            </div>
          </div>
        </Wrapper>
      </StyledResetPassword>
    </Container>
  )
}

export default ResetPassword
