import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'
import Step2Plwd from '../../assets/images/step2Plwd.png'

import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import colors from '../../themes/Colors';

import { getAddress } from '../../api/UserAPI'

import ProccessData from '../../lib/ProccessData'

import PopUpBoxHandler from '../../components/modalhandler';
export class residingAddress extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            username: this.props.location.state.username,
            password: this.props.location.state.password,
            firstname: this.props.location.state.firstname,
            lastname: this.props.location.state.lastname,
            displayName: this.props.location.state.displayName,
            gender : this.props.location.state.gender,
            NIRC: this.props.location.state.NIRC,
            birth: this.props.location.state.birth,
            language: this.props.location.state.language,
            dementiaType: this.props.location.state.dementiaType,
            dementiaStage: this.props.location.state.dementiaStage,
            email: this.props.location.state.email,
            credentialType: this.props.location.state.credentialType,
            idTokenGoogle: this.props.location.state.idTokenGoogle,
            phone:this.props.location.state.phone,
            homeNumber:this.props.location.state.homeNumber,
            masterPartnerUid: this.props.location.state.masterPartnerUid,
            partnerToken: this.props.location.state.partnerToken,
            findCaraList: this.props.location.state.findCaraList,
            token: this.props.location.state.token,
            streetName:'',
            unitNumber:'',
            postalCode:'',
            cityCountry:'',
            blockNumber: '',
            building: '',

            showError:false,
            errorMessage:'',
            cardAddress: false,
         
            show: false,

            partnerAddress: this.props.location.state.partnerAddress,
            partnerBranch: this.props.location.state.partnerBranch,
            partnerPostalCode: this.props.location.state.partnerPostalCode,
        }
      }
      componentDidMount(){  
        console.log("this.props.location.state.findCaraList => ", this.props.location.state.findCaraList)
      if(  this.props.location.state.streetName){
        this.setState({streetName:  this.props.location.state.streetName})
      }
      if(  this.props.location.state.unitNumber){
        this.setState({unitNumber:  this.props.location.state.unitNumber})
      }
      if(  this.props.location.state.postalCode){
        this.setState({postalCode:  this.props.location.state.postalCode})
      }
      if(  this.props.location.state.cityCountry){
        this.setState({cityCountry:  this.props.location.state.cityCountry})
      }
      }
   handleShow = (e)=> {
    
     this.setState({show: true})
 } 
    
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
  submitForm = ()=>{
    if((this.state.streetName !='' ) && (this.state.postalCode != '' )  && (this.state.cityCountry !='' )  ){
      
      window.sessionStorage.setItem("streetName", this.state.streetName);
      window.sessionStorage.setItem("postalCode", this.state.postalCode);
      window.sessionStorage.setItem("gender", this.state.gender);
      window.sessionStorage.setItem("unitNumber",this.state.unitNumber);
      window.sessionStorage.setItem("blockNumber",this.state.blockNumber);
      window.sessionStorage.setItem("building",this.state.building);
      window.sessionStorage.setItem("NIRC",this.state.NIRC);
      window.sessionStorage.setItem("cityCountry",this.state.cityCountry);

      this.props.history.push({pathname:`/registrationScreeningQuestionPWD`,
        state:{
          username: this.state.username,
          password: this.state.password,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          displayName: this.state.displayName,
          gender : this.state.gender,
          NIRC: this.state.NIRC,
          birth: this.state.birth,
          language: this.state.language,
          dementiaType: this.state.dementiaType,
          dementiaStage: this.state.dementiaStage,
          email: this.state.email,
          streetName:this.state.streetName,
          unitNumber:this.state.unitNumber,
          postalCode:this.state.postalCode,
          cityCountry:this.state.cityCountry,
          blockNumber: this.state.blockNumber,
          building: this.state.building,
          idTokenGoogle:this.state.idTokenGoogle,
          credentialType: this.state.credentialType, 
          phone:this.state.phone,
          findCaraList: this.state.findCaraList,
          token: this.state.token,

          homeNumber:this.state.homeNumber,
          masterPartnerUid: this.state.masterPartnerUid,
          partnerToken: this.state.partnerToken,
          partnerAddress: this.state.partnerAddress,
          partnerBranch: this.state.partnerBranch,
          partnerPostalCode: this.state.partnerPostalCode,
          cardAddress: this.state.cardAddress,
        }
      })
      return this.state
    }else{
      Swal.fire({
        title: 'Warning!',
        text: 'please fill the required data',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    }
  }
  
back=()=>{
  this.props.history.push({
    pathname: '/signUpPersonDetailPWD',
    state: {
      
      idTokenGoogle:this.state.idTokenGoogle,
      password: this.state.password,
      credentialType: this.state.credentialType, 
      username: this.state.username,
      streetName:this.state.streetName,
      unitNumber:this.state.unitNumber,
      postalCode:this.state.postalCode,
      cityCountry:this.state.cityCountry,
      building: this.state.building,
      blockNumber: this.state.blockNumber,
      NIRC: this.state.NIRC,
      masterPartnerUid: this.state.masterPartnerUid,
      partnerToken: this.state.partnerToken,
      partnerAddress: this.state.partnerAddress,
      partnerBranch: this.state.partnerBranch,
      partnerPostalCode: this.state.partnerPostalCode,
      cardAddress: this.state.cardAddress,
    }
  });
}
  handleCloseError =()=>{
    this.setState({showError:false})
  }

  _handleCheckbox = (e, agree) => {
    let checked = e.target.checked;
    this.setState(state => ({
      ...state,
      [agree]: checked
    }))
  }

  onGetAddress = async ({ value }) => {
    try {
      let resultGet = {}
      resultGet.payload = await getAddress({
        searchVal: `${value}`,
        returnGeom: `Y`,
        getAddrDetails: `Y`,
      })
      resultGet.payload.type = 'SUCCESS'
      ProccessData(resultGet, 'get address')
      .then(res => {
        console.log('res address => ', res)
        if (res && res.data && res.data.results && res.data.results.length > 0) {
          this.setState({
            // streetName: `${res.results[0].ADDRESS.replace(value, '')}`
            streetName: `${res.data.results[0].ROAD_NAME}`,
            building: `${res.data.results[0].BUILDING}`,
            blockNumber: `${res.data.results[0].BLK_NO}`,
            cityCountry: 'Singapore'
          })
        }
      })
      .catch(err => {
        alert(err)
      })
    } catch (e) {
      alert(e)
    }
  }

  render() {
    const { streetName, unitNumber, postalCode, cityCountry, building, blockNumber, masterPartnerUid, cardAddress, partnerAddress, partnerBranch, partnerPostalCode, }= this.state
    return (
      <>
        <StyledCG>
          <Header title={masterPartnerUid ? `Create PLWD Account` : null} />
            <h2 style={{marginTop:'3%',marginBottom:'3%'}}>Residing Address</h2>
            <img src={Step2Plwd} style={{marginTop:'40px', width: '800px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '3%'}}/>
            <Row>
              <Col  style={{margin:'auto'}}>
                <label>Postal Code</label>
              </Col>
              <Col md={6}>
              <Input 
                containerStyle="InputStyle"  
                inputProps={{
                  placeholder:'Type your postal code here... ',
                  name:"postalCode",
                  value:postalCode,
                  onChange: (e) => {
                    this.onChangeText('postalCode', e.target.value)
                    this.onGetAddress({ value: e.target.value })
                  },
                }} />
              </Col>
            </Row>
            <Row>
              <Col  style={{margin:'auto'}}>
                <label>Street Name</label>
              </Col>
              <Col md={6}>
                <Input 
                  containerStyle="InputStyle"  
                  inputProps={{
                    placeholder:'Type your street name here...',
                    name:"streetName",
                    value:streetName,
                    onChange: (e) => this.onChangeText('streetName', e.target.value),
                  }} />
              </Col>
            </Row>
            <Row>
              <Col  style={{margin:'auto'}}>
                <label>Building</label>
              </Col>
              <Col md={6}>
                <Input 
                  containerStyle="InputStyle"  
                  inputProps={{
                    placeholder:'Type your building here...',
                    name:"building",
                    value:building,
                    onChange: (e) => this.onChangeText('building', e.target.value),
                  }} />
              </Col>
            </Row>
            <Row>
              <Col  style={{margin:'auto'}}>
                <label>Block Number</label>
              </Col>
              <Col md={6}>
                <Input 
                  containerStyle="InputStyle"  
                  inputProps={{
                    placeholder:'Type your block number here...',
                    name:"blockNumber",
                    value:blockNumber,
                    onChange: (e) => this.onChangeText('blockNumber', e.target.value),
                  }} />
              </Col>
            </Row>
            <Row>
              <Col  style={{margin:'auto'}}>
                <label>Unit Number</label>
              </Col>
              <Col md={6}>
                <Input 
                  containerStyle="InputStyle" 
                  inputProps={{
                    placeholder:'Type your unit number here...',
                    name:"unitNumber",
                    value:unitNumber,
                    onChange: (e) => this.onChangeText('unitNumber', e.target.value),
                  }} />
              </Col>
          </Row>
          <Row>
            <Col style={{margin:'auto'}}  md={6}>
              <label>City, Country</label>
            </Col>
            <Col md={6}>
            <Input 
              containerStyle="InputStyle" 
              inputProps={{
                placeholder:'Type your city country here...',
                name:"cityCountry",
                value:cityCountry,
                onChange: (e) => this.onChangeText('cityCountry', e.target.value),
              }} />
            </Col>
          </Row>   
          {/* <div style={{ marginTop: '13px' }}>
            <div class="checkbox-container">
              <label class="checkbox-label">
                  <input type="checkbox" onClick={(e) => this._handleCheckbox(e, 'cardAddress')} />
                  <span class="checkbox-custom rectangular"></span>
              </label>
              <div class="input-title">Please check this box if you would like CARA Physical card to be sent to another address.</div>
            </div>
          </div> */}
          {
            cardAddress &&
            <>
              <Row style={{ marginTop: '20px' }}>
                <Col  style={{margin:'auto'}}>
                  <label>Address</label>
                </Col>
                <Col md={6}>
                  <Input 
                    containerStyle="InputStyle"  
                    inputProps={{
                      placeholder:'Type your address here...',
                      name:"partnerAddress",
                      value:partnerAddress,
                      onChange: (e) => this.onChangeText('partnerAddress', e.target.value),
                    }} />
                </Col>
              </Row>
              <Row>
                <Col  style={{margin:'auto'}}>
                  <label>Unit Number</label>
                </Col>
                <Col md={6}>
                  <Input 
                    containerStyle="InputStyle" 
                    inputProps={{
                      placeholder:'Type your unit number here...',
                      name:"partnerBranch",
                      value:partnerBranch,
                      onChange: (e) => this.onChangeText('partnerBranch', e.target.value),
                    }} />
                </Col>
            </Row>
            <Row>
              <Col  style={{margin:'auto'}}>
                <label>Postal Code</label>
              </Col>
              <Col md={6}>
              <Input 
                containerStyle="InputStyle"  
                inputProps={{
                  placeholder:'Type your postal code here... ',
                  name:"partnerPostalCode",
                  value:partnerPostalCode,
                  onChange: (e) => this.onChangeText('partnerPostalCode', e.target.value),
                }} />
              </Col>
            </Row>
            </>
          }
          <Row style={{marginTop:'6%',marginBottom:'6%'}}>
            <Col md={6}></Col>
            <Col md={3}>
              <Button onClick={this.back} containerStyle={StyledButton} textStyle={StyledButton}  buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} backgroundColor={colors.color3} textColor={colors.color11} bordered={colors.color11} text="Back" />
            </Col>
            <Col md={3}>
              <Button onClick={this.submitForm} text="Next" containerStyle={StyledButton} textStyle={StyledButton} buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} 
              disabled={ 
                streetName === '' || !streetName ||
                postalCode === '' || !postalCode ||
                cityCountry === '' || !cityCountry 
              }  
              />
            </Col>
          </Row>
        </StyledCG>

        
       
      </>
    )
  }
}


export default residingAddress