import React, { Component } from 'react'
import Header from '../components/header'
import OptionType from '../components/optiontype'
import TabsIndicator from '../components/tabsIndicator';
import queryString from 'query-string';
import Swal from 'sweetalert2'

import SigninPartner from './registrationPartner/SigninPartner';

import {StyledRegis} from "./styles/StyledRegist";

import { verifyToken } from '../api/UserAPI';

import ProccessData from '../lib/ProccessData'

export class Registation extends Component {
  constructor(props) {
    super(props)
    // this.state.showing = 'OPTION' || 'SIGNIN'
    this.state = {
      showing: 'OPTION',
      token: null
    }
  }

  componentDidMount(){
    const token = queryString.parse(this.props.location.search).token
    
    if(token) this.tokenCheck(token)
  }

  tokenCheck = async (token) => {
    try {
      let resultCheck = {}
      resultCheck.payload = await verifyToken({ headers: {
        Authorization: `${token}`
      }})
      ProccessData(resultCheck  , 'check token')
      .then(res => {
        this.setState({token: token})
      })
      .catch(err => {
        this.alertError(err)
      })
    } catch (e) {
      this.alertError(e)
    }
  }

  alertError = (text, exit = true) => {
    Swal.fire({
      title: 'Error!',
      text,
      icon: 'error',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        console.log('exit => ', exit)
        if (exit) {
          this.props.history.replace('/')
        } else null
      },
    })
  }

  render() {
    const { showing, token } = this.state
    return (
      <>
        <StyledRegis>
            <Header/>
            <OptionType token={token ? `?token=${token}` : ''}/>
            {/* <div style={{ marginTop: '30px' }}>
              <TabsIndicator
                setShowing={(showing) => this.setState({ showing })}
              />
            </div>
            {
              showing === 'SIGNIN' ?
                <SigninPartner />
              :
                <div className="option-container">
                  <OptionType />
                </div>
            } */}
        </StyledRegis>
      </>
    )
  }
}


export default Registation