import React, { useEffect, useState, } from 'react'
import PropTypes from 'prop-types'

import { StyledTabsIndicator } from './StyledTabsIndicator'

function TabsIndicator({ setShowing, }) {
  const [state, setState] = useState({
    stateShowing: 'OPTION'
  })

  useEffect(() => {
    const indicator = document.querySelector('.nav-indicator');
    const items = document.querySelectorAll('.nav-item');

    function handleIndicator(el) {
      items.forEach(item => {
        item.classList.remove('is-active');
        item.removeAttribute('style');
      });
      
      indicator.style.width = `${el.offsetWidth}px`;
      indicator.style.left = `${el.offsetLeft}px`;
      indicator.style.backgroundColor = el.getAttribute('activeColor');

      el.classList.add('is-active');
      el.style.borderBottom = `1px solid ${el.getAttribute('activeColor')}`;
      el.style.color = `${el.getAttribute('textColor')}`;
    }


    items.forEach((item, index) => {
      item.addEventListener('click', (e) => {
        console.log('event => ', e)
        handleIndicator(e.target)
      });
      item.classList.contains('is-active') && handleIndicator(item);
    });

    return () => {
      
    }
  }, [])

  return (
    <StyledTabsIndicator>
      <nav class="nav">
        <div 
          onClick={() => {
            setState(state => ({
              ...state,
              stateShowing: 'SIGNIN'
            }))
            setShowing('SIGNIN')
          }} 
          className="nav-item"
          activeColor="#EFC900"
          textColor="#424242"
        >
          Sign in
        </div>
        <div 
          onClick={() => {
            setState(state => ({
              ...state,
              stateShowing: 'OPTION'
            }))
            setShowing('OPTION')
          }} 
          className="nav-item is-active"
          activeColor="#EFC900"
          textColor="#424242"
        >
          Sign up
        </div>
        <span activeColor="#EFC900" class="nav-indicator"></span>
      </nav>
    </StyledTabsIndicator>
  )
}

TabsIndicator.propTypes = {
  setShowing: PropTypes.func,
}

TabsIndicator.defaultProps = {
  setShowing: () => null,
}

export default TabsIndicator


