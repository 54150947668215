import { useLayoutEffect, useState } from "react";
import DesktopGTP from "./viewport/desktop";
import MobileGTP from "./viewport/mobile";
import Navigation from "../../components/navigation";
// import { useHistory } from "react-router-dom";

const GoToPoints = () => {
  const [viewport, setViewport] = useState(null);

  const [isLanding, setLanding] = useState(false);

  // const data = useHistory();

  useLayoutEffect(() => {
    const updateSize = setViewport(
      window.innerWidth >= 576 ? "desktop" : "mobile"
    );

    window.addEventListener("resize", updateSize);

    if (
      window.location.pathname
        .split("/")
        .filter((el, i, arr) => i === arr.length - 1)[0] === "landing"
    )
      setLanding(true);
    else setLanding(false);

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      {viewport === "desktop" ? (
        <>
          {isLanding && <Navigation />}
          <DesktopGTP isLanding={isLanding} />
        </>
      ) : viewport === "mobile" ? (
        <MobileGTP />
      ) : null}
    </>
  );
};

export default GoToPoints;
