import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledCardImage = styled.div`

  width:290px;
  margin: 0 auto;
  margin-bottom:20px;
  
  .title {
    font-family: "TruenoBold", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    lineHeight: 26px;
    /* identical to box height, or 71% */
    display: flex;
    justify-content:flex-start;
    align-items: center;
    color: #502773;
    margin-top: 10px;
    margin-left: 7px;
  }

  .image{
    height: 202px;
    filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.07));
    border-radius: 10px;
    width: 100%;
    object-fit: contain;
  }

  .button-text {
    margin-top: 30px;
    margin-bottom: 50px;
    // font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 140% */
    color: ${Colors.color6};
  }

  .text {
    font-family: "TruenoRegular", Arial, sans-serif;
    font-size: 18px;
    /* or 137% */
    padding-top:20px;
    margin-left: 7px;
    line-height: 1.7;
    /* TXT SECONDARY */
    
    color: #424242;
  }

@media (max-width:480px){
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */

    display: flex;
    align-items: center;

    color: #502773;
    }
    
  .image{
    width: auto;
    margin: 0 auto;
  }
}
`