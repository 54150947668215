import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton,InputStyle} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'
import barcode from '../../assets/images/barcode.png'
import styled from 'styled-components'

import Button from '../../components/button'
import Search from '../../assets/images/searchIcon.png'
import brain from '../../assets/images/brain.png'
import noPhoto from '../../assets/images/nophoto2.png';

import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'
import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import PictureCard from '../../assets/images/iconProfile.jpeg'
import back from '../../assets/images/icon-back.png'
import qr from '../../assets/images/qr.png'

import Swal from 'sweetalert2'
import PopUpBox from '../../components/modal'


import AppConfig from '../../config'

import Select from 'react-select'
import PopUpBoxHandler from '../../components/modalhandler';
const accountUrl = AppConfig.ACCOUNT_URL


import moment from 'moment'
import { Images, Colors } from '../../themes';
import axios from 'axios';
const InputDateStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`
const InputReadOnlyStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin: 10px 0 25px 0; 
.input-field { cursor: pointer; }
`;

const InputTypeStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin-top: 10px; 
.input-field { cursor: pointer; }
`;


const relationshipStatusData =
[
      {
        id: 0,
        name: `Uncle / Aunt`,
        value: `Uncle / Aunt`,
        label: `Uncle / Aunt`
      },
      {
        id: 1,
        name:  'Spouse',
        value: `Spouse`,
        label:  `Spouse`
      },
      {
        id: 2,
        name:  'Parent',
        value: `Parent`,
        label:  `Parent`
      },
      {
        id: 3,
        name:  'Grandparent',
        value: `Grandparent`,
        label: `Grandparent`
      },
      {
        id: 4,
        name:  'Child',
        value: `Child`,
        label:  `Child`
      },
      {
        id: 5,
        name: 'Grandchild',
        value: `Grandchild`,
        label:  `Grandchild`
      },
      {
        id: 6,
        name: `Nephew / Niece`,
        value: `Nephew / Niece`,
        label:  `Nephew / Niece`
      },
      {
        id: 7,
        name: `Organisation Staff`,
        value: `Organisation Staff`,
        label:  `Organisation Staff`
      },
      {
        id: 8,
        name:  'Volunteer',
        value: `Volunteer`,
        label:  `Volunteer`
      },
      {
        id: 9,
        name: 'Others, please specify',
        value: 'Others, please specify',
        label: 'Others, please specify',
      },
    ]

const relationshipStatusData2 =
[
  {
    id: 0,
    name: `Organisation Staff`,
    value: `Organisation Staff`,
    label:  `Organisation Staff`
  },
  {
    id: 1,
    name:  'Volunteer',
    value: `Volunteer`,
    label:  `Volunteer`
  },
]

export class tagNewPWDSelectRelation extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          ...this.props.location.state
          //   uidTagged: '',
          //   token:(this.props.location && this.props.location.state && this.props.location.state.token) ? this.props.location.state.token : '',
          //   uidTags:(this.props.location && this.props.location.state && this.props.location.state.uid) ? this.props.location.state.uid : '',
          //   partnerToken:(this.props.location && this.props.location.state && this.props.location.state.partnerToken) ? this.props.location.state.partnerToken : '',
          //   searchCG:'',
          //   searchCGName:'',
          //   resultName:'',
          //   cityCG:'',
          //   membershipCG:'',
          //   searchCGNric:'',email:'',lastname:'',
          //   searchBy:'',
          //   relationship:'',
          //   relationshipStatus:'',
          //   data:[],
          //   showError:false,
          // errorMessage:'',
          //   Switch: SwitchNo,
          //   SwitchName:TypeInactive,
          //   SwitchNumber:TypeInactive,
        
        }
      }
      handleShow = (i)=> {
        if(this.state.relationship == ''){
          Swal.fire({
            title: 'Warning!',
            text: `Please select This Person Living with Dementia is my `,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }else{
        axios.post(`${accountUrl}/public/accounts/tagMyOtherAccount`, {
          uidTagged: i,
          uidTags: parseInt(this.state.uidTags),
          status: "PENDING",
          relationship: this.state.relationship,
          },{headers: {
            Authorization: `${this.state.token}`
          }},)
          .then(res => {
            console.log(res)
            if(res.status==200 && res.data.type=="SUCCESS"){
              
     this.setState({show: true})
            }else{
              Swal.fire({
                title: 'Warning!',
                text: `${res.data.data.message}`,
                icon: 'info',
                confirmButtonText: 'OK'
              })
            };
          }, (error) => {
            console.log(error);
          });
        }
    }
    handleClose = (e)=> {
     this.setState({show: false})
     if (this.state.partnerToken) {
      window.open(`${AppConfig.ADMIN_HOSTNAME}/users`, "_self")
      // this.props.history.push('/')
     } else  {
       this.props.history.push({pathname:'/registrationNotification'})
     }
    }

  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state })
  } 
  // handleChangeRelationship =(e)=> {
  //   this.setState({relationship:e.value})
  //   console.log('relationship', this.state.relationship )
  //   this.setState({relationshipStatus:e.id})
  // };
  handleChangeRelationship =(e)=> {
    if(e.id !== 9) {
      this.setState({relationship:e.value})
      this.setState({relationshipStatus:e.id})
    } else {
      this.handleChangeCustomRelationship(this.state.relationship, this.state.relationshipStatus)
    }
    // this.setState({relationship:e.value})
    // this.setState({relationshipStatus:e.id})
  };
  handleChangeCustomRelationship = (relationship, relationshipId) => {
    Swal.fire({
     //  title: 'Please fill username and phone number',
      html:
        `<label style="color: #424242;">Please Specify </label><input ${relationship === relationshipStatusData[relationshipStatusData.length - 1].value && `value="${relationship}"`} id="swal-input-type-relationship" class="swal2-input">`,
        showCancelButton: true,
        onOpen: () => {
          const input = document.getElementById('swal-input-type-relationship')

          if(input.value === '') {
            Swal.getConfirmButton().setAttribute('disabled', '')
          }

          input.addEventListener('keyup', (e) => {
            if(e.target.value.trim() === '') {
              Swal.getConfirmButton().setAttribute('disabled', '')
            } else {
              Swal.getConfirmButton().removeAttribute('disabled')
            }
          })
        },
      preConfirm: () => {
        const value = document.getElementById('swal-input-type-relationship').value;
        relationshipStatusData.splice(relationshipStatusData.length - 1, 1, { id: 9, value: value.trim(), label: value.trim() })
         this.setState({relationship : value.trim()})
        this.setState({relationshipStatus:relationshipId})
      }
    })
    }
  
  changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes})
   }else{ 
       this.setState({Switch: SwitchNo})
   }
  }
  changeNumber=()=>{
    if(this.state.SwitchNumber == TypeInactive ){
        this.setState({SwitchNumber: TypeActive,searchBy:'NRIC', SwitchName:TypeInactive})
        
     }else{ 
         this.setState({SwitchGender: TypeInactive,SwitchGenderM: TypeActive,})
     }
  }
  changeName=()=>{
    if(this.state.SwitchName == TypeInactive ){
        this.setState({SwitchName: TypeActive,searchBy:'name', SwitchNumber:TypeInactive})
        
     }else{ 
         this.setState({SwitchGenderM: TypeInactive,SwitchGenderF: TypeActive,})
     }
  }
  getCG = () =>{
    if (this.state.partnerToken) {
      this.partnerSearch()
    } else {
      if(this.state.searchCGName !='' && this.state.searchCGNric !=''){
        axios.get(`${accountUrl}/public/accounts/usersFiltered?name=${this.state.searchCGName}&lastNric=${this.state.searchCGNric}&type=DEMENTIA`, 
          )
          .then(res => {
            console.log(res)
            console.log(this.state.phone)
            let CGName = this.state.searchCGName
            this.setState({resultName:CGName})
            if(res.status==200 && res.data.type=="SUCCESS"){
              
                console.log(this.state.token)
                this.setState({data:res.data.data})
               
            }else{
              Swal.fire({
                title: 'Warning!',
                text: `${res.data.data.message}`,
                icon: 'info',
                confirmButtonText: 'OK'
              })
            };
          }, (error) => {
            console.log(error);
          });}else{
            Swal.fire({
              title: 'Warning!',
              text: `Please Fill the name and last NRIC`,
              icon: 'info',
              confirmButtonText: 'OK'
            })
          }
    }
  }

  partnerSearch = () => {
    if(this.state.searchCGName !='' && this.state.searchCGNric !=''){
      axios.get(`${accountUrl}/partner/users?name=${this.state.searchCGName}&lastNric=${this.state.searchCGNric}&type=DEMENTIA`, {
        headers: {
          Authorization: this.state.partnerToken,
        }
      })
      .then(res => {
        let CGName = this.state.searchCGName
        this.setState({resultName:CGName})
        if( res.status == 200 && res.data.type == "SUCCESS") {
          console.log(this.state.token)
          this.setState({data:res.data.data})
        } else {
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        };
      }, (error) => {
        console.log(error);
      });
    } 
    
    else {
      Swal.fire({
        title: 'Warning!',
        text: `Please Fill the name and last NRIC`,
        icon: 'info',
        confirmButtonText: 'OK'
      })
    }
    
  }
  
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
  
  handleCloseError =()=>{
    this.setState({showError:false})
  }
  render() {
    const {SwitchName,email,data, SwitchNumber,lastNric,searchCGName,searchCGNric, searchCG ,cityCG, nameCG,membershipCG, lastname, gender,SwitchGenderF,SwitchGenderM, partnerToken } = this.state
    
    const styles = {
      card: {
        backgroundColor: 'white',
        height: 200,
        width: 270,
        overflow: 'auto',
        borderRadius: 10,
        boxShadow: '3px 3px 10px #CCD5E1',
      },
      header: {
        height: '25%',
        backgroundColor: '#502773',
        position: 'relative',
      },
      title: {
        color: 'white',
        fontSize: 16,
      },
      subtitle: {
        color: '#424242',
        fontSize: 12,
      },
      container: {
        width: '88%',
        height: '100%',
        margin: '0 auto',
      },
      img: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        marginRight: 10,
      },
      control: {
        position: 'absolute',
        top: 20,
        display: 'flex',
      },
      main: {
        height: '75%',
      },
      control2: {
        borderTop: '1px solid #D1DAE4',
        paddingTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        top: 45,
      },
      title2: {
        fontSize: 11,
        color: '#758BA9',
        lineHeight: 0.2,
      },
      subtitle2: {
        color: '#424242',
        fontSize: 12,
        lineHeight: 0.2,
      },
      barcode: {
        width: 50,
        height: 50,
      }
    }

    return (
      <>
        <StyledCG>
            <Header/>
            <div onClick={() => this.props.history.goBack()} style={{ marginBottom: '20px', width: '70px', height: '30px', background: '#502773', borderRadius: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
              <div><img src={back} alt="back" style={{ width: '9px', objectFit: 'contain' }} /></div>
              <div style={{ fontWeight: 800, fontSize: '13px', color: 'white', marginLeft: '5px' }}>Back</div>
            </div>
                <Row>
                  <Col md={12}>
                    <div style={{
                      backgroundColor: '#F9F9F9',
                      width: '100%',
                      height: '350px',
                      borderRadius: 8,
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '6%',
                    }}>
                      <div style={styles.card}>
                        <div style={styles.header}>
                          <div style={{...styles.container}}>
                            <div style={styles.control}>
                              <img src={this.state.taggedPhoto ? this.state.taggedPhoto : PictureCard} style={styles.img} />
                              <div>
                                <p style={styles.title}>{this.state.taggedName}</p>
                                <p style={styles.subtitle}>{this.state.taggedMembershipId}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={styles.main}>
                          <div style={{...styles.container}}>
                            <div style={styles.control2}>
                              <div>
                                <p style={styles.title2}>Member Since</p>
                                <p style={styles.subtitle2}>{this.state.memberSince}</p>
                              </div>
                              <img src={barcode} style={styles.barcode} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <img src={brain} style={{
                        width: 300,
                        height: 300,
                        position:'absolute',
                        bottom: 0,
                        right: 0,
                      }} alt="Cara" />
                    </div>
                  </Col>
                </Row>
                <Row style={{marginTop:'40px',marginBottom:'20px'}}>
                  <Col md={6} style={{margin:'auto'}}>
                      <label>Relationship to Caregiver</label>
                  </Col>
                  <Col md={6} >
                  <Select  
                    onChange={(e) =>  this.handleChangeRelationship(e)} 
                    // defaultValue={relationshipStatusData[window.sessionStorage.getItem("RelationshipStatus")] } 
                    value={relationshipStatusData.filter(item => String(item.value) === String(this.state.relationship))}
                    options={relationshipStatusData} 
                  />
                  </Col>
                  
              </Row>
                <Row style={{display:'flex', marginTop: '20px' ,justifyContent:'flex-end',marginRight:'10px'}}>
                <Col md={4}>
                <CustomButton color="#62318C" onClick={() => this.handleShow(this.state.id)}>
                  Add
                </CustomButton>
                <PopUpBox body="The user has been successfully added! Please note that this has to be approved in the person living with dementia's account." show={this.state.show}  handleClose={this.handleClose}/>
                              <PopUpBoxHandler show={this.state.showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
                </Col>
                {/* <Button buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} text="Search"  onClick={this.getCG}/> */}
                </Row>     
            </StyledCG>
        
       
      </>
    )
  }
}

const CustomButton = styled.button`
  background-color: ${props => props.color} !important;
  font-size: 18px !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100% !important;
  height: 50px !important;
  box-shadow: -3px -3px 10px #FFFFFF, 3px 3px 10px #C1CAD7 !important;
  font-weight: bold !important;
  cursor: pointer !important; 
`;
