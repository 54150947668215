// Deps
import Modal from "react-awesome-modal";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";

// Components
import Map from "../components/Map";
import GTPCard from "../components/GTPCard";
import FilterGTP from "../components/FilterGTP";
import Button from "../../../components/button2";
import ToggleLanguages from "../components/ToggleLanguages";

// Helpers
import { fetchGTPs } from "../helpers";
import { changeLanguage } from "../../../language/i18n";

// Custom hooks
import { useWindowWidth } from "../../../lib/GetWIndowSize";

// Images
import NoGTP from "../../../assets/images/no-gtp.png";
import Logo from "../../../assets/images/cara-logo.png";

// Styles
import styles from "../components/styles";

// Constants
import { Colors } from "../../../themes";

// CSS
import "../../../styles/gtp.css";
import "maplibre-gl/dist/maplibre-gl.css";

const DesktopGTP = ({ isLanding }) => {
  // Refs
  const ref = useRef(null),
    reactMapRef = useRef(null);

  // Hooks
  const { t } = useTranslation(),
    windowWidth = useWindowWidth();

  // States
  const [currentCoordinate, setCurrentCoordinate] = useState({
      status: false,
      latitude: 1.2901003,
      longitude: 103.8508516,
      zoom: 15,
    }),
    [lang, setLang] = useState("en"),
    [gtps, setGtps] = useState([]),
    [isMapLoaded, setMapLoaded] = useState(false),
    [activeMarker, setActiveMarker] = useState(null),
    [isLoading, setLoading] = useState(true),
    [search, setSearch] = useState(""),
    [postalCode, setPostalCode] = useState(""),
    [region, setRegion] = useState(""),
    [suggestedPostalCodes, setSuggestedPostalCodes] = useState([]),
    [isNearestPostalCodeModal, setIsNearestPostalCodeModal] = useState(false),
    [isNearestPostalCode, setIsNearestPostalCode] = useState(false);

  // Constants
  const shorthandFetchGTPsOption = {
    gtps,
    reactMapRef,
    isMobile: false,
    currentCoordinate,
    setIsNearestPostalCodeModal,
    setIsNearestPostalCode,
    setLoading,
    setGtps,
  };

  // Functions
  const settingCurrentPosition = (latitude, longitude) => {
      setCurrentCoordinate({
        status: true,
        latitude,
        longitude,
      });
    },
    handleFilter = (search, postalCode, region) => {
      fetchGTPs(
        false,
        false,
        search,
        postalCode,
        region,
        shorthandFetchGTPsOption
      );
    },
    handleSuggestedPostalCodes = (postalCodes) =>
      setSuggestedPostalCodes(postalCodes);

  // Effects
  useEffect(() => {
    if (isMapLoaded) {
      navigator.geolocation.getCurrentPosition(
        (pos) =>
          settingCurrentPosition(pos.coords.latitude, pos.coords.longitude),
        (e) => {
          settingCurrentPosition(1.2901003, 103.8508516);
          console.error(e);
        }
      );
    }
  }, [isMapLoaded]);

  useEffect(() => {
    if (currentCoordinate.status) {
      fetchGTPs(
        currentCoordinate.latitude,
        currentCoordinate.longitude,
        null,
        null,
        null,
        shorthandFetchGTPsOption
      );
    }
  }, [currentCoordinate]);

  useEffect(() => {
    changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target))
        setSuggestedPostalCodes([]);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={styles.desktopContainer(windowWidth)}>
      {!isLanding && (
        <img
          onClick={() => (window.location.href = "/")}
          style={styles.caraLogo}
          src={Logo}
        />
      )}
      <div style={styles.rowContainer}>
        <div style={styles.mapContainer}>
          <Map
            gtps={gtps}
            reactMapRef={reactMapRef}
            activeMarker={activeMarker}
            currentCoordinate={currentCoordinate}
            setActiveMarker={setActiveMarker}
            setMapLoaded={setMapLoaded}
          />
        </div>
        <div style={styles.rowDivider} />

        <div
          style={{
            gap: 20,
            display: "flex",
            position: "relative",
            flexDirection: "column",
          }}
        >
          <div style={styles.gtpsContainer}>
            <div className="search-container">
              <FilterGTP
                onSearch={(search, postalCode, region) => {
                  setSearch(search);
                  setPostalCode(postalCode);
                  setRegion(region);
                  handleFilter(search, postalCode, region);
                }}
                search={search}
                setSearchValue={setSearch}
                setSuggestedPostalCodes={handleSuggestedPostalCodes}
              />
              <button onClick={() => handleFilter(search, postalCode, region)}>
                <a href="#download">List Location</a>
              </button>
            </div>

            <div
              ref={ref}
              style={{
                bottom: -50,
                width: "100%",
                borderRadius: 10,
                overflow: "hidden",
                background: "white",
                position: "absolute",
                boxShadow: "2px 2px 2px 1px rgb(0 0 0 / 20%)",
                display: suggestedPostalCodes.length ? "flex" : "none",
                flexDirection: "column",
              }}
            >
              {suggestedPostalCodes.map((el) => {
                const searchTerm = search;
                const regex = new RegExp(`(${searchTerm})`, "gi");
                const parts = el.split(regex);

                return (
                  <div
                    style={{
                      margin: 0,
                      width: "100%",
                      display: "flex",
                      padding: "10px",
                      cursor: "pointer",
                      userSelect: "none",
                      flexDirection: "row",
                    }}
                    onClick={() => {
                      setSearch(el);
                      handleSuggestedPostalCodes([]);
                      handleFilter(el, postalCode, region);
                    }}
                  >
                    {parts.map((part, index) =>
                      part.toLowerCase() === searchTerm.toLowerCase() ? (
                        <p
                          key={index}
                          style={{ fontWeight: "bold", margin: 0 }}
                        >
                          {part}
                        </p>
                      ) : (
                        part
                      )
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <h3 style={styles.nearestText}>
            {isNearestPostalCode
              ? `${t("ShowingTheClosestResultsOf")} ‘${search}’`
              : t("NearestGTPText")}
          </h3>
          <div>
            {gtps.length ? (
              gtps.map(({ id, name, address }, i) => {
                const active = id === activeMarker;
                return (
                  <GTPCard
                    id={id}
                    name={name}
                    active={active}
                    key={`gtp-${i}`}
                    address={address}
                    setActiveMarker={setActiveMarker}
                  />
                );
              })
            ) : !isLoading ? (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img
                  src={NoGTP}
                  style={{
                    height: 150,
                    objectFit: "contain",
                  }}
                />
                <div
                  style={{
                    gap: 4,
                    display: "flex",
                    padding: "20px 0",
                    flexDirection: "column",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      fontSize: 15,
                      fontWeight: 800,
                      color: Colors.color2,
                      textAlign: "center",
                    }}
                  >
                    {t("NoResultText")}
                  </h3>
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 500,
                      color: Colors.color2,
                      textAlign: "center",
                    }}
                  >
                    {t("TryAnotherLocationText")}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div style={styles.footerContainer}>
        <div style={styles.tipContainer}>
          <p style={styles.emergencyText}>{t("IfThisIsAnEmergencyText")}</p>
          <p style={styles.helplineText}>{t("DementiaHelplineText")}</p>
          <div style={styles.contactContainer}>
            <p style={styles.contactNumberText}>6377 0700</p>
            <a href="/" style={styles.websiteText}>
              cara.sg
            </a>
          </div>
        </div>
        <ToggleLanguages setLang={setLang} lang={lang} />
      </div>

      <Modal
        visible={isNearestPostalCodeModal}
        onClickAway={() => setIsNearestPostalCodeModal(false)}
        width="400px"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            padding: 20,
            alignItems: "center",
          }}
        >
          <img
            alt="NoGTP"
            src={NoGTP}
            style={{
              objectFit: "contain",

              maxWidth: 175,
            }}
          />
          <h6
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: 0,
            }}
          >
            {t("NoResultText")}
          </h6>
          <h6
            style={{
              margin: 0,
              textAlign: "center",
            }}
          >
            {t("WeWillShowYouTheNearestLocationsFromYourSearch")}
          </h6>

          <Button
            onClick={() => setIsNearestPostalCodeModal(false)}
            containerStyle={{
              width: "100%",
            }}
            text={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  Okay
                </p>
              </div>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default DesktopGTP;
