import React , { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import {StyledModalOtp} from "./StyledModalOtp";
import ButtonBlue from '../button'
import Button from '../button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Input from '../../components/input'

function PopUpBoxOtp(props) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <StyledModalOtp>
        <Modal dialogClassName='modalClass'  onHide={props.close}
         style={{borderRadius:'40px'}} show={props.show}   backdrop="static">
        <Modal.Header style={{borderBottom:'none',marginLeft:'auto',marginRight:'auto'}} closeButton>
        <Modal.Title style={{margin:'auto'}}></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{marginLeft:'auto',marginRight:'auto',padding:'50px'}}>
        <h2 style={{fontWeight:'700',fontSize:'28px',textAlign:'center',paddingBottom:'10px'}}>Input 6 digit code</h2>
         <h6 style={{color:'#758BA9',textAlign:'center'}}>A 6 digit code has been sent via SMS 
            to your mobile number tied to {props.prefix} **** {props.lastDigit}. 
            Please verify your mobile number to continue.
          </h6>
          
            <Row style={{marginTop:'40px',padding:'0', width:'auto'}}>
              <Col xs={2} >{props.first}
                
              </Col>
              <Col xs={2}>{props.second}
                
              </Col>
              <Col xs={2}>{props.third}
               </Col>
              <Col xs={2}>{props.fourth}
               
              </Col>
              <Col xs={2}>{props.fifth}
               
              </Col>
              <Col xs={2}>{props.sixth}
               
              </Col>
            </Row>
          <div style={{width:'100%',paddingBottom:'20px',paddingTop:'50px',display:'flex', justifyContent:'center'}}>
              <Button text="Submit"  buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}  onClick={props.handleClose}/>
          </div>
          </Modal.Body>
        </Modal>
        </StyledModalOtp>
      </>
    );
  }
  export default PopUpBoxOtp