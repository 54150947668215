import React , { useState } from 'react';

import Modal from 'react-bootstrap/Modal'

import {StyledModal,StyledButton} from "./StyledModal";

import Button from '../button'


function PopUpBox(props) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <StyledModal>
        <Modal style={{borderRadius:'40px'}} show={props.show} onHide={props.handleClose}>
          <Modal.Header  style={{borderBottom:'none'}}>
            <Modal.Title style={{margin:'auto', fontFamily: `"TruenoBold", Arial, sans-serif`, }}><h2 style={{ fontSize: '24px' }}>{props.title}</h2></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{textAlign:'center', fontFamily: `"TruenoRegular", Arial, sans-serif`, }}><h4 style={{ fontSize: '20px' }}>{props.body}</h4></Modal.Body>
          
          <div style={{width:'200px',paddingBottom:'20px',paddingTop:'10px',display:'flex',justifyContent:'center', alignItems: 'center', margin: '0 auto'}}>
            <Button containerStyle={StyledButton} textStyle={StyledButton}   buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}  style={{height:'20px', fontFamily: `"TruenoRegular", Arial, sans-serif`}} text="OK"
                    onClick={props.handleClose}
              />
              </div>
        </Modal>
        </StyledModal>
      </>
    );
  }
  export default PopUpBox