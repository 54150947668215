import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton,InputStyle} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'

import Button from '../../components/button'
import Search from '../../assets/images/searchIcon.png'

import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'
import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import PictureCard from '../../assets/images/iconProfile.jpeg'
import back from '../../assets/images/icon-back.png'
import qr from '../../assets/images/qr.png'

import Swal from 'sweetalert2'
import PopUpBox from '../../components/modal'

import Select from 'react-select'


import AppConfig from '../../config'

import PopUpBoxHandler from '../../components/modalhandler';
const accountUrl = AppConfig.ACCOUNT_URL


import moment from 'moment'
import { Images, Colors } from '../../themes';
import axios from 'axios';
import { Route } from 'react-router-dom';
const InputDateStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`
const InputReadOnlyStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin: 10px 0 25px 0; 
.input-field { cursor: pointer; }
`;

const InputTypeStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin-top: 10px; 
.input-field { cursor: pointer; }
`;
const relationshipStatusData =
[
  {
    id: 0,
    name: `Nephew / Niece`,
    value: `Nephew / Niece`,
    label:  `Nephew / Niece`
  },
  {
    id: 1,
    name:  'Child',
    value: `Child`,
    label:  `Child`
  },
  {
    id: 2,
    name:  'Grandchild',
    value: `Grandchild`,
      label:  `Grandchild`
  },
  {
    id: 3,
    name:  'Spouse',
    value: `Spouse`,
    label:  `Spouse`
  },
  {
    id: 4,
    name:  'Parent',
    value: `Parent`,
    label:  `Parent`
  },
  {
    id: 5,
    name:  'Grandparent',
    value: `Grandparent`,
    label: `Grandparent`
  },
  {
    id: 6,
    name:  `Uncle / Aunt`,
    value: `Uncle / Aunt`,
    label: `Uncle / Aunt`
  },
  {
    id: 7,
    name: 'Others, please specify',
    isCustom: false,
    value: `Others, please specify`,
    label: `Others, please specify`
  }
]

export class tagNewCG extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            uidTagged: '',
            token:this.props.location.state.token,
            uidTags:this.props.location.state.uid,
            searchCG:'',
            search:'',
            nameCG:'',
            cityCG:'',
            membershipCG:'',phone:'',email:'',lastname:'',
            searchBy:'',
            relationship:'',
            relationshipStatus:'',
            data:[],
            showError:false,
            errorMessage:'',
            Switch: SwitchNo,
            SwitchName:TypeInactive,
            SwitchNumber:TypeInactive,
            masterPartnerUid: this.props.location.state.masterPartnerUid,
            partnerToken: this.props.location.state.partnerToken,
        }
      }
      handleShow = (i)=> {
        if(this.state.relationship == ''){
          Swal.fire({
            title: 'Warning!',
            text: `Please select This Person Living with Dementia is my `,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }
        
      //   else if (this.state.partnerToken || this.state.masterPartnerUid) {
      //     console.log(i)
          
      //     this.setState({uidTagged: i})
      //     axios.post(`${accountUrl}/public/accounts/tagMyOtherAccount`, {
      //       uidTagged: i,
      //       uidTags: parseInt(this.state.uidTags),
      //       status: "ACCEPTED",
      //       relationship: this.state.relationship
      //       },{headers: {
      //         Authorization: `${this.state.partnerToken}`
      //       }},)
      //       .then(res => {
      //         console.log(res)
      //         if(res.status==200 && res.data.type=="SUCCESS"){
      //           sessionStorage.clear();
                
      
      //  this.setState({show: true})
      //         }else{
               
      //       Swal.fire({
      //         title: 'Warning!',
      //         text: `${res.data.data.message}`,
      //         icon: 'info',
      //         confirmButtonText: 'OK'
      //       })
      //         };
      //       }, (error) => {
      //         console.log(error);
      //       });
      //   }

        else {
        console.log(i)
          
        this.setState({uidTagged: i})
        axios.post(`${accountUrl}/public/accounts/tagMyOtherAccount`, {
          uidTagged: i,
          uidTags: parseInt(this.state.uidTags),
          status: "PENDING",
          relationship: this.state.relationship,
          },{headers: {
            Authorization: `${this.state.token}`
          }},)
          .then(res => {
            console.log(res)
            if(res.status==200 && res.data.type=="SUCCESS"){
              sessionStorage.clear();
              
    
     this.setState({show: true})
            }else{
             
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
            };
          }, (error) => {
            console.log(error);
          });
        }
    }
    handleClose = (e)=> {
     this.setState({show: false})
     if (this.state.partnerToken) {
      window.open(`${AppConfig.ADMIN_HOSTNAME}/users`, "_self")
      // this.props.history.push('/')
     } else {
       this.props.history.push({pathname:'/registrationNotification'})
     }
    }

    handleChangeRelationship =(e)=> {
      this.setState({relationship:e.value})
      console.log('relationship', this.state.relationship )
      this.setState({relationshipStatus:e.id})
    }; 
  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state })
  }
  
  changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes})
   }else{ 
       this.setState({Switch: SwitchNo})
   }
  }
  changeNumber=()=>{
    if(this.state.SwitchNumber == TypeInactive ){
        this.setState({SwitchNumber: TypeActive,searchBy:'phone', SwitchName:TypeInactive})
        
     }else{ 
         this.setState({SwitchGender: TypeInactive,SwitchGenderM: TypeActive,})
     }
  }
  changeName=()=>{
    if(this.state.SwitchName == TypeInactive ){
        this.setState({SwitchName: TypeActive,searchBy:'name', SwitchNumber:TypeInactive})
        
     }else{ 
         this.setState({SwitchGenderM: TypeInactive,SwitchGenderF: TypeActive,})
     }
  }
  getCG = () =>{
    if (this.state.partnerToken) {
      this.partnerSearch()
    } else {
      if(this.state.searchBy=='phone'){
        axios.get(`${accountUrl}/public/accounts/users?phone=${this.state.searchCG}&type=CAREGIVER`)
          .then(res => {
            console.log(res.data.data)
            console.log(this.state.phone)
            let S = this.state.searchCG
            this.setState({search:S})
            if(res.status==200 && res.data.type=="SUCCESS"){
              
                console.log(this.state.token)
                this.setState({data:res.data.data})
                               
            }else{
              Swal.fire({
                title: 'Warning!',
                text: `${res.data.data.message}`,
                icon: 'info',
                confirmButtonText: 'OK'
              })
            };
          }, (error) => {
            console.log(error);
          });}else{
            axios.get(`${accountUrl}/public/accounts/usersFiltered?name=${this.state.searchCG}&type=CAREGIVER`, 
          )
          .then(res => {
            console.log(res.data.data)
            console.log(this.state.phone)
            let S = this.state.searchCG
            this.setState({search:S})
            if(res.status==200 && res.data.type=="SUCCESS"){
              
                console.log(this.state.token)
                this.setState({data:res.data.data})
                               
            }else{
              Swal.fire({
                title: 'Warning!',
                text: `${res.data.data.message}`,
                icon: 'info',
                confirmButtonText: 'OK'
              })
            };
          }, (error) => {
            console.log(error);
          });
          }
    }
  }

  partnerSearch = () => {
    if(this.state.searchBy=='phone'){
      axios.get(`${accountUrl}/partner/users?phone=${this.state.searchCG}&type=CAREGIVER`, {
        headers: {
          Authorization: this.state.partnerToken,
        }
      })
      .then(res => {
        console.log('res => ', res)
        let S = this.state.searchCG
        this.setState({search:S})
        if (res.status == 200 && res.data.type=="SUCCESS") {
            console.log(this.state.token)
            this.setState({data:res.data.data})                  
        } else {
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        };
      }, (error) => {
        console.log(error);
      });
    }

    else {
      axios.get(`${accountUrl}/partner/users?phone=${this.state.searchCG}&type=CAREGIVER`, {
        headers: {
          Authorization: this.state.partnerToken,
        }
      })
      .then(res => {
        console.log(res.data.data)
        console.log(this.state.phone)
        let S = this.state.searchCG
        this.setState({search:S})
        if( res.status == 200 && res.data.type == "SUCCESS") {
          console.log(this.state.token)
          this.setState({data:res.data.data})    
        } else {
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        };
      }, (error) => {
        console.log(error);
      });
    }
  }
  
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
  
  handleCloseError =()=>{
    this.setState({showError:false})
  }
  render() {
    const {SwitchName,data, SwitchNumber,searchCG,lastname ,cityCG,email, nameCG,membershipCG,phone, gender,SwitchGenderF,SwitchGenderM, partnerToken, masterPartnerUid } = this.state
    
    return (
      <>
        <StyledCG>
            <Header/>
            <div onClick={() => this.props.history.goBack()} style={{ marginBottom: '20px', width: '70px', height: '30px', background: '#502773', borderRadius: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
              <div><img src={back} alt="back" style={{ width: '9px', objectFit: 'contain' }} /></div>
              <div style={{ fontWeight: 800, fontSize: '13px', color: 'white', marginLeft: '5px' }}>Back</div>
            </div>
            <h2>Tag new Caregiver / Next-of-Kin</h2>
            <Container>
               
                <Row >
                    <Col md={6} style={{margin:'auto'}}>
                        <label>Search By </label>
                    </Col>
                    <Col md={3} style={{padding:'0', paddingLeft: '30px'}}>
                    <button style={{marginRight:'20px'}} className="type" onClick={this.changeName}><Row><img src={SwitchName} style={{marginRight:'15px'}}  /> Name</Row></button>
                    </Col>
                    <Col md={3} >
                    <button className="type"  onClick={this.changeNumber}><Row><img src={SwitchNumber} style={{marginRight:'15px'}}/> Number</Row></button>
                    </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col md={6} style={{margin:'auto'}}>
                        <label>The Caregiver / Next-of-Kin is {!partnerToken || !masterPartnerUid ? 'my' : 'PLWD'} </label>
                    </Col>
                    <Col md={6} style={{padding:'0', paddingLeft: '30px'}}>
                    <Select  onChange={(e) =>  this.handleChangeRelationship(e)} defaultValue={relationshipStatusData[window.sessionStorage.getItem("RelationshipStatus")] } options={relationshipStatusData} />
                    </Col>
                </Row>
                <Row style={{margin:'0',padding:'0'}}>
                    <Col>
                        
                    </Col>
                    <Col md={6}>  
                    <Row> 
                    <Col md={10}>  
                        <Input inputProps={{placeholder:'Search Caregiver',name:"searchCG",
                                                                    value:searchCG,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('searchCG', e.target.value),}}  />
                    </Col>
                    <Col md={2} style={{display:'flex',justifyContent:'center'}}>
                    <button style={{border:'none',height:'auto'}} onClick={this.getCG}><img src={Search} style={{height:'auto'}}/></button>
                    </Col>
                    </Row>
                    <Row style={{display:'flex',justifyContent:'flex-end', marginTop:'20px'}}>
                    <Button buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', paddingBottom: '5px', marginLeft: '15px' }} containerStyle={StyledButton} textStyle={StyledButton} text="Search"  onClick={this.getCG}/>
                    </Row>
                    </Col>             
                </Row>
                <hr/>
              
            
            <Row>
                <Col md={6}>
                    <p>Showing {data.length} results for "{this.state.search}" </p> 
                </Col> 
                <Col md={6} >
                <Row>{
              data.map((item,i) => {
                return (
                  
                  <Col key={i} className="e-card " style={{padding: '0px', marginRight: '10px'}}>
                        <Row style={{height:'70px'}} className="headerCard">
                        <img src={item.photo ? item.photo : PictureCard}  style={{left:'12px',top:'35px',position:'absolute',height:'70px',width:'70px', borderRadius: '50%' }}/>
                          <Col > 
                          
                            <Row >
                              <Col style={{top:'25px',left:'60px'}} >  <p style={{marginTop:'15px', fontSize: '16px', color: 'white'}}>{item.firstname} {item.lastname}</p></Col>              
                            </Row>
                          </Col>
                          </Row>
                          <Row style={{height: '50px'}}>
                            <Col style={{left:'19%'}}> </Col>
                            <Col style={{left:'5%'}}> <h4 style={{marginTop:'7%' }}>{item.email}</h4></Col>
                            <Col style={{display:'flex',justifyContent:'flex-start'}}>  <h4 style={{marginTop:'7%' }}>{item.membershipId}</h4></Col>
                          </Row>
                        <hr/>
                        <Row >
                            <Col md={7} >
                            </Col>
                            <Col md={5} style={{display:'flex',justifyContent:'flex-end'}} >
                              <Button  text="Add" buttonStyle={{height:'40px',width:'150px', display:'flex',justifyContent:'center', alignItems: 'flex-end', marginBottom: '10px'}} textStyle={{fontSize: '14px', marginBottom: '12px'}} onClick={() => this.handleShow(item.uid)}/>
                              <PopUpBox body="The user has been successfully added! Please note that this has to be approved in the caregiver's account" show={this.state.show}  handleClose={this.handleClose}/>
                              <PopUpBoxHandler show={this.state.showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
                            </Col>
                        </Row>
                       
                    </Col>
                )
              })
            }
                    
                    </Row>
                </Col>
                </Row>
               
            </Container>
            </StyledCG>
        
       
      </>
    )
  }
}


export default tagNewCG