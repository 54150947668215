// Deps
import Modal from "react-awesome-modal";
import { useEffect, useRef, useState } from "react";

// Components
import Map from "../components/Map";
import GTPCard from "../components/GTPCard";
import Button from "../../../components/button2";

// Assets
import Faq from "../../../assets/images/faq.png";
import GPS from "../../../assets/images/gps.png";
import NoGTP from "../../../assets/images/no-gtp.png";
import CaraLogo from "../../../assets/images/cara-logo.png";

// Helpers
import { fetchGTPs, fitToMarkers } from "../helpers";

// Constants
import { Colors } from "../../../themes";

// Styles
import styles from "../components/styles";
import FilterGTP from "../components/FilterGTP";
import ModalInformation from "../components/ModalInformation";
import { t } from "i18next";

const MobileGTP = () => {
  // Refs
  const reactMapRef = useRef(null),
    ref = useRef(null);

  // States
  const [search, setSearch] = useState(""),
    [postalCode, setPostalCode] = useState(""),
    [region, setRegion] = useState(""),
    [isMapLoaded, setMapLoaded] = useState(false),
    [currentCoordinate, setCurrentCoordinate] = useState({
      status: false,
      latitude: null,
      longitude: null,
    }),
    [gtps, setGtps] = useState([]),
    [activeMarker, setActiveMarker] = useState(null),
    [showInfo, setShowInfo] = useState(false),
    [isLoading, setLoading] = useState(true),
    [suggestedPostalCodes, setSuggestedPostalCodes] = useState([]),
    [isNearestPostalCodeModal, setIsNearestPostalCodeModal] = useState(false),
    [isNearestPostalCode, setIsNearestPostalCode] = useState(false);

  // Constants
  const shorthandFetchGTPsOption = {
    gtps,
    reactMapRef,
    isMobile: true,
    currentCoordinate,
    setIsNearestPostalCodeModal,
    setIsNearestPostalCode,
    setLoading,
    setGtps,
  };

  // Functions
  const settingCurrentPosition = (latitude, longitude) =>
      setCurrentCoordinate({
        status: true,
        latitude,
        longitude,
      }),
    handleFilter = (search, postalCode, region) => {
      fetchGTPs(
        false,
        false,
        search,
        postalCode,
        region,
        shorthandFetchGTPsOption
      );
    };

  // Effects
  useEffect(() => {
    if (currentCoordinate.status)
      fetchGTPs(
        currentCoordinate.latitude,
        currentCoordinate.longitude,
        null,
        null,
        null,
        shorthandFetchGTPsOption
      );
  }, [currentCoordinate]);

  useEffect(() => {
    if (isMapLoaded)
      navigator.geolocation.getCurrentPosition(
        (pos) =>
          settingCurrentPosition(pos.coords.latitude, pos.coords.longitude),
        (e) => {
          settingCurrentPosition(1.2901003, 103.8508516);
          console.error(e);
        }
      );
  }, [isMapLoaded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target))
        setSuggestedPostalCodes([]);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={styles.mobileContainer}>
      <div>
        <nav
          style={{
            height: "65px",
            display: "flex",
            padding: "20px 25px",
            flexDirection: "row",
            justifyContent: "space-between",
            boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img
            src={CaraLogo}
            style={{
              cursor: "pointer",
              objectFit: "contain",
              objectPosition: "left",
            }}
          />

          <img
            onClick={() => setShowInfo(true)}
            style={{
              cursor: "pointer",
              objectFit: "contain",
              objectPosition: "right",
            }}
            src={Faq}
          />
        </nav>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <Map
          gtps={gtps}
          reactMapRef={reactMapRef}
          activeMarker={activeMarker}
          currentCoordinate={currentCoordinate}
          setActiveMarker={setActiveMarker}
          setMapLoaded={setMapLoaded}
        />
      </div>
      <button
        style={styles.fitToMarker}
        onClick={() => fitToMarkers(gtps, shorthandFetchGTPsOption)}
      >
        <img src={GPS} style={styles.fitToMarkerImage} />
      </button>

      <div style={styles.bottomSheet}>
        <div className="search-container">
          <div
            style={{
              position: "relative",
            }}
          >
            <FilterGTP
              setSuggestedPostalCodes={(postalCodes) =>
                setSuggestedPostalCodes(postalCodes)
              }
              search={search}
              setSearchValue={setSearch}
              onSearch={(search, postalCode, region) => {
                setSearch(search);
                setPostalCode(postalCode);
                setRegion(region);
                handleFilter(search, postalCode, region);
              }}
            />
            <div
              ref={ref}
              style={{
                bottom: -50,
                width: "100%",
                borderRadius: 10,
                overflow: "hidden",
                background: "white",
                position: "absolute",
                flexDirection: "column",
                boxShadow: "2px 2px 2px 1px rgb(0 0 0 / 20%)",
                display: suggestedPostalCodes.length ? "flex" : "none",
              }}
            >
              {suggestedPostalCodes.map((el) => {
                const searchTerm = search;
                const regex = new RegExp(`(${searchTerm})`, "gi");
                const parts = el.split(regex);

                return (
                  <div
                    style={{
                      margin: 0,
                      width: "100%",
                      display: "flex",
                      padding: "10px",
                      cursor: "pointer",
                      userSelect: "none",
                      flexDirection: "row",
                    }}
                    onClick={() => {
                      setSearch(el);
                      setSuggestedPostalCodes([]);
                      handleFilter(el, postalCode, region);
                    }}
                  >
                    {parts.map((part, index) =>
                      part.toLowerCase() === searchTerm.toLowerCase() ? (
                        <p
                          key={index}
                          style={{ fontWeight: "bold", margin: 0 }}
                        >
                          {part}
                        </p>
                      ) : (
                        part
                      )
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <button onClick={() => handleFilter(search, postalCode, region)}>
            <a href="#download">List Location</a>
          </button>
        </div>

        <h1
          style={{
            fontSize: 16,
            fontWeight: "bold",
            color: Colors.color11,
          }}
        >
          {isNearestPostalCode
            ? `${t("ShowingTheClosestResultsOf")} ‘${search}’`
            : t("NearestGTPText")}
        </h1>
        <div style={styles.scrollableContainer}>
          {gtps.length ? (
            gtps.map(({ id, name, address }, i, arr) => {
              const active = id === activeMarker;
              return (
                <>
                  <GTPCard
                    id={id}
                    name={name}
                    active={active}
                    key={`gtp-${i}`}
                    address={address}
                    setActiveMarker={setActiveMarker}
                  />

                  {i === arr.length - 1 && (
                    <div
                      style={{
                        paddingBottom: "37px",
                      }}
                    />
                  )}
                </>
              );
            })
          ) : !isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={NoGTP}
                style={{
                  height: 150,
                  objectFit: "contain",
                }}
              />

              <div
                style={{
                  gap: 4,
                  display: "flex",
                  padding: "20px 0",
                  flexDirection: "column",
                }}
              >
                <h3
                  style={{
                    margin: 0,
                    fontSize: 15,
                    fontWeight: 800,
                    color: Colors.color2,
                    textAlign: "center",
                  }}
                >
                  {t("NoResultText")}
                </h3>
                <p
                  style={{
                    margin: 0,
                    fontWeight: 500,
                    color: Colors.color2,
                    textAlign: "center",
                  }}
                >
                  {t("TryAnotherLocationText")}
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ModalInformation
        isVisible={showInfo}
        onSetVisible={(state) => setShowInfo(state)}
      />

      <Modal
        visible={isNearestPostalCodeModal}
        onClickAway={() => setIsNearestPostalCodeModal(false)}
        width="90%"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            padding: 20,
            alignItems: "center",
          }}
        >
          <img
            alt="NoGTP"
            src={NoGTP}
            style={{
              objectFit: "contain",

              maxWidth: 175,
            }}
          />
          <h6
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: 0,
            }}
          >
            {t("NoResultText")}
          </h6>
          <h6
            style={{
              margin: 0,
              textAlign: "center",
            }}
          >
            {t("WeWillShowYouTheNearestLocationsFromYourSearch")}
          </h6>

          <Button
            onClick={() => setIsNearestPostalCodeModal(false)}
            containerStyle={{
              width: "100%",
            }}
            text={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  Okay
                </p>
              </div>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default MobileGTP;
