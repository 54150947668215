import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton,InputStyle} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Select from 'react-select'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'

import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'

import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'
import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import Polygon from '../../assets/images/Polygon.png'

import { Link } from 'react-router-dom';
import moment from 'moment'
import styled from "styled-components";

import { Images, Colors, Fonts } from '../../themes';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Swal from 'sweetalert2'
import PopUpBoxHandler from '../../components/modalhandler';
const InputDateStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`
const CustomInput = React.forwardRef((props, ref) => {
  return (
    <CustomDatePickDiv>
    <Row>
   
      <label onClick={props.onClick} ref={ref}>
      <Col >
        {props.value || props.placeholder}
        </Col> 
      </label>
     
      <Col style={{display:'flex',alignSelf:'center',justifyContent:'flex-end',marginRight:'10px'}}>
      <img src={Polygon} onClick={props.onClick} />
</Col>
      </Row>
    </CustomDatePickDiv>
  );
});

const CustomDatePickDiv = styled.div`
  background-color: white;
 
  border-radius: 10px;
  label{padding-left:5px;
    font-family: ${Fonts.FontRegular};
    font-weight: bold;
    color: ${Colors.color2};
    resize: none;
    ${Fonts.size.size20}}
  }
`;
const InputReadOnlyStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin: 10px 0 25px 0; 
.input-field { cursor: pointer; }
`;

const InputTypeStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin-top: 10px; 
.input-field { cursor: pointer; }
`;

const relationshipStatusData =
[
  {
    id: 0,
    name: `Nephew / Niece`,
    value: `Nephew / Niece`,
    label:  `Nephew / Niece`
  },
  {
    id: 1,
    name:  'Child',
    value: `Child`,
    label:  `Child`
  },
  {
    id: 2,
    name:  'Grandchild',
    value: `Grandchild`,
      label:  `Grandchild`
  },
  {
    id: 3,
    name:  'Spouse',
    value: `Spouse`,
    label:  `Spouse`
  },
  {
    id: 4,
    name:  'Parent',
    value: `Parent`,
    label:  `Parent`
  },
  {
    id: 5,
    name:  'Grandparent',
    value: `Grandparent`,
    label: `Grandparent`
  },
  {
    id: 6,
    name:  `Uncle / Aunt`,
    value: `Uncle / Aunt`,
    label: `Uncle / Aunt`
  },
  {
    id: 7,
    name: 'Others, please specify',
    isCustom: false,
    value: `Others, please specify`,
    label: `Others, please specify`
  }
]

export class createNewCGDetail extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          phone:this.props.location.state.phone,
          username:this.props.location.state.username,
          password:this.props.location.state.password,
          
          uid:this.props.location.state.uid,
          streetName:this.props.location.state.streetName,
          unitNumber:this.props.location.state.unitNumber,
          postalCode:this.props.location.state.postalCode,
          cityCountry:this.props.location.state.cityCountry,
          building:this.props.location.state.building,
          blockNumber:this.props.location.state.blockNumber,
          findCaraList: this.props.location.state.findCaraList,
          adminUid: this.props.location.state.adminUid,
          firstname: '',
          lastname:'',
          displayName:'',
          gender:'',
          birth:'',
          lastNric:'',
          relationship:'',
          relationshipStatus:'',
          date: '',
          dateNow:'',
          Switch: SwitchNo,
          showError:false,
          errorMessage:'',
        SwitchGenderM:TypeInactive,
        SwitchGenderF:TypeInactive,        
        show: false,
        delivery: '',
        dateSend: moment().format('YYYY-MM-DD')
        }
      }
   handleShow = (e)=> {
    
     this.setState({show: true})
 } 
  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state })
  }
  
 componentDidMount(){
  if(this.props.location.state.firstname){
    this.setState({firstname: this.props.location.state.firstname})
  }
  
  if(this.props.location.state.lastname){
    this.setState({lastname: this.props.location.state.lastname})
  }
  if(this.props.location.state.displayName){
    this.setState({displayName: this.props.location.state.displayName})
  }
  if(window.sessionStorage.getItem("gender") == 'male'){
    this.setState({SwitchGenderM: TypeActive,gender:window.sessionStorage.getItem("gender")})
   }
    if(window.sessionStorage.getItem("gender") == 'female'){
    this.setState({SwitchGenderF: TypeActive,gender:window.sessionStorage.getItem("gender")})
   }if(  this.props.location.state.lastNric){
    this.setState({lastNric:  this.props.location.state.lastNric})
  }
   
 
  if(window.sessionStorage.getItem("dateNow")!= ''){
    this.setState({dateNow:window.sessionStorage.getItem("dateNow"),birth:window.sessionStorage.getItem("dateNow")})
  }
  
 }
  changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes})
   }else{ 
       this.setState({Switch: SwitchNo})
   }
  }
  changeGenderM=()=>{
    if(this.state.SwitchGenderM == TypeInactive ){
        this.setState({SwitchGenderM: TypeActive,gender:'male', SwitchGenderF:TypeInactive})
        
     }else{ 
         this.setState({SwitchGender: TypeInactive,SwitchGenderM: TypeActive,})
     }
  }
  changeGenderF=()=>{
    if(this.state.SwitchGenderF == TypeInactive ){
        this.setState({SwitchGenderF: TypeActive,gender:'female', SwitchGenderM:TypeInactive})
        
     }else{ 
         this.setState({SwitchGenderM: TypeInactive,SwitchGenderF: TypeActive,})
     }
  }
  
  renderInputDropdown(props) {
    const { value, onClick, filtered } = props;
    return (
      <Input
        inputProps={{
          readOnly: true,
          onClick: filtered ? onClick : null,
          value,
        }}
        ic
        containerStyle={`
          ${InputReadOnlyStyle}
          .input-field {
            cursor: ${filtered ? 'pointer' : 'not-allowed'}
          }
          input {
            color: ${filtered ? Colors.color2 : Colors.color34} !important;
          }
          `}
        iconRight={Polygon}
        {...props}
        dropdownWidth={'100%'}
      />
    );
  }
  
 submitForm = ()=>{
   
  if((this.state.firstname == '' && window.sessionStorage.getItem("firstname") == '') || (this.state.lastname =='' && window.sessionStorage.getItem("lastname") == '') || (this.state.displayName =='' && window.sessionStorage.getItem("displayName") == '') || (this.state.gender =='' && window.sessionStorage.getItem("gender") == '')  || 
  (this.state.birth =='' || window.sessionStorage.getItem("birth") == '')|| (this.state.lastNric =='' || window.sessionStorage.getItem("lastNric") == '') || this.state.birth === null ){
 
    Swal.fire({
      title: 'Warning!',
      text: 'please fill the required data and choose date',
      icon: 'info',
      confirmButtonText: 'OK'
    })
  
  }else{
    var letters = /^[A-Za-z]+$/;
        let result = {}
      
        if (this.state.lastNric.length < 4) {
         
          Swal.fire({
            title: 'Warning!',
            text: 'Last NRIC must be 4 character.',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }
      
        else if ( 
          ( (!isNaN(this.state.lastNric[0]) || this.state.lastNric[0] >= 1 || this.state.lastNric[0] <= 10) ) && 
          ( (!isNaN(this.state.lastNric[1]) || this.state.lastNric[1] >= 1 || this.state.lastNric[1] <= 10) ) && 
          ( (!isNaN(this.state.lastNric[2]) || this.state.lastNric[2] >= 1 || this.state.lastNric[2] <= 10) ) && 
          this.state.lastNric[3].match(letters)
        ) {
    window.sessionStorage.setItem("firstname", this.state.firstname);
    window.sessionStorage.setItem("lastname", this.state.lastname);
    window.sessionStorage.setItem("displayName", this.state.displayName);
    window.sessionStorage.setItem("gender", this.state.gender);
    window.sessionStorage.setItem("dateNow",this.state.dateNow)
    
    window.sessionStorage.setItem("lastNric",this.state.lastNric);
  this.props.history.push({pathname:'/registrationCreateNewCGAddress',
  state:{
    username: this.state.username,
    password: this.state.password,
    phone: this.state.phone,
    
    uid:this.state.uid,
    streetName:this.state.streetName,
    unitNumber:this.state.unitNumber,
    postalCode:this.state.postalCode,
    cityCountry:this.state.cityCountry,
    firstname: this.state.firstname,
    lastname:this.state.lastname,
    displayName:this.state.displayName,
    building:this.state.building,
    blockNumber:this.state.blockNumber,
    gender:this.state.gender,
    birth:this.state.birth,
    lastNric:this.state.lastNric,
    relationship:this.state.relationship,
    relationshipStatus:this.state.relationshipStatus,
    findCaraList: this.state.findCaraList,
    adminUid: this.state.adminUid
  }})
  return this.state}
 else {
  
    Swal.fire({
      title: 'Warning!',
      text: 'Incorrect NRIC format, please input last 3 numbers of NRIC and final alphabet. For e.g If NRIC is S1234123A enter 123A.',
      icon: 'info',
      confirmButtonText: 'OK'
    })
  }
}
}
   
back=()=>{
  this.props.history.goBack()
}
handleCloseError =()=>{
  this.setState({showError:false})
} 
onChangeText = (stateName, value) => {
  this.setState({ [stateName]: value });
};

handleChangeRelationship =(e)=> {
  this.setState({relationship:e.value})
  console.log('relationship', this.state.relationship )
  this.setState({relationshipStatus:e.id})
}; 
  render() {
    const {firstname,lastname, displayName, gender,lastNric, birth,SwitchGenderF,SwitchGenderM,delivery } =this.state
    
    
    return (
      <>
        <StyledCG>
            <Header/>
            <h2 style={{marginTop:'5%'}}>Your Caregiver / Next-of-Kin Details</h2>
            <p style={{marginBottom:'2%'}} className="signUp">Please note that all fields in the form are mandatory</p>
            <Row style={{marginTop:'20px'}}>
                <Col md={6} style={{margin:'auto'}}>
                    <label>The Caregiver / Next-of-Kin is my </label>
                </Col>
                <Col md={6} >
                <Select  onChange={(e) =>  this.handleChangeRelationship(e)} defaultValue={relationshipStatusData[window.sessionStorage.getItem("relationshipStatus")] } options={relationshipStatusData} />
                </Col>
                
            </Row>
            <hr/>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>First Name</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type your first name here...',name:"firstname",
                                                                    value:firstname,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('firstname', e.target.value),}} />
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Last Name</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your last name here...',name:"lastname",
                                                                    value:lastname,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('lastname', e.target.value),}} />
                   
                    </Col>
                </Row>
                <Row>
                  <Col  style={{margin:'auto'}}>
                    <label>Display Name</label>
                  </Col>
                  <Col md={6}>
                    <Input 
                      containerStyle="InputStyle"  
                      inputProps={{
                        placeholder:'Type your display name here... ',
                        name:"displayName", 
                        value:displayName,
                        onChange: (e) => this.onChangeText('displayName', e.target.value),}}/>
                   
                  </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Gender </label>
                    </Col>
                    <Col md={6}>
                    <Row>
                    
                    <Col md={6} >
                    <button style={{marginRight:'10%'}} className="type" onClick={this.changeGenderM}><Row style={{alignItems:'center'}}><img src={SwitchGenderM} style={{marginRight:'10%'}} /> Male</Row></button>
                    </Col>
                    <Col md={6} >
                    <button className="type"  onClick={this.changeGenderF}><Row style={{alignItems:'center'}}><img src={SwitchGenderF} style={{marginRight:'10%'}}/> Female</Row></button>
                    </Col>
                    </Row>
                    </Col>
                </Row>
                
                <Row style={{marginTop:'2%'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Date of Birth</label>
                    </Col>
                    <Col md={6}>
                    <Row style={{margin:'auto', border: '1px solid #B1C2D8',boxSizing: 'border-box',borderRadius: '15px'}}>
                    <DatePicker  showMonthDropdown showYearDropdown  customInput={<CustomInput />}  filterDate = {(date) => {return moment() > date;}} dropdownMode= 'select' dateFormat="dd-MM-yyyy" selected={this.state.date} selected={this.state.dateNow ? new Date(this.state.dateNow) : null} onChange={date => { this.setState({dateNow:date.toISOString().slice(0,10),birth:date.toISOString().slice(0,10),})
                                                                                                                      this.setState({date:date})
                                                                                                                      console.log(date.toISOString().slice(0,10))}} placeholderText="Please select a date" />
 
                         
                  </Row>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Last 4 characters of NRIC</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your last nric here...',name:"lastNric",
                                                                    value:lastNric,maxLength:4,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('lastNric', e.target.value),}} />
                   
                    </Col>
                </Row>
                <Row style={{marginTop:'5%',marginBottom:'4%'}}>
                <Col md={6}></Col>
                 <Col md={3}>
                <Button  onClick={this.back} buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} backgroundColor={Colors.color3} textColor={Colors.color11} bordered={Colors.color11} text="Back" />
                </Col>
                <Col md={3}>
                
                <Button buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} onClick={this.submitForm} containerStyle={StyledButton} textStyle={StyledButton} text="Next" />
               
                </Col>
                </Row>
                
            </StyledCG>
        
       
      </>
    )
  }
}


export default createNewCGDetail