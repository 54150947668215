import React, { Component } from 'react'
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { sendSafeReturnSms } from '../api/UserAPI';
import Swal from 'sweetalert2';
import ProccessData from '../lib/ProccessData';
import codes from 'country-calling-code';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { withTranslation } from 'react-i18next';

import { StyledUserQRDetail, Text, Label, InputStyle, TextSmall, InputStyleSafeReturn } from './styles/StyledUserQRDetail'

import Input from '../components/input'
import Button from '../components/button'

import { Images, Colors, Fonts, } from '../themes/'
import axios from 'axios'
import { getUserById, createSafeReturnCard, updateDementiaInfo, getTaggedAccount } from '../api/UserAPI'

import PopUpBox from '../components/modal-safe-return'
import PopUpBoxHandler from '../components/modalhandler'
import CaregiverContact from '../components/caregiver-contact'
import AppConfig from '../config'

import caraLogo from '../assets/images/CARA_logo.png'
import iconInfo from '../assets/images/icon-info.png'
import iconProfile from '../assets/images/iconProfile.jpeg'
import languegeIcon from '../assets/images/language.png'

import { changeLanguage } from '../language/i18n';

const DELAY = 500;

const textSmallStyle = {
  paddingTop:10, 
  fontFamily: `"TruenoRegular", Arial, sans-serif`
}
export class UserQRDetail extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      user: null,
      show: false,
      alert: false,
      name: '',
      phone: '',
      location: '',
      disable: true,
      load: false,
      errorMessage: '',
      submitted: false,
      taggedAccount: [],
      caregivers: [],
      carePartners: [],
      codes: [],
      language: 'en'
    }
  }

  componentDidMount() {
    this.addOrUpdateDementiaInfo({})

    const sortingCodes = []

    for(let item of codes) {
      if(item.country === 'Singapore') {
        sortingCodes.unshift(item)
      } else {
        sortingCodes.push(item)
      }
    }

    this.setState(() => ({
      codes: sortingCodes
    }))
    
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);

    getUserById({ uid: this.props.match.params.uid })
    .then(res => {
      this.setState({ user: res.data })
    })
    .catch(e => {
      alert('Error get user '+ e)
      console.log('error get user by id => ', e)
    })

    getTaggedAccount({ uid: this.props.match.params.uid, status: 'ACCEPTED' })
    .then(res => {
      let caregivers = []
      let carePartners = []
      let taggedAccount = []
      console.log('res get tagged account => ', res)
      res.data.account && res.data.account.map(item => {
        if (item.Account.type === 'CAREGIVER' && !item.Account.masterPartnerUid) caregivers.push(item)
        if (item.Account.type === 'PARTNER') carePartners.push(item)
        if (item.Account.type === 'CAREGIVER' || item.Account.type === 'PARTNER') taggedAccount.push(item)
      })

      
      this.setState({ 
        taggedAccount,
        caregivers,
        carePartners,
      })
    })
    .catch(e => {
      alert('Error get tagged account data '+ e)
      console.log('error get user by id => ', e)
    })
  }

  addOrUpdateDementiaInfo = ({ data = { qrCodeScanned: 1, } }) => {
    updateDementiaInfo({ uid: this.props.match.params.uid, data  })
    .then(res => {
      console.log('res add or update dementia info => ', res)
    })
  }

  handleChange = (event)=>{
    this.setState({value: event.target.value})
  }

  handleClose = () => {
    this.setState({show: false})
    this.setState({name:''})
    
    this.setState({phone:''})
    
    this.setState({location:'', submitted: true,})
  }

  alertError = (text, exit = true) => {
    Swal.fire({
      title: 'Error!',
      text,
      icon: 'error',
      confirmButtonText: 'OK',
      allowOutsideClick: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        this.setState({show: false})
        this.setState({name:''})
        
        this.setState({phone:''})
      },
    })
  }

  handleSendSms = async () => {
    try {
      let resultGet = {}
      resultGet.payload = await sendSafeReturnSms({
        phone: this.state.phone,
      })
      resultGet.payload.type = 'SUCCESS'
      ProccessData(resultGet, 'send sms')
      .then(res => {
        if(res && res.data && res.data && res.data.success) {
          this.handleClose()
        } else {
          this.alertError(res.data.message)
        }
      })
      .catch(err => {
        this.alertError(err)
      })
    } catch (e) {
      this.alertError(e)
    }
  }

  handleCloseAlert = () => {
    this.setState({alert: false})
    this.setState({location:''})  
  } 
  
  handleChange = value => {
    this.setState({  isValid: "true" });
    // if value is null recaptcha expired
    if (value === null) this.setState({ isValid: false, expired: "true" });
  };

  handleShow = (e) => {
    if (this.state.name != '' && this.state.phone != '') {
      createSafeReturnCard({ 
        name: this.state.name,
        phone:this.state.phone.replace(/\s/g,''), 
        location: 'Singapore',
        uidDementia: this.state.user ? this.state.user.uid : undefined,
      })
      .then(res => {
        if (res.type === 'SUCCESS') {
          this.setState({show: true})
        } else {
          this.setState({ alert: true, errorMessage: res.data.message ? res.data.message : `Error submit data` })
        }
      })
      .catch(err => alert(err))
      
    } else {
      this.setState({alert :true, errorMessage: `Required Data Cannot Be Empty, Please Fill All Required Data`})
    }
  } 


  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
  
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  changeLanguageOption = (language) => {
    changeLanguage(language)
    this.setState({language})
  }

  render() {
    const { user, name, phone, location, submitted, language } = this.state
    const { firstname, lastname, photo,show , membershipId } = user || ''
    const isValid = this.state.isValid;
    let disable = name === '' || phone === ''
    const { t } = this.props;

    const activeButtonLanguage = {
      background: Colors.color11,
      color: '#DCE3ED',
      border: '0', 
      fontSize: '15px',
      fontWeight: '600',
      padding: '5px 10px',
      borderRadius: '8px'
    }

    const inActiveButtonLanguage = {
      background: 'white',
      color: '#B1C2D8',
      border: '1px solid #B1C2D8', 
      fontSize: '15px',
      fontWeight: '600',
      padding: '5px 10px',
      borderRadius: '8px'
    }
    
    return (
      <StyledUserQRDetail>
        {
          submitted ?
          <CaregiverContact 
            {...this.props}
            {...this.state}
            onClickCall={() => this.addOrUpdateDementiaInfo({ data: { callByHelper: 1 } })}
          />
          :
          <GoogleReCaptchaProvider
            reCaptchaKey={AppConfig.SITE_KEY}
          >
            <div className="card-container">
              <div className="card-left-section">
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                  <div>
                    <img src={caraLogo} className="cara-logo" style={{width: 100, height: 40, resizeMode: 'contain'}} />
                  </div>
                  <div className="membershipId2 desktop">{user ? membershipId : '-'}</div>
                </div>
                <div className="name-photo-wrapper">
                  <div>
                    <Text color={Colors.color19} style={{
                      fontFamily: `"TruenoRegular", Arial, sans-serif`,
                      marginBottom: '-10px',
                      marginTop: '16px'
                    }}>{t('MyNameIsText')}</Text>
                    
                    <Text className="text-name" style={{
                      fontFamily: `"TruenoBold", Arial, sans-serif`
                    }}>{user && `${firstname} ${lastname}`}</Text>
                    
                    <Text className='desc' color={Colors.color11}  customStyle={` font-weight: bold; `} style={{
                      fontFamily: `"TruenoRegular", Arial, sans-serif`
                    }}>{t('IamLivingWithDementiaText')}</Text>
                    
                    <div className="mobile2" style={{ width: '100px', margin: '0 auto', marginBottom: '20px' }}>
                      <div className="membershipId2">{user ? membershipId : '-'}</div>
                    </div>
                  </div>
                  <div className="photo-wrapper">
                    <img src={photo ? photo : iconProfile} alt="dementia" className="photo-profile" />
                  </div>
                </div>

                <div className="info-text-container">
                  <div className="info-text">{t('YouAreOnThisText')}</div>
                  <div className="info-text2">{t('IfThisIsAnEmergencyText')}</div>
                </div>

                <div className='bot'>
                  <TextSmall className='textChanged'  customStyle={` font-weight: bold; font-size: 14px; `} style={{fontFamily: `"TruenoRegular", Arial, sans-serif`}}>{t('IfThisIsAnEmergencyText')}</TextSmall>
                  <Text color={Colors.color19} style={{ fontSize: '12px', fontFamily: `"TruenoRegular", Arial, sans-serif`}}>{t('DementiaHelplineText')}</Text>
                  <Row>
                    <Col style={{ }}>
                      <Text color={Colors.color11} style={{ fontSize: '16px',fontWeight: '700', fontFamily: `"TruenoRegular", Arial, sans-serif`}}>6377 0700</Text>
                    </Col>
                    <Col style={{display:'flex',justifyContent:'flex-end', }}>
                      <Text color={Colors.color11} style={{fontSize: '16px',fontWeight: '700', fontFamily: `"TruenoRegular", Arial, sans-serif`}}>cara.sg</Text>
                    </Col>
                  </Row>
                </div>

                <div style={{marginTop: '100px'}}>
                  <Row>
                    <Col style={{display:'flex',justifyContent:'center', }}>
                      <Row>
                        {/* <Col> */}
                          <img style={{ width: '32px', height: '32px' }} src={languegeIcon}/>
                        {/* </Col> */}
                        <Col>
                          <button 
                            disabled={language === 'en' ? true : false} 
                            onClick={()=>this.changeLanguageOption('en')}
                            style={ language === 'en' ? activeButtonLanguage : inActiveButtonLanguage}
                          >
                            English
                          </button>
                        </Col>
                        {/* <Col> */}
                          <Text color='#B1C2D8' style={{fontSize: '24px',fontWeight: '400'}}>|</Text>
                        {/* </Col> */}
                        <Col>  
                          <button 
                            disabled={language === 'md' ? true : false} 
                            onClick={()=>this.changeLanguageOption('md')}
                            style={ language === 'md' ? activeButtonLanguage : inActiveButtonLanguage}
                          >
                            中文
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

              </div>
              <div className="card-right-section">
                <div>
                  <Text color={Colors.color2} style={{
                    fontFamily: `"TruenoRegular", Arial, sans-serif`, 
                  }}>{t('ToCallCaregiversText')}</Text>
                
                  <div style={{ marginTop: '25px', }}>
                    <Label htmlFor="name" className="label-style" style={{fontFamily: `"TruenoRegular", Arial, sans-serif`, fontSize: '16px'}}>{t('YourNameText')}</Label>
                    <Input containerStyle={InputStyle} inputProps={{ 
                      id: 'name',
                      value: name,
                      onChange: (e) => this.onChangeText('name', e.target.value),
                    }} />
                    <Label htmlFor="phone" className="label-style" style={{fontFamily: `"TruenoRegular", Arial, sans-serif`, fontSize: '16px'}}>{t('YourMobileNoText')}</Label>
                    <PhoneInput
                      buttonStyle={{
                        backgroundColor: 'white',
                      }}
                      containerStyle={{
                        marginBottom: 25,
                      }}
                      inputStyle={{
                          width: '100%',
                          backgroundColor: 'white',
                          border: 'none',
                          outline: 'none',
                          border: '1px solid #B1C2D8',
                          borderTopRightRadius: 15,
                          borderBottomRightRadius: 15,
                          height: 58,
                          boxShadow: `-3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color20}`
                      }}
                      country={'sg'}
                      value={this.state.phone}
                      onChange={(phone) => this.setState({ phone })}
                    />
                    {/* <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <div style={{
                        flex:  2
                      }}>
                        <select style={{
                          padding: '17.5px 0 17.5px 18px',
                          width: '100%',
                          backgroundColor: 'white',
                          border: 'none',
                          outline: 'none',
                          border: '1px solid #B1C2D8',
                          borderRadius: 15,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderRight: 'none',
                          boxShadow: `-3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color20}`
                        }}
                        value={this.state.prefixCountryNum}
                        onChange={(e) => {
                          this.setState(() => ({
                            prefixCountryNum: e.target.value
                          }))
                        }}>
                          {
                            this.state.codes.map((item, index) => (
                              <optgroup key={String(index)} label={item.country}>
                                 {item.countryCodes.map((countryCode, countryCodeIndex) => (
                                   <option key={String(countryCodeIndex)} value={`+${countryCode.replace('-', '')}`}>+{countryCode.trim().replace('-', '')}</option>
                                 ))}
                              </optgroup>
                            ))
                          }
                        </select>
                      </div>
                      <div style={{
                        flex: 5
                      }}>
                        <Input containerStyle={InputStyleSafeReturn} inputProps={{ 
                          id: 'phone',
                          maxLength: "15", 
                          onInput: (o) => this.maxLengthCheck(o),
                          value: phone,
                          onChange: (e) => this.onChangeText('phone', `${(e.target.value).replace(/[^0-9 () +]/g, '')}`),  
                        }} />
                      </div>
                    </div> */}
                  </div>
                  <div className="form-info">
                    <div>
                      <img src={iconInfo} className="icon-info" alt="info" />
                    </div>
                    <div className="form-info-text">
                      {t('YourPrivacyText')}
                    </div>
                  </div>
                  <Col>
                    <Row style={{display:'flex',justifyContent:'center', marginTop: '20px', paddingBottom:'20px'}}> 
                      <Button 
                        text={t('SubmitText')}    
                        onClick={this.handleShow} disabled={disable}
                        buttonStyle={{height:'60px', width:'auto', fontFamily: `"TruenoRegular", Arial, sans-serif`, display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
                      />
                    </Row>
                  </Col>
                  <div  className='mobile'>
                    <Text color={Colors.color19} style={{ fontSize: '12px',lineHeight:'14px',marginTop: '20px', fontFamily: `"TruenoRegular", Arial, sans-serif`}} >{t('DementiaHelplineText')}</Text>
                    <Row style={{ paddingTop: '10px', }} >
                    <Col style={{lineHeight:'28px',}}>
                    <Text color={Colors.color11} style={{ fontSize: '16px',fontWeight: '700', fontFamily: `"TruenoRegular", Arial, sans-serif`}}>6377 0700</Text>
              
                    </Col>
                      <Col style={{display:'flex',justifyContent:'flex-end',lineHeight:'28px'}}>
                        <Text color={Colors.color11} style={{ fontSize: '16px',fontWeight: '700', fontFamily: `"TruenoRegular", Arial, sans-serif`}}>cara.sg</Text>
                      </Col>
                    </Row>
                  </div>
                  <PopUpBox show={this.state.show} handleClose={this.handleSendSms}/>
                  <PopUpBoxHandler  show={this.state.alert} text={this.state.errorMessage}  handleClose={this.handleCloseAlert}/>
                </div>
              </div>
            </div>
          </GoogleReCaptchaProvider>
        }
      </StyledUserQRDetail>
    )
  }
}

export default withTranslation()(UserQRDetail)
{/* <a href="tel:555-123-4567">testt</a> */}



                  {/* <Label htmlFor="location" className="label-style" style={{fontFamily: `"TruenoRegular", Arial, sans-serif`, fontSize: '16px'}}>Your location</Label>
                  <Input containerStyle={InputStyle} inputProps={{ 
                    id: 'location', 
                    value: location,
                    onChange: (e) => this.onChangeText('location', e.target.value), 
                  }} /> */}
{/* <div className='mobile'>
<h4 className='blueMobile' style={{fontFamily: `"TruenoRegular", Arial, sans-serif`, textAlign: 'center', lineHeight: 1.5}}>You are on this page because I may seem lost, need help, or you have found an item of mine. 
</h4>
</div> */}
{/* <TextSmall className='mobile textChanged'  customStyle={` font-weight: bold; font-size: 14px; `} style={{fontFamily: `"TruenoRegular", Arial, sans-serif`, textAlign: 'center'}}>If this is an emergency, please call the relevant authorities</TextSmall> */}

{/* <div  className='mobile'>
<div>
  <Text color={Colors.color2} customStyle={` font-weight: bold; `} style={{fontFamily: `"TruenoRegular", Arial, sans-serif`}}>Please be patient as i may:</Text>
  <div style={{ marginTop: '0px', }}>
    <Text style={{ paddingTop: '10px', fontFamily: `"TruenoRegular", Arial, sans-serif` }} color={Colors.color2} customStyle={` font-weight: bold; font-size: 14px; margin-bottom: 5px; `}>• Repeat questions</Text>
    <Text style={{ paddingTop: '10px', fontFamily: `"TruenoRegular", Arial, sans-serif` }} color={Colors.color2} customStyle={` font-weight: bold; font-size: 14px; margin-bottom: 5px; `}>• Forget what you have told me</Text>
    <Text style={{ paddingTop: '10px', fontFamily: `"TruenoRegular", Arial, sans-serif` }} color={Colors.color2} customStyle={` font-weight: bold; font-size: 14px; margin-bottom: 5px; `}>• Take longer to make a decision</Text>
    <Text style={{ paddingTop: '10px',paddingBottom:'20px', fontFamily: `"TruenoRegular", Arial, sans-serif` }} color={Colors.color2} customStyle={` font-weight: bold; font-size: 14px; `}>• Seem confused or gets easily upset</Text>
  </div>
</div>
</div> */}



              {/* <div className='bot'>
                <Text color={Colors.color2} customStyle={` font-weight: bold; `} style={{
                  fontFamily: `"TruenoRegular", Arial, sans-serif`
                }} >Please be patient as i may:</Text>
                <div style={{ 
                  marginTop: '20px', 
                }}>
                  <TextSmall color={Colors.color2} style={textSmallStyle}>• Repeat questions</TextSmall>
                  <TextSmall color={Colors.color2} style={textSmallStyle}>• Forget what you have told me</TextSmall>
                  <TextSmall color={Colors.color2} style={textSmallStyle}>• Take longer to make a decision</TextSmall>
                  <TextSmall color={Colors.color2} style={textSmallStyle}>• Seem confused or gets easily upset</TextSmall>
                </div>
              </div> */}  