import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

const windowHeight = window.innerHeight

export const StyledDialog = styled.div`
  .container-modal {
    padding: 30px;
    padding-bottom: 22px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${props => props.height};
  }
  .image {
    width: 10px;
    height: 10px;
  }
  .title {
    font-family: "TruenoRegular";
    font-weight: bold;
    color: ${Colors.color};
    font-size: 25px;
    padding-top: 10px;
    margin-left: 14px;
    ${props => props.titleStyle}
  }
  .description {
    font-family: "TruenoRegular";
    font-weight: bold;
    color: ${Colors.color};
    ${Fonts.size.size20}
    margin-top: 30px;
    margin-left: 14px;
    ${props => props.descriptionStyle}
  }
  .button-container {
    margin-top: 30px;
    margin-left: 14px;
    display: flex;
    justify-content: space-between;
    ${props => props.buttonContainerStyle}
  }
`