import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import moment from 'moment'
import { useTranslation } from 'react-i18next';

import caraLogo from '../../assets/images/CARA_logo.png'
import iconInfo from '../../assets/images/icon-info.png'
import arrowLeft from '../../assets/images/sr-prev.png'
import arrowRight from '../../assets/images/sr-next.png'

import Button from '../button'

const settings = {
  className: 'carousel',
  centerMode: true,
  infinite: false,
  centerPadding: '60px',
  slidesToShow: 1,
  speed: 500
};

function CaregiverContact({
  taggedAccount,
  onClickCall,
  caregivers,
  carePartners,
}) {
  const { t, i18n } = useTranslation();
  
  const [layout, setLayout] = useState({
    width:  800,
    height: 182,
  })
  const [value, setValue] = useState(0)

  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimensions)
    updateDimensions()
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  function updateDimensions() {
    if(window.innerWidth < 500) {
      setLayout({ width: 450, height: 102 });
    } else {
      let update_width  = window.innerWidth;
      let update_height = Math.round(update_width/4.4);
      setLayout({ width: update_width, height: update_height });
    }
  } 
  
  const renderCardCaregiver = (item) => {
    const isCaregiver = item.Account.type === "CAREGIVER"
    const phoneNumber = (!isCaregiver && item.Account.partnerDetail) ? item.Account.partnerDetail.hotlineNumber : `${item.Account.prefixCountryNum}${item.Account.phone}`
    let relationship = ''

    if(item && item.relationship === 'Nephew / Niece') {
      relationship = t('NephewOrNieceText')
    } else if (item && item.relationship === 'Child') {
      relationship = t('ChildText')
    } else if (item && item.relationship === 'Grandchild') {
      relationship = t('GrandchildText')
    } else if (item && item.relationship === 'Spouse') {
      relationship = t('SpouseText')
    } else if (item && item.relationship === 'Parent') {
      relationship = t('ParentText')
    } else if (item && item.relationship === 'Grandparent') {
      relationship = t('GrandparentText')
    } else if (item && item.relationship === 'Uncle / Aunt') {
      relationship = t('UncleAuntText')
    } else {
      relationship = item.relationship
    }

    return (
      <div className="caregiver-card">
        <div>
          <div style={{ display: 'grid', gridTemplate: 'auto / 1fr 1fr', marginTop: '28px' }}>
            <div className="caregiverNo">{isCaregiver ? t('CareGiverText') : "Care Partner" }</div>
          </div>
    
          {
            (!isCaregiver && item.Account.partnerDetail) &&
              <div>
                <div className="membershipId">{item.Account.partnerDetail.organizationName}</div> 
                <div className="partnerAddress">{item.Account.partnerDetail.unitNumber && `${item.Account.partnerDetail.unitNumber}, `}{item.Account.partnerDetail.address}</div> 
              </div>
          }
        </div>
        {
          !isCaregiver && item.Account.partnerDetail &&
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
            <div className="relationshipText" style={{ marginRight: '-20px' }}>{'Postal Code:'}</div>
            <div className="relationshipText" style={{ fontWeight: 'bold' }}>{
              item.Account.partnerDetail ? item.Account.partnerDetail.postalCode : '-'   
            }</div>
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
          <div>
            <div className="relationshipText">{isCaregiver ? `${t('RelationshipText')}:` : `${t('BranchText')}:`}</div>
            <div className="relationshipText" style={{ fontWeight: 'bold' }}>{
              !isCaregiver && item.Account.partnerDetail ? 
                item.Account.partnerDetail.branch ? item.Account.partnerDetail.branch : '-' 
              : 
                relationship}</div>
          </div>
          <div>
            <Button 
              text={t('CallText')}
              buttonStyle={{ height:'50px', width:'143px', fontFamily: `"TruenoRegular", Arial, sans-serif`, display: 'flex', justifyContent: 'center', alignItems: 'flex-end', marginBottom: '24px' }}
              textStyle={{ fontSize: '16px' }}
              onClick={() => {
                const link = document.createElement('a')
                link.setAttribute("href", `tel:${phoneNumber}`)
                link.click()
                onClickCall()
              }}
            />
          </div>
        </div>
  
      </div>
    )
  }

  return (
    <div className="card-container2">
      {layout.width > 768 ? (
      <div className="card-left-container2">
        <img src={caraLogo} className="cara-logo2" style={{width: 100, height: 40, resizeMode: 'contain'}} />
        <div className="call-text text-max-md-purple">{t('PressCallToContactCaregiverText')}</div>
        <div className="form-info">
          <div>
            <img src={iconInfo} className="icon-info" alt="info" />
          </div>
          <div className="form-info-text">
            {t('TipsToEngagePlwdText')}:
            <ul style={{ marginLeft: '15px' }}>
              <li>{t('SpeakSlowlyText')}</li>
              <li>{t('AskOpenEndedText')}</li>
            </ul>
          </div>
        </div>
        <div style={{ height: '1px', width: '100%', background: '#DEE4EB', marginBottom: '28px' }} />
        <div className="ifyou">{t('IfYouRequireMoreAssistanceText')}</div>
        <Button 
          text={t('DementiaGoToPointLocatorText')}
          buttonStyle={{ height:'50px', width:'auto', fontFamily: `"TruenoRegular", Arial, sans-serif`, display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
          textStyle={{ fontSize: '16px' }}
          onClick={() => window.location = '/go-to-points'}
          // onClick={() => window.open('https://www.dementiahub.sg/gtp-database/', '_blank')}
        />
      </div>
      ) : null}
      <div style={{marginTop: '50px'}}>
        {((caregivers && caregivers.length > 0) && (carePartners && carePartners.length > 0)) &&
          <div className="call-text mobile2 text-purple" style={{ margin: 0 }}>{t('PressCallToContactCaregiverText')}</div>
        }
        <div className="card-right-container2 carousel-mobile">
          {
            layout.width > 768 ?
            <>
              {
                taggedAccount && taggedAccount.map((item) => {
                  return (
                    renderCardCaregiver(item)
                  )
                })
              }
            </>
            :
            <div className="carousel-container mt-30px" 
            style={{maxWidth: '90vw'}}
            >
              {((caregivers && caregivers.length > 0) && (carePartners && carePartners.length < 1)) &&
                <p className="form-info-text steps on-mobile w-100">
                  <stepTitle>{t('Step1Text')}:</stepTitle> {" "}
                  <stepDesc>
                    {t('PressCallToContactCaregiverText')}
                  </stepDesc>
                </p>
              }
              {caregivers && caregivers.length > 0 && 
                <p className="cg-text">{t('CareGiverText')}</p>
              }
              {((caregivers && caregivers.length > 0) && (carePartners && carePartners.length > 0)) &&
                <p className="cg-text2">{t('PleaseContactText')}</p>
              }
              {caregivers && caregivers.length > 0 && 
              <>
              {/* <p className="cg-text2">(If this field is blank, please scroll down to call Care Partner.)</p> */}
              {
                caregivers.length > 1 ? 
                <Carousel 
                mouseTracking
                activeIndex={0}
                renderPrevButton={({ isDisabled }) => {
                  return (
                    <div className="arrow-left-container">
                      <img src={arrowLeft} />
                    </div>
                  )
                }}
                renderNextButton={({ isDisabled }) => {
                  return (
                    <div className="arrow-right-container">
                      <img src={arrowRight} />
                    </div>
                  )
                }}
                >
                  {
                    caregivers && caregivers.map((item) => {
                      return (
                        renderCardCaregiver(item)
                      )
                    })
                  }
                </Carousel>
                :
                caregivers && caregivers.map((item) => {
                  return (
                    renderCardCaregiver(item)
                  )
                })

              }
              {((caregivers && caregivers.length > 0) && (carePartners && carePartners.length < 1)) &&
                <p className="form-info-text steps on-mobile w-100">
                  <stepTitle>{t('Step2Text')}:</stepTitle> {" "}
                  <stepDesc>
                   {t('LocateText')}
                  </stepDesc>
                </p>
              }
              </>
              }
              {carePartners && carePartners.length > 0 &&
              <>
                {caregivers && caregivers.length < 1 &&
                <>
                  <p className="form-info-text steps on-mobile w-100">
                    <stepTitle>{t('Step1Text')}:</stepTitle> {" "}
                    <stepDesc>
                     {t('PressCallToContactCaregiver2Text')} <span className="cg-text2"> ({t('BetweenText')})</span>
                    </stepDesc>
                  </p>
                </>
                }
                <p className="cg-text">{t('CarePartnerText')}</p>
                {((caregivers && caregivers.length > 0) && (carePartners && carePartners.length > 0)) &&
                <p className="cg-text2">{t('IfUnableToGetText')}</p>
              }
              {
                carePartners.length > 1 ? 
                  <Carousel 
                  mouseTracking
                  activeIndex={0}
                  renderPrevButton={({ isDisabled }) => {
                    return (
                      <div className="arrow-left-container">
                        <img src={arrowLeft} />
                      </div>
                    )
                  }}
                  renderNextButton={({ isDisabled }) => {
                    return (
                      <div className="arrow-right-container">
                        <img src={arrowRight} />
                      </div>
                    )
                  }}
                  >
                    {
                      carePartners && carePartners.map((item) => {
                        return (
                          renderCardCaregiver(item)
                        )
                      })
                    }
                  </Carousel>
                  :
                  carePartners && carePartners.map((item) => {
                    return (
                      renderCardCaregiver(item)
                    )
                  })
                }
                { caregivers && caregivers.length < 1 && 
                  <p className="form-info-text steps on-mobile w-100">
                    <stepTitle>{t('Step2Text')}:</stepTitle> {" "}
                    <stepDesc>
                      {t('LocateText')}
                    </stepDesc>
                  </p>
                }
              </>
              }
              {
                (caregivers && caregivers.length > 0) && (carePartners && carePartners.length > 0) ? 
                <div className="call-text text-purple">
                  {t('LocateText')}
                </div>
                : ''
              }
              <Button 
                text={t('DementiaGoToPointLocatorText')}
                buttonStyle={{ height:'50px', width:'auto', fontFamily: `"TruenoRegular", Arial, sans-serif`, display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
                textStyle={{ fontSize: '16px' }}
                onClick={() => window.location = '/go-to-points'}
              />

              <div className="form-info all-text-purple" style={{marginTop: '34px'}}>
                <div>
                  <img src={iconInfo} className="icon-info" alt="info" />
                </div>
                <div className="form-info-text">
                  {t('TipsToEngagePlwdText')}:
                  <ul style={{ marginLeft: '15px', marginBottom: '0px' }}>
                    <li>{t('SpeakSlowlyText')}</li>
                    <li>{t('AskOpenEndedText')}</li>
                  </ul>
                </div>
              </div>

              <div>
                <p className="call-authorities pointer" onClick={() => {
                  const link = document.createElement('a')
                  link.setAttribute("href", `tel:${999}`)
                  link.click()
                  onClickCall()
                }}>{t('CallPoliceText')}</p>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

CaregiverContact.propTypes = {
  taggedAccount: PropTypes.array,
  onClickCall: PropTypes.func,
}

CaregiverContact.propTypes = {
  taggedAccount: [],
  onClickCall: () => null,
}

export default CaregiverContact
