import React, { Component } from 'react'
import queryString from 'query-string';

import Header from '../../components/header'

import { connect } from 'react-redux';
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import MultiSelect from '../../components/multiSelectDropdown'

import colors from '../../themes/Colors';
import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import Swal from 'sweetalert2'
import AppConfig from '../../config'

import axios from 'axios'
const accountUrl = AppConfig.ACCOUNT_URL
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import PopUpBoxHandler from '../../components/modalhandler';

import { verifyPartnerToken, } from '../../api/UserAPI'

import ProccessData from '../../lib/ProccessData'

import smallInfo from '../../assets/images/small-info.png'

const eye = <FontAwesomeIcon icon={faEye} />;

import GoogleLogin from 'react-google-login'; 

export class RegistrationPWD extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          passwordShown: false,
          username: '',
          password:'',
          confirmPassword:'',
          show: false,
          type: 'password',
          typeConfirm: 'password',
          errorMessage:'',
          enableBtn:true,
          firstname: '',
          lastname:'',
          email:'',
          idTokenGoogle:'',
          credentialType: "USERNAME",
          masterPartnerUid: null,
          partnerToken: queryString.parse(this.props.location.search).partnerToken,
          agree: false,
          partnerAddress: null,
          partnerBranch: null,
          partnerPostalCode: null,
          howToFindCaraSelected: false,
          findCaraList: [
            {
              id: 1,
              name: 'Search Engine (Google, Yahoo, etc)',
              display: 'Search Engine (Google, Yahoo, etc)',
              isActive: false
            },
            {
              id: 2,
              name:  'Recommendation by friend or collague',
              display: 'Recommendation by friend or collague',
              isActive: false
            },
            {
              id: 3,
              name: 'Social Media',
              display: 'Social Media',
              isActive: false
            },
            {
              id: 4,
              name:  'Poster at MRT / Bus interchange',
              display: 'Poster at MRT / Bus interchange',
              isActive: false
            },
            {
              id: 5,
              name: 'Others',
              display: 'Others',
              isActive: false
            }
          ]
        }
    }

    componentDidMount() {
      const partnerToken = queryString.parse(this.props.location.search).partnerToken
      if (partnerToken) this.checkToken(partnerToken)  
    }

    checkToken = async (token) => {
      try {
        let resultCheck = {}
        resultCheck.payload = await verifyPartnerToken({ headers: {
          Authorization: `bearer ${token}`
        }})
        ProccessData(resultCheck  , 'check partner token')
        .then(res => {
          console.log('res partner => ', res)
          this.setState({
            masterPartnerUid: res.data.account.id,
            partnerAddress: res.data.account.address,
            partnerBranch: res.data.account.unitNumber,
            partnerPostalCode: res.data.account.postalCode,
          })
        })
        .catch(err => {
          console.log('err => ', err)
          this.alertError(err)
        })
      } catch (e) {
        this.alertError(e)
      }
    }

    alertError = (text, exit = true) => {
      Swal.fire({
        title: 'Error!',
        text,
        icon: 'error',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        preConfirm: () => {
          console.log('exit => ', exit)
          if (exit) {
            this.props.history.replace('/')
          } else null
        },
      })
    }

      responseGoogleFail = ()=> {
        Swal.fire({
          title: 'Warning!',
          text: 'This website uses cookies to help ensure you receive an optimal experience. Please activate cookies.',
          icon: 'info',
          confirmButtonText: 'OK'
        })
        // this.props.history.push({pathname:'/registrationPWD',})
      }
 responseGoogle = (response) => {
  this.setState({credentialType: "GOOGLE"})
Swal.fire({
 title: 'Please fill username ',
 html:
   `<input   id="swal-input1" class="swal2-input">` ,
   
   showCancelButton: true,
 preConfirm: () => {
   
     this.setState({username : document.getElementById('swal-input1').value})
    
    if(this.state.username =='' ) {
     Swal.fire({ title: 'Warning!',
     text: 'Username',
     icon: 'info',
     confirmButtonText: 'OK'})
   }else{
     axios.post(`${accountUrl}/public/credential/checkDementiaRegistration`, {
       username : this.state.username
  })
  .then(res => {
      console.log(res)
    if(res.status==200 && res.data.data.registered==true){
      
     Swal.fire({
       title: 'Warning!',
       text: 'This google account is already exist',
       icon: 'info',
       confirmButtonText: 'OK'
     })
    }else{ 
      this.setState({idTokenGoogle:response.tokenId, firstname: response.profileObj.givenName,
         lastname:response.profileObj.familyName,email:response.profileObj.email})

     this.submitForm(); 
     
    }
    
   })
 }


 }
})

   
 
 console.log(response);
}
      togglePasswordVisiblity = () => {
        if(this.state.passwordShown==false){

          this.setState({passwordShown :true});
          
          this.setState({type:'text'})

        }else{
          this.setState({type:'password'})
          this.setState({passwordShown :false});
          
        }
      };

      togglePasswordConfirmVisiblity = () => {
        if(this.state.passwordShownConfirm==false){

          this.setState({passwordShownConfirm :true});
          
          this.setState({typeConfirm:'text'})

        }else{
          this.setState({typeConfirm:'password'})
          this.setState({passwordShownConfirm :false});
          
        }
      };
    submitForm = ()=>{
      const token = queryString.parse(this.props.location.search).token

      axios.post(`${accountUrl}/public/credential/checkDementiaRegistration`, {
        username : this.state.username
      }).then(res => {
          console.log(res)
        if(res.status==200 && res.data.data.registered==true){
          Swal.fire({
            title: 'Warning!',
            text: 'This username is already exist',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }else{

          window.sessionStorage.setItem("firstname", this.state.firstname);
          window.sessionStorage.setItem("lastname", this.state.lastname);
          
          window.sessionStorage.setItem("email",this.state.email)
          if (this.state.masterPartnerUid) {
            window.sessionStorage.setItem("masterPartnerUid", this.state.masterPartnerUid)
          } 

          if (this.state.partnerToken) {
            window.sessionStorage.setItem("partnerToken", this.state.partnerToken)
          } 

          if (this.state.partnerAddress) {
            window.sessionStorage.setItem("partnerAddress", this.state.partnerAddress)
          } 

          if (this.state.partnerBranch) {
            window.sessionStorage.setItem("partnerBranch", this.state.partnerBranch)
          } 

          if (this.state.partnerPostalCode) {
            window.sessionStorage.setItem("partnerPostalCode", this.state.partnerPostalCode)
          } 
          this.props.history.push({pathname:'/signUpPersonDetailPWD',
          state:{
            username: this.state.username,
            password: this.state.password, 
            credentialType: this.state.credentialType,
            idTokenGoogle:this.state.idTokenGoogle,
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            email:this.state.email,
            idTokenGoogle:this.state.idTokenGoogle,
            credentialType: this.state.credentialType,
            masterPartnerUid: this.state.masterPartnerUid,
            partnerToken: this.state.partnerToken,
            partnerAddress: this.state.partnerAddress,
            partnerBranch: this.state.partnerBranch,
            partnerPostalCode: this.state.partnerPostalCode,
            findCaraList: this.state.findCaraList,
            token: token ? token : null
          }})

          if(this.state.password == this.state.confirmPassword){
            console.log("Password Match")
          }
          return this.state
          
        };
      }, (error) => {
        console.log(error);
      });
     
    }
    
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value }, () => {
      if((this.state.password.length >5) &&(this.state.password == this.state.confirmPassword)){

             this.setState({enableBtn:false})
      
     } else{
       this.setState({enableBtn:true})
     };
  })

  };


  onChangeList = (data) => {
    const { findCaraList } = this.state
    let newData = findCaraList

    for(let i = 0; i < newData.length; i++){
      if(newData[i].id === data.id) {
        newData[i] = data
      }
    }

    this.setState({howToFindCaraSelected: false})

    for(let i = 0; i < newData.length; i++) {
      if(newData[i].isActive === true && (newData[i].name !== '' &&  newData[i].name !== 'Others')) {
        this.setState({howToFindCaraSelected: true})
        break;
      }
    }

    this.setState({findCara: newData})
  }

  onChangeOtherDropdown = (data) => {
    const { findCaraList } = this.state
    let newData =  findCaraList

    newData[4].name = data

    if((newData[4].name !== 'Others') || (newData[4].name !== '')) {
      for(let i = 0; i < newData.length; i++) {
        if(newData[i].isActive === true) {
          if((newData[4].name !== '' && newData[4].name !== 'Others')) {
            this.setState({...this.state, howToFindCaraSelected: true, findCara: newData})
          } else {
            this.setState({...this.state, howToFindCaraSelected: false, findCara: newData})
          }
          break;
        } else {
          this.setState({...this.state, howToFindCaraSelected: false, findCara: newData})
        }
      }
    } else {
      this.setState({...this.state, findCara: newData})
    }
  }

    handleClose = (e)=> {
    
     this.setState({show: false})
    } 
    back=()=>{
      this.props.history.goBack()
    }

   _handleCheckbox = (e, agree) => {
      let checked = e.target.checked;
      this.setState(state => ({
        ...state,
        [agree]: checked
      }))
    }

  render() {
    const {passwordShown,type, typeConfirm,username,password,confirmPassword,enableBtn,show,errorMessage, findCaraList} = this.state
    const isPartner = queryString.parse(this.props.location.search).partnerToken

    return (
      <>
        <StyledCG>
          <Header title={isPartner ? ` ` : null} />
          <h2 style={{marginTop:'5%'}}>{isPartner ? `Create PLWD Account` : `Sign Up as Person Living with Dementia`}</h2>
          <Row style={{textAlign:'start',marginTop:'6%'}}>
            <Col md={5} style={{marginTop:'auto',marginBottom:'auto',textAlign:'start'}}>
              <label>Username</label>
            </Col>
            <Col md={7}>
              <Input containerStyle="InputStyle" inputProps={{
                  name:"username",
                  type:'text',
                  value:username,
                  onChange: (e) => this.onChangeText('username', e.target.value), 
                }}
               />
               {/* {
                 isPartner &&
                  <div className="small-info-container">
                    <div>
                      <img src={smallInfo} alt="info" className="small-info-image" />
                    </div>
                    <div className="small-info-text" style={{ marginRight: '17px', }}>
                      Username need to be minimum 6 characters
                    </div>
                  </div>
               } */}
            </Col>
          </Row>
          { 
            !isPartner &&
            <>
              <Row>
                <Col md={5} style={{margin:'auto'}}>
                  <label>Password</label>
                </Col>
                <Col md={7}>
                  <Input containerStyle="InputStyle" inputProps={{
                    name:"password",
                    type:type,
                    value:password,
                    onChange: (e) => this.onChangeText('password', e.target.value)}}  
                  />
                  <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                </Col>
              </Row>
              <Row>
                <Col md={5} style={{margin:'auto'}}>
                    <label>Confirm Password</label>
                </Col>
                <Col md={7} style={{display:'flex'}}>
                <Input 
                  containerStyle="InputStyle" 
                  inputProps={{ 
                    name:"confirmPassword",
                    value:confirmPassword, type:typeConfirm,
                    onChange: (e) => this.onChangeText('confirmPassword', e.target.value) }}  />
                    <i style={{ justifyContent:'flex-end'}} onClick={this.togglePasswordConfirmVisiblity}>{eye}</i>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col md={5}>
                  <label>How did you find about CARA?</label>
                </Col>
                <Col md={7} style={{margin:'auto',padding:'0 15px'}}>
                  < MultiSelect 
                    data={findCaraList}
                    onChangeList={this.onChangeList}
                    onChangeOtherDropdown={this.onChangeOtherDropdown}
                  />
                </Col>
              </Row>
            </>

          }

          {
            isPartner && 
            <div className="agree-container">
              <div className="agree-text" style={{ color: `#502773`, marginBottom: '14px' }}>“By clicking “Register”, I have verified that the user has consented to the creation of a CARA “Person living with dementia” membership account and will be bounded by the CARA Membership Terms and Conditions CARA Privacy Policy, and CARA Terms of Use (“CARA documents”).</div>
              <div class="checkbox-container">
                <label class="checkbox-label">
                    <input type="checkbox" onClick={(e) => this._handleCheckbox(e, 'agree')} />
                    <span class="checkbox-custom rectangular"></span>
                </label>
                <div class="input-title"> I agree to the <a href='https://cara.sg/terms-of-use/' target="_blank"  style={{color:' #502773',paddingLeft:'5px', fontWeight:'bold',}}>Terms of Use</a>,<a href='https://cara.sg/privacy-policy/' target="_blank"  style={{color:' #502773',paddingLeft:'5px', fontWeight:'bold',}}>Privacy Policy</a> and the<a href='https://cara.sg/cara-membership-terms-condition/' target="_blank"  style={{color:' #502773',paddingLeft:'5px', fontWeight:'bold',}}>CARA Membership Terms and Conditions</a></div>
              </div>
            </div>
          }
                
            <Row style={{justifyContent:'flex-end',marginRight:'1%',marginTop:'10%'}}>
              <Col md={3}>
                <Button onClick={this.back} containerStyle={StyledButton} textStyle={StyledButton}  buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} backgroundColor={colors.color3} textColor={colors.color11} bordered={colors.color11} text="Back" />
              </Col>
              <Col md={3}>  
                <Button disabled={isPartner ? username.length < 4 || !this.state.agree : username.length < 4 || enableBtn}  text="Register" onClick={this.submitForm} containerStyle={StyledButton} textStyle={StyledButton} buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}/>
              </Col>
            </Row>
            <Row style={{justifyContent:'flex-end',marginTop:'2%'}}>
              <a style={{marginRight: '15px',}} href="https://cara.sg/privacy-policy/"><p>Privacy Policy</p></a>
              <a style={{marginLeft: '15px',marginRight: '20px'}} href="https://cara.sg/cara-membership-terms-condition/"><p>Membership Terms and Condition</p></a>
              <a style={{marginLeft: '15px',marginRight: '20px'}} href="https://cara.sg/terms-of-use/"><p>Terms of Use</p></a>
            </Row>
           
           
           
                <hr style={{marginTop:'2%',marginBottom:'5%', border: "1px solid #758BA9"}}/>
                {/* {
                  !isPartner &&
                    <Row style={{marginBottom:'10%',}}>
                      <Col md={4} > <a color='#424242' href=""><label className="signUp" >Or sign up with</label></a></Col>
                      <Col md={8} style={{textAlign:'end'}}> 
                        <GoogleLogin
                          clientId={AppConfig.APP_CLIENT_ID}
                          render={renderProps => ( <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="google"><img style={{margin:'auto'}} src={google}/></button>

                          )}

                          onSuccess={this.responseGoogle}
                          onFailure={this.responseGoogleFail}
                          cookiePolicy={'single_host_origin'}
                        /> 
                      </Col>
                    </Row>
                } */}
               
            </StyledCG>

        
       
      </>
    )
  }
}


export default RegistrationPWD