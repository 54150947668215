import { getNearestGtp } from "../../../api/UserAPI";

export const fitToMarkers = (
  data,
  { gtps, currentCoordinate, reactMapRef, isMobile }
) => {
  let bounds = gtps.map(({ lat, lng }) => [Number(lng), Number(lat)]);

  if (data.length)
    bounds = data.map(({ lat, lng }) => [Number(lng), Number(lat)]);

  bounds.push([
    Number(currentCoordinate.longitude),
    Number(currentCoordinate.latitude),
  ]);

  bounds = [
    [
      Math.max(...bounds.map((el) => el[0])),
      Math.max(...bounds.map((el) => el[1])),
    ],
    [
      Math.min(...bounds.map((el) => el[0])),
      Math.min(...bounds.map((el) => el[1])),
    ],
  ];
  reactMapRef.current?.fitBounds(bounds, {
    padding: isMobile
      ? {
          top: 60,
          left: 60,
          right: 60,
          bottom: 403,
        }
      : 60,
  });
};

export const fetchGTPs = async (
  lat,
  lng,
  search,
  postalCode,
  region,
  {
    setGtps,
    currentCoordinate,
    reactMapRef,
    gtps,
    isMobile,
    setLoading,
    setIsNearestPostalCodeModal,
    setIsNearestPostalCode,
  }
) => {
  if (currentCoordinate.status) {
    setGtps([]);
    if (!lat) lat = currentCoordinate.latitude;
    if (!lng) lng = currentCoordinate.longitude;

    let tempGtpData = [];
    setLoading(true);
    await getNearestGtp({
      lat,
      lng,
      search,
      region,
      limit: 5,
      postalCode,
    })
      .then(({ data }) => {
        if (data?.data?.length) {
          setIsNearestPostalCodeModal(false);
          setIsNearestPostalCode(false);
          tempGtpData = data.data;
        } else if (data?.suggestedData?.length) {
          setIsNearestPostalCodeModal(true);
          setIsNearestPostalCode(true);
          tempGtpData = data?.suggestedData;
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setGtps(tempGtpData);
        setLoading(false);
        return fitToMarkers(tempGtpData, {
          gtps,
          currentCoordinate,
          reactMapRef,
          isMobile,
        });
      });
  }
};
