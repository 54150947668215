import React, { Component } from 'react';
import Navigation from '../components/navigation';
import Section1 from '../components/content/Section1'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './LandingPage.css'
import  '../components/navigation/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {StyledTerms} from './styles/StyledTerms'

import Faq from "react-faq-component";
import { Link } from 'react-router-dom';
class MembershipTerms extends Component {
 

    render(){
    
    return(
        <div>
            <Navigation Home="/" Why="/" How="/" Social="/" Faq="/"/>
                <StyledTerms>
                   
                    <Row style={{borderBottom: '1px solid #758BA9'}}>
                        <Col xs={4}  style={{display:'flex',justifyContent:'center',width: '440px'}}>
                            <a href='https://cara.sg/terms-of-use/'><h4>Terms of Use</h4></a>
                        </Col>
                        <Col xs={3} style={{display:'flex',justifyContent:'center'}}>
                            <a href='https://cara.sg/privacy-policy/'>
                                <h4>Privacy Policy</h4>
                            </a>
                        </Col>
                        <Col xs={5} style={{borderBottom: '3px #7F59A0 solid', display:'flex',justifyContent:'center'}}>
                            <h4  className='active'>Membership Terms and Condition</h4>
                        </Col>
                    </Row>
                   
                        <h3>Membership Terms and Condition</h3>
                        <p>These Terms and Conditions define the contractual relationship between Dementia Singapore Limited (“Dementia Singapore”) and each individual registered CARA member. All persons applying for CARA membership programme shall be deemed to have read and accepted the Terms and Conditions. Members are responsible for keeping themselves informed of the current Terms and Conditions of the CARA membership programme which are posted on CARA’s website.  CARA membership card refers to physical and/or electronic versions. Continued participation in the CARA membership programme by a member will be regarded as acceptance of the current Terms and Conditions.
</p>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>A. General Conditions</h5></Row>
                            <Row><p>1. The CARA membership programme and membership card is owned and administered by Dementia Singapore. </p>
                            <p>2. Application is open to any Singapore citizens and Singapore permanent resident who is either i) a person living with dementia, or ii) a caregiver of a person living with dementia. The CARA membership card is issued at the sole and absolute discretion of Dementia Singapore. </p>
                            <p>3. The CARA membership is strictly personal and only for use by the member. It cannot be transferred, sold or bartered. The member is responsible for ensuring that his/her CARA account is not used improperly. Misuse of CARA membership cards, CARA programme, their related benefits may result in termination or suspension of membership, or withdrawal of benefits. Dementia Singapore reserves the right to claim compensation for damage sustained in connection with the misuse. </p>
                            <p>4. CARA benefits may only be extended upon presentation of a valid CARA membership card. The CARA membership card has no cash value.</p>
                            <p>5. The use of the CARA membership card is deemed as acceptance of the terms and conditions by Dementia Singapore and all participating organisations. </p>
                            <p>6. The CARA membership card may not be altered, tampered or modified in any manner.</p>
                            <p>7. Lost or stolen CARA membership cards must be immediately reported to Dementia Singapore.  </p>
                            <p>8. The CARA membership card remains the property of Dementia Singapore and must be returned upon request. Misuse of the CARA membership card or its accompanying benefits may result in termination of the CARA membership card or withdrawal of benefits without refund or compensation.</p>
                            <p>9. Dementia SIngapore reserves the right to modify the structure, benefits and other features of the CARA membership card, including these terms at any time. Use of the CARA membership card by a cardholder will be deemed as acceptance of any amendment to the benefits, terms and conditions of the CARA membership card. Dementia Singapore will not be liable for loss or damage resulting there from. </p>
                            <p>10. Dementia Singapore does not negotiate or guarantee prices or terms made by participating partners to cardholders through the CARA membership card Benefits Scheme.</p>
                            <p>11. Dementia Singapore shall not be liable for any errors or omissions within these terms and conditions. Dementia Singapore reserves the right to final interpretation of these terms and conditions. </p>
                            <p>12. Dementia Singapore has no responsibility or obligation with respect to purchases or sales (including no responsibility or obligation for disputes between an individual cardholder and any participating partner). </p>
                            <p>13. Although Dementia Singapore will use reasonable efforts to notify members of material changes to the CARA membership programme, it is the responsibility of members to keep themselves up to date. Any use of the CARA membership programme by a member will be deemed as acceptance of any amendment. </p>
                            <p>14. Dementia Singapore reserves the right to audit the member’s account at any time for compliance with the Terms and Conditions without prior notice. </p>
                            
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>B. Membership enrolment and obligations</h5></Row>
                            <Row><p>1. A CARA account belongs to the specified member and cannot be transferred to any other individual(s). Membership, including the CARA membership card and other CARA benefits are not transferable.</p>
                            <p>2. All mobile numbers and email addresses provided to Dementia Singapore must be valid and personal to the member or applicant. In addition, the mobile number and email address provided by new applicants at the time of enrolment must not have been registered with Dementia Singapore previously.</p>
                            <p>3. It is the responsibility of members to keep their mailing address, mobile number, and email address up-to-date. Any communication sent to members will be regarded as delivered when posted to a mailing address on record. Dementia Singapore will not be responsible for any delayed, misdirected or lost mail. Any change in the Members' mailing address must be provided in writing, or updated online by accessing your account via the CARA app.  </p>
                            <p>4. Any change in name, date of birth, last four characters of NRIC, or gender on the member's record will be accepted upon written notification to Dementia Singapore by the member. The member shall submit the relevant legal documents as proof of change. Other changes to personal data, such as a change in residential or email address, may be made online by accessing your account via the CARA app. </p>
                            <p>5. Multiple memberships are not permitted. Each member must maintain only one account. Similarly, enrolment of several persons in a single account (i.e., a joint account) is not allowed.</p>
                            <p>6. Membership enrolment fee is waived until further notice. </p>
                            <p>7. The member’s obligations are as follows (where the applicant/member is a minor, the parental/legal guardian will ensure that the CARA applicant/member fulfills these obligations): </p>
                            <p style={{textIndent:'10px'}}>a. Provide Dementia Singapore with correct or complete information in connection with the application or membership and promptly inform Dementia Singapore if such information changes. An account with inaccurate and/or incomplete personal data shall be closed at the sole discretion of Dementia Singapore. </p>
                            <p style={{textIndent:'10px'}}>b. Quote the CARA membership number if requested, as a precondition of any activity which may result in the member’s claim to CARA member benefits. </p>
                            <p style={{textIndent:'10px'}}>c. Keep the member’s CARA membership number, PIN and password secure and confidential and not disclose them to any person other than, where applicable, the parental/legal guardian. </p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>C. Membership Termination </h5></Row>
                            <Row><p>1. Dementia Singapore reserves the right to suspend or terminate membership in the CARA membership programme at any time with or without prior notice. Dementia Singapore is not liable to members for any loss of value or benefits that may arise as a result of any changes made to the programme. In particular, occurrence of the following event may cause suspension or termination: In the sole and exclusive opinion of Dementia Singapore, the member has acted dishonestly or in any manner that is unacceptable to Dementia Singapore in relation to his/her membership. </p>
                            <p>2. If the membership is suspended, member will not be able to use any CARA benefits and rewards.  </p>
                            <p>3. A member may terminate the CARA membership at any time by giving written notice and returning the membership card to Dementia Singapore. Upon receipt of the notification, any outstanding benefits and privileges will be forfeited without compensation and cannot be transferred.</p>
                            <p>4. Membership will terminate immediately upon death of the member. CARA membership benefits will be automatically forfeited on the death of the member.  </p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>D. Personal Identification number (PIN)/Password </h5></Row>
                            <Row>  <p>1. Members may create a confidential Personal Identification Number (PIN)/Password through the CARA app. The Password is needed for 'self-service' transactions relating to the member's secured information such as personal data, unique QR code identifier and member information.   </p>
                            <p>2. Members shall keep their PIN/Password confidential at all times and undertake not to disclose the PIN/Password to any other person under any circumstances whatsoever.  </p>
                            <p>3. Members must ensure that this PIN/Password is not disclosed to any unauthorised parties. Dementia Singapore cannot be held liable for the consequences of any unauthorised disclosure of the PIN/Password by members or unauthorised use of the PIN/Password.  Members acknowledge that Dementia Singapore is under no obligation or duty to verify the authenticity of any person who performs transactions through the use of a valid PIN/Password. </p>  
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>E. Personal Data Protection  </h5></Row>
                            <Row>  <p>1. Information regarding the processing of member’s personal data is set out in the CARA Privacy Policy accessible at <a to='https://cara.sg/privacy-policy/'> https://cara.sg/Privacy-Policy</a> </p>
                             </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>F. Limitation to Liability </h5></Row>
                            <Row>  <p>1. Dementia Singapore shall not be liable to any member or nominee for any loss of revenue, profit, business or any indirect or consequential loss, damage or expense of any kind whatsoever arising out of or in connection with the CARA membership programme , the provision or the refusal to provide any benefits and rewards and the use of rewards, whether such loss, damage or expense is caused by negligence or otherwise, and whether Dementia Singapore has any control over the circumstances giving rise to the claim or not.  </p>
                            <p>2. For the avoidance of any doubt, Dementia Singapore shall not be liable for any loss or damage resulting from the termination of or any change in the CARA Programme and related benefits.</p>
                            <p>3. Dementia Singapore will endeavour to ensure the availability of products and services provided by programme partners but will not be liable for any loss arising from the failure by programme partners to provide such products and/or services. Where a member uses the services provided by a programme partner, their terms and conditions will apply and Dementia Singapore will not be liable for any loss. </p>  
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>G. Miscellaneous  </h5></Row>
                            <Row>  <p>1. Dementia Singapore shall not be in breach of its obligation of confidentiality if disclosure of a member's confidential information is pursued in conjunction with an order of court, or is required by law or any governmental or regulatory authority. However, if the disclosure is not required with immediate effect, Dementia Singapore shall give the member prior notice of such disclosure. </p>
                            <p>2. Should CARA collaterals be available in other languages, the English language version shall be the only point of reference if any discrepancy arises.</p>
                            <p>3. These Terms and Conditions shall be governed by and construed in accordance with the laws of the Republic of Singapore. Any unresolved dispute arising under, out of, or in connection with these Terms and Conditions, including any questions regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of the Singapore International Arbitration Centre for the time being in force.</p>  
                            </Row>
                        </Col>
            </StyledTerms>
        </div>
        )
    }
}

export default MembershipTerms