import React from 'react'
import PropTypes from 'prop-types'

import {Container, StyledButton, StyledDropdown } from './StyledButton'
import { Colors, Images, } from '../../themes'

function ButtonUnclickable({ text, iconRightStyle, onClick, buttonStyle, textStyle, backgroundColor, textColor, bordered, borderColor, iconRight, iconRightImage, containerStyle, showDropdown, dropdownData, dropdownHeight, onSelectSort, disabled, dropdownWidth, selectedData, sortPropertyName, styleHoverDropdown, iconLeftImage, iconLeftStyle}) {

  return (
    <Container containerStyle={containerStyle}>
      <StyledButton onClick={disabled ? null : onClick} buttonStyle={buttonStyle} backgroundColor={backgroundColor} textColor={textColor} bordered={bordered} borderColor={borderColor} textStyle={textStyle} disabled={disabled} iconRightStyle={iconRightStyle} iconLeftStyle={iconLeftStyle}>
        {
          iconLeftImage &&
          <img src={iconLeftImage} alt="icon" className="icon-left"  />
        }
        <p className="button-text">{text}</p>
        {
          iconRight &&
          <img src={iconRightImage} alt="icon" className="icon-right"  />
        }
      </StyledButton>
      <StyledDropdown showDropdown={showDropdown} styleHoverDropdown={styleHoverDropdown} dropdownHeight={dropdownHeight} dropdownWidth={dropdownWidth}>
        <ul>
          {
            dropdownData.map((data, index) => {
              return (
                <div style={{ background: selectedData === data[sortPropertyName] ? Colors.color10  : Colors.color3 }}>
                  <li key={index.toString()} onClick={() => onSelectSort(data)} style={{ color: selectedData === data[sortPropertyName] ? Colors.color3 : '' }}>{data[sortPropertyName]}</li>
                </div>
              )
            })
          }
        </ul>
      </StyledDropdown>
    </Container>
  )
}

ButtonUnclickable.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.any,
  textStyle: PropTypes.any,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  bordered: PropTypes.bool,
  borderColor: PropTypes.string,
  iconRight: PropTypes.bool,
  iconRightImage: PropTypes.string,
  containerStyle: PropTypes.string,
  showDropdown: PropTypes.bool,
  dropdownData: PropTypes.array,
  dropdownHeight: PropTypes.string,
  onSelectSort: PropTypes.func,
  disabled: PropTypes.bool,
  dropdownWidth: PropTypes.string,
  selectedData: PropTypes.string,
  iconLeftImage: PropTypes.any,
  iconLeftStyle: PropTypes.string,
}

ButtonUnclickable.defaultProps = {
  text: '',
  onClick: () => null,
  buttonStyle: '',
  buttonStyle: '',
  textStyle: '',
  backgroundColor: Colors.color8,
  textColor: Colors.color3,
  bordered: false,
  borderColor: Colors.color11,
  iconRight: false,
  iconRightImage: Images.polygon,
  iconRightStyle: 'width: 12px; height: 8px;',
  containerStyle: '',
  showDropdown: false,
  dropdownData: [
    {
      id: 1,
      name: 'Status',
      sortBy: 'accountStatus,asc',
    },
    {
      id: 2,
      name: 'A-Z',
      sortBy: 'firstname,asc',
    },
    {
      id: 3,
      name: 'Z-A',
      sortBy:  'firstname,desc'
    },
    {
      id: 4,
      name: 'Last Updated',
      sortBy: 'updatedAt,desc',
    }
  ],
  dropdownHeight: '110px',
  onSelectSort: () => null,
  disabled: false,
  dropdownWidth: '136px',
  selectedData: '',
  sortPropertyName: 'name',
  styleHoverDropdown:'',
  iconLeftImage: null,
  iconLeftStyle: 'width: 12px; height: 8px;',
}

export default ButtonUnclickable

