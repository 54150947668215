import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledUserQRDetail = styled.div`
  background: #FFFFFF;
  height: auto;
  justify-content: center;
  transition: all 1s;

  .mobile{
    display: none;
  }

  .card-container {
    display: flex;
    width: 854px;
    box-shadow: 3px 3px 10px ${Colors.color20};
    margin: 80px auto;
  } 

  .photo-wrapper { 
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
    margin: 60px auto 0;
  }

  .photo-profile {
    width: 100%;
    object-fit: cover;
  }

 .textChanged {
    color:${Colors.color11}
  }

  .card-left-section {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 20px 0 0 20px;
    padding: 50px 53px 67px;
    display: flex;
    flex-direction: column;
  }

  .name-photo-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    margin-bottom: 34px;
    border-bottom: 1px solid ${Colors.color31};
  }

  .blueMobile {
    text-align:justify;
    font-family: ${Fonts.FontRegular};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */
    margin-bottom:70px;

    /* PRIMARY VIOLET */

    color: #502773;
  }

  .bot{
    display:block
  }

  .card-right-section {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 0 20px 20px 0;
    overflow: hidden;
    padding: 43px 58px 67px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .text-name {
    ${Fonts.FontRegular}
    ${Fonts.size.size28}
    color: ${Colors.color2};
    font-weight: 800;
    width: 250px;
    margin:auto;
    padding-bottom:10px;
    padding-top: 10px;
  }

  .card-right-section-after-return {
    background: ${Colors.primaryBgColor};
    border-radius: 0 20px 20px 0;
    overflow: hidden;
    padding: 43px 58px 67px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cara-logo {
    margin: 0 0 20px 0;
  }
  
  .info-text {
    font-weight: bold;
    font-size: 18px;
    color: #502773;
    margin-bottom: 15px;
  }

  .info-text2 {
    font-weight: bold;
    font-size: 18px;
    color: #FF6060;
    margin-bottom: 45px;
  }

  .form-info {
    background: #E6D9F2;
    border-radius: 12px;
    display: flex;
    padding: 12px;
    justify-content: space-between;
    margin-bottom: 42px;
  }

  .icon-info {
    width: 24px;
    height: 24px;
    margin: 4px;
  }

  .form-info-text {
    width: 85%;
  }

  .card-container2 {
    display: grid;
    grid-template: auto / 1fr 1fr;
    width: 854px;
    box-shadow: 3px 3px 10px ${Colors.color20};
    margin: 80px auto;

    
    @media ${deviceSize.tablet} {
      grid-template: auto / 1fr;
      box-shadow: none !important;
    } 
  } 

  .cara-logo2 {
    margin: 0 0 20px 0;

    @media ${deviceSize.tablet} {
      display: none;
    } 
  }

  .card-left-container2 {
    padding: 40px 20px;
    
    @media ${deviceSize.tablet} {
      order: 1;
    } 
  }

  .call-text {
    font-weight: 600;
    font-size: 28px;
    color: #424242;
    margin-bottom: 32px;
    @media ${deviceSize.tablet}{
      font-size: 18px;
    }
  }

  .ifyou {
    font-weight: 700;
    font-size: 18px;
    color: #502773;
    margin-bottom: 30px;
  }

  .carousel-container {
    width: 100%; 
    padding: 0 0 50px 0;
  }

  .caregiver-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(157, 157, 157, 0.2);
    border-radius: 14px;
    margin: 24px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    @media ${deviceSize.tabletL} {
      
    } 
  }

  .arrow-left-container {
    width: 52px;
    cursor: pointer;
  }

  .arrow-right-container {
    width: 52px;
    float: right;
    cursor: pointer;
  }

  .cg-text {
    font-size: 24px;
    color: #502773;
    font-weight:  600;
  }

  .cg-text2 {
    font-size: 14px;
    color: #FF6060;
    margin-bottom: 0;
  }

  .card-right-container2 {
    padding: 40px 20px 30px 0; 
    max-height: 600px;
    overflow: auto;
    @media ${deviceSize.tablet} {
      display: flex;
      max-height: 100%;
    } 
  } 

  .caregiverNo {
    background: ${Colors.color36};
    border-radius: 4px;
    font-weight: bold;
    font-size: 18px;
    color: #502773;
    text-align: center;
    padding: 18px 0;
  }

  .date-text {
    font-weight: 600;
    font-size: 14px;
    color: #758BA9;
    text-align: right;
  }

  .membershipId {
    font-size: 24px;
    font-weight: bold;
    color: #424242;
    margin: 18px 0 8px 25px;
  }

  .partnerAddress {
    font-weight: bold;
    font-size: 14px;
    color: #552B7A;
    margin-left: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;

  }

  .relationshipText {
    margin-left: 25px;
    font-size: 14px;
    color: #758BA9;
    margin-right: 10px;
  }

  .desktop {
    @media ${deviceSize.tabletL} {
      display: none;
      visibility: hidden;
    } 
  }

  .mobile2 {
    display: none;
    @media ${deviceSize.tablet} {
      display: block;
    } 
  }
  
  .alice-carousel__stage {
    width: 90% !important;
    @media ${deviceSize.mobileM} {
      width: 100% !important;
    }
  }

  .alice-carousel__dots{
    margin-top: 0px;
  }
  
  @media ${deviceSize.tabletL} {
    .card-container {
      flex-direction: column;
      width:100%;
      align-items: center;
      margin-top: 0;
    }

    .card-container2 {
      width: 90%;
      margin: auto;
    } 

    .bot {
      display:none;
      margin-top: 30px;
    }

    .mobile {
      display:block;
      justify-content:center;
      padding-top:10px;
      margin-top:10px;
    }

    .desc {
      padding-top:10px;
      padding-bottom:20px;
    }

    .textChanged {
      color:#EE220C;
      text-align:justify
    }

    .card-left-section {
      width: 100%;
      background: #ffffff;
      border-radius: 0 0 20px 20px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      text-align:center;
      justify-content: space-between;

     .name-photo-wrapper {
        display: flex;
        flex-direction: column-reverse;
        flex: 1;
        justify-content: space-between;
        
        border-bottom: 1px solid ${Colors.color31};
      }

      .card-right-section {
        padding: 24px;
      }

      .blueMobile {
        margin-bottom: 24px;
      }

      .textChanged {
        font-size: 16px;
        line-height: 24px;
      }

      .card-right-section label {
        font-size: 20px;
        font-weight: 800;
      }

      .cara-logo {
        margin: -125px 0 95px 0;
      }
      
      .text-name {
        ${Fonts.FontRegular}
        ${Fonts.size.size28}
        color: ${Colors.color2};
        font-weight: 800;
        width: 250px;
        margin:auto;
        padding-bottom:10px;
      }

      .label-style {
        margin-left: -20px;
      }
    }

    // Begin custom styling
    .steps.on-mobile {
      color: #502773;
    }
  
    .steps.on-mobile stepTitle {
      font-weight: 700 !important;
      font-size: 18px;
    }
  
    .steps.on-mobile stepDesc {
      font-weight: 500 !important;
      font-size: 18px;
    }
    
    .caregiver-card {
      min-width: 280px;
      @media ${deviceSize.tablet} {
        margin: 24px 24px 24px 5px !important;
        border: 2px solid #502773;
        box-shadow: 0px 4px 15px rgba(80, 39, 115, 0.2);
      }
    }

    .carousel-mobile {
      padding: 0 !important;
    }
  }

  @media ${deviceSize.mobileL} {
    .card-container {
      margin-top: 0;
    }

    .card-left-section {
      padding: 24px;
    }

    .card-left-section .name-photo-wrapper {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .card-right-section {
      padding: 24px;
    }

    .blueMobile {
      margin-bottom: 24px;
    }

    .textChanged {
      font-size: 18px;
      line-height: 20px;
    }

    .card-right-section label {
      font-size: 16px;
      font-weight: 800;
    }

    .cara-logo {
      margin: -125px 0 95px 0;
    }

    .label-style {
      margin-left: -20px;
    }
  }

  .rc-anchor-center-container label {
    font-family: Roboto,helvetica,arial,sans-serif;
    font-size: 44px;
    font-weight: 400;
    line-height: 17px;
  }

  .membershipId2 {
    background: #EFE7F7;
    border-radius: 12px;
    height: 30px;
    padding: 8px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #502773;
  }

  .steps {
    font-weight: bolder;
    font-size: 18px;
  }

  .w-100 {
    width: 100% important;
  }

  .mt-30px {
    margin-top: 30px;
  }
  
  .call-authorities {
    color: #FF6060;
    font-weight: 700;
    text-align: center;
  }

  .all-text-purple * {
    color: #582C7E;
  }

  .text-purple {
    color: #582C7E;
  }

  .text-max-md-purple {
    @media ${deviceSize.tablet} {
      color: #582C7E;
    }
  }

  .pointer {
    cursor: pointer;
  }
`

export const Text = styled.p`
  ${Fonts.FontRegular}
  ${Fonts.size.size18}
  color: ${props => props.color};
  ${props => props.customStyle}
`

export const TextSmall = styled.p`
  ${Fonts.FontRegular}
  ${Fonts.size.size20}
  color: ${props => props.color};
  font-weight: bold;
`

export const Label = styled.label`
  ${Fonts.FontRegular}
  ${Fonts.size.size16}
  color: ${Colors.color19};
  font-weight: bold;
`

export const InputStyle = css` 
  background-color: ${Colors.color32}; 
  box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field {  
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
  }
`

export const InputStyleSafeReturn = css` 
  background-color: ${Colors.color32}; 
  box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color20};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; 
  border-left: none;

  .input-field {  
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; 
    border-left: none;
    background-color: white;
  }
`

export const StyledButton = styled.div`
width:200px;
  height:20px;
font-family: ${Fonts.FontRegular};
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 27px;
display: flex;
align-items: center;
text-align: center;

`