import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

import img from '../../assets/images/sample-card.png'

export const StyledSampleCard = styled.div`
  background-color: white;
  overflow: hidden;
  
  .base-container {
    width: 1093px;
    margin: auto;
    padding-top: 20px;

    @media ${deviceSize.laptopL} {
      width: 70%;
    }

    @media ${deviceSize.tablet} {
      width: 90%;
    }
  }

  .title-sample {
    font-weight: bold;
    font-size: 32px;
    color: #502773;
    margin: 80px 0 0;
  }

  .card-container {
    background: #EEF1F5;
    width: 100%;
    padding: 35px;
  }

  .card {
    background: url(${img});
    background-size: contain;
    border:none;
    background-repeat: no-repeat;
    background-position: center;
    width: 315px;
    height: 420px;
    margin: 0 auto;
  } 

  .text-container {
    position: absolute;
    top: 104px;
    left: 35px;
    width: 90%;
  }

  .card-text {
    font-size: 11px;
    color: #000000;
  }

  .card-text2 {
    font-weight: bold;
    font-size: 30px;
    white-space:pre-wrap;
    color: #000000;
  }

  .card-text3 {
    font-weight: bold;
    font-size: 24px;
    white-space: pre-wrap;
    color: #000000;
  }
`