import React, { useState } from 'react';
import CheckListActive from '../../assets/images/checklist-yellow.png'
import ChecklistInactive from '../../assets/images/checklist-inactive.png'
import Polygon from '../../assets/images/Polygon.png'
import { Images } from '../../themes';

function MultiSelect(props) {
  const { data, onChangeList, onChangeOtherDropdown } = props

  const [selectedOption, setSelectedOption] = useState(null);
  const [showList, setShowList] = useState(false)

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
   <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
     <div 
        style={{
          border: '1px solid #B1C2D8',
          width: '100%',
          height: '47px',
          borderRadius: '13px',
          cursor: 'pointer'
        }}
        onClick={()=>setShowList(!showList)}
      >
        {/* input field */}
        <div style={{marginTop: '12px', paddingLeft: '15px', paddingRight: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{fontSize: '14px'}}>Please Select</div>
          <div>
            <img src={Polygon}/>
          </div>
        </div>
      </div>

      {
        showList && (
          <div 
            style={{
              border: '1px solid #B1C2D8',
              backgroundColor: 'rgba(240, 240, 240, 0.4)',
              borderRadius: '13px',
              marginTop: '1px',
              paddingTop: '15px',
              fontSize: '14px'
            }}
          > 
            {
              data.map((item, index) => (
                <div
                  style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer'}}
                  onClick={()=>onChangeList({ ...item, isActive: !item.isActive })}
                  key={index}
                >
                  <div style={{marginRight: '20px', marginLeft: '15px'}}>
                    <img 
                      src={item.isActive ? CheckListActive : ChecklistInactive}
                      style={{width: '20px'}}
                    />
                  </div>
                  <div>
                    <p style={{color: '#212529', fontSize:'14px', fontWeight: '400'}}>{item.display}</p>
                  </div>
                </div>
              ))
            }
            {
              data[4].isActive && (
                <input
                  value={data[4].name !== 'Others' ? data[4].name : ''}
                  onChange={(e) => onChangeOtherDropdown(e.target.value)}
                  style={{
                    marginLeft: '15px',
                    border: '1px solid #B1C2D8',
                    borderRadius: '5px',
                    marginBottom: '15px',
                    width: '95%',
                    height: '35px',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                  }}
                />
              )
            }
          </div>
        )
      }
   </div>
  );
}

export default MultiSelect;
