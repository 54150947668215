import styled from "styled-components";

import { Fonts, } from '../../themes'

export const StyledHeader2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #758BA9;
  padding-bottom: 30px;
  padding-top: 40px;

  .title {
    ${Fonts.FontRegular}
    ${Fonts.size.size24}
    color: #502773;
    top: 15px;
    position: relative;
  }
`