import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import PropTypes from 'prop-types';
import close from '../../assets/images/close.png';
import { Link } from 'react-router-dom';
import { StyledDialog } from './StyledDialog';
import Button from '../button'

import { Colors, } from '../../themes'

const windowHeight = window.innerHeight
export default class Dialog extends Component {
  render() {
    const { description, height, cancelText, okText, cancelBordered, okBordered, okBorderColor, okTextColor, onOk, okBackgroundColor, onCancel, } = this.props
    return (
      <StyledDialog {...this.props.additionalStyle} height={height}>
        <Modal
          visible={this.props.isVisible}
          width={this.props.width}
          effect="fadeInUp"
          onClickAway={this.props.isClose}
        >
         
            <div className="container-modal" style={{  overflow: 'auto' }}> 
              <div>
                <div style={{display: "flex", justifyContent: 'space-between', }}>
                  <p className="title">{this.props.title}</p>
                  {
                    this.props.showXClose && 
                    <a href="javascript:void(0);" onClick={this.props.isClose}>
                      <img src={close} className="image" />
                    </a>
                  }
                </div>
                {
                  description !== '' &&
                  <div>
                    <p className="description">{description}</p>
                  </div>
                }
                {this.props.children}
              </div>
              <div className="button-container">
                {
                  cancelText !== '' &&
                  <>
                    <div style={{ flex: 1, justifyContent: `center`, alignItems: `center`, }}>
                      <Button 
                        text={cancelText}
                        bordered={cancelBordered}
                        textColor={Colors.color2}
                        textStyle={`font-weight: bold; text-align: center;`}
                        onClick={this.props.onCancel ? this.props.onCancel : this.props.isClose}
                        buttonStyle={`
                          width: 100%;
                          flex: 1;
                        `}
                         textStyle={`
                          margin: 0 auto;
                          text-align: center;
                          padding: 13px 0;
                          flex: 1;
                          width: 100%; 
                        `}
                      />
                    </div>
                    <div style={{ width: '15px', }} />
                  </>
                }
                {
                  okText !== '' &&
                  <div style={{ flex: 1, justifyContent: `center`, alignItems: `center`, }}>
                    <Button 
                      text={okText}
                      bordered={okBordered}
                      textColor={okTextColor}
                      textStyle={`font-weight: bold; text-align: center;`}
                      borderColor={okBorderColor}
                      onClick={onOk}
                      backgroundColor={okBackgroundColor}
                      buttonStyle={`
                        width: 100%;
                        flex: 1;
                      `}
                       textStyle={`
                          margin: 0 auto;
                          text-align: center;
                          padding: 13px 0;
                          flex: 1;
                          width: 100%; 
                        `}
                    />
                  </div>
                }
              </div>
            </div>
        </Modal>
      </StyledDialog>
    )
  }
}


Dialog.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  isClose: PropTypes.func,
  title: PropTypes.string,
  width: PropTypes.string,
  showXClose: PropTypes.bool,
  additionalStyle: PropTypes.any,
  description: PropTypes.description,
  height: PropTypes.string, 
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  cancelBordered: PropTypes.bool,
  okBordered: PropTypes.bool,
  okBorderColor: PropTypes.string,
  okTextColor: PropTypes.string,
  onOk: PropTypes.func,
  okBackgroundColor: PropTypes.string,
  onCancel: PropTypes.func,
}

Dialog.defaultProps = {
  children: null,
  isVisible: false,
  isClose: null,
  title: '',
  width: '',
  showXClose: true,
  additionalStyle: {},
  description: '',
  height: `${windowHeight - 106}px`,
  cancelText: '',
  okText: '',
  cancelBordered: true,
  okBordered: true,
  okBorderColor: Colors.color11,
  okTextColor: Colors.color11,
  onOk: () => null,
  okBackgroundColor: 'transparent',
  onCancel: null,
}