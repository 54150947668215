import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types'

import { StyledCard } from './StyledCard'

function CardSingle(props) {
  return (
    <StyledCard>
      <img src={props.image} className="image" alt="PWD"/>
      <h4 >{props.title}</h4>
      <p className="button-text">{props.text}</p>
    </StyledCard>
  )
}

export default CardSingle

