import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledType = styled.div`
  .type {
    margin-right:45px
  }
  
 .containerPWD {
    width: 100%;
    height: 240px;
    background: #FFFFFF;
    border: 1.5px solid #502773;
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    cursor:  'pointer';
    padding:auto;
    margin-bottom:40px;
    margin-right:1%;  
  }
  button{

  }
  
 .containerCG {  
    width: 100%;
    height: 240px;
    background: #FFFFFF;
    border: 1.5px solid #502773;
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    cursor:  'pointer';
    padding:auto;
  }
  h2{     
    font-family: ${Fonts.FontRegular};
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 34px;
    /* identical to box height, or 106% */
    color: #502773;
  }
  h4{         
        font-family: ${Fonts.FontRegular};
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */
        margin-left:auto;margin-right:auto;
        text-align: center;

        color: #424242;
  }
  .Button{
    max-width: 100px;
    font-family: 'Nunito Sans'
  }
  
`

export const StyledButton = styled.div`
  width:200px;
  height:20px;
float:right;margin-bottom:80px
`