import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledPartnerConfirmation = styled.div`
  background-color: white;
  .base-container {
    width: 1093px;
    margin: auto;
    padding-top: 20px;

    @media ${deviceSize.laptopL} {
      width: 70%;
    }

    @media ${deviceSize.tablet} {
      width: 90%;
    }
  }

  .text {
    font-weight: bold;
    font-size: 32px;
    color: #502773;
    padding-top: 100px;
  }

  .small-text {
    font-weight: 600;
    font-size: 14px;
    color: #758BA9;
    margin-top: 15px;
  }

  .dialog-text {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #424242;
    width: 382px;
  }
`
