import React from 'react'
import Navbar from 'react-bootstrap/Navbar'

import Logo from '../../assets/images/LogoCara.png'

import {StyledHeader} from "./StyledHeader";

function Header({ title }) {
    return (
      <>
      <StyledHeader>
        <Navbar>
          <Navbar.Brand ><img src={Logo}/></Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
                <h3 style={{color: "#502773"}}>
                  {title ? title : `Member Registration`}
                </h3>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        </StyledHeader>
        <hr style={{border: "1px solid #758BA9"}}/>
      </>
    )
  }
  
  
  export default Header
  