import { Link } from "react-router-dom";
import { Colors } from "../../../../themes";

const GTPCard = ({ id, name, address, active, setActiveMarker }) => {
  return (
    <Link to={`/go-to-points/detail/${id}`} style={{ textDecoration: "none" }}>
      <div
        id={`gtp-data-${id}`}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "14px",
          backgroundColor: active ? Colors.color14 : Colors.color3,
          userSelect: "none",
          cursor: "pointer",
          boxShadow: "2px 2px 5px 0px rgba(0, 0, 0, 0.1)",
        }}
        onClick={() => setActiveMarker(id)}
      >
        <h1
          style={{
            fontSize: "16px",
            margin: 0,
            fontWeight: "bold",
            color: Colors.color2,
          }}
        >
          {name || "-"}
        </h1>
        <p
          style={{
            fontSize: "14px",
            margin: 0,
            fontWeight: "600",
            color: active ? Colors.color3 : Colors.color19,
          }}
        >
          {address || "-"}
        </p>
      </div>
    </Link>
  );
};

export default GTPCard;
