import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledModalOtp = styled.div`
 display:flex;
 justify-content:'center';
 text-align:center;

 .modal-dialog {
   width:250px
 }
 .modal-header{
   border-bottom:none
 }
  h3{     
 text-align:center;
    font-family: ${Fonts.FontRegular};
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height, or 121% */
    text-align: center;
    color: #424242;
  }
  .h6{

    text-align:center;
    font-family: ${Fonts.FontRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* or 122% */

    text-align: center;

    color: #758BA9;

  }
  input{
          
    size: 10px;
    height: 30px;
    background: #FFFFFF;
    /* TXT PASSIVE */
    
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height, or 121% */
    
    text-align: center;
    
    color: #424242;
    
    
    border: 1px solid #B1C2D8;
    box-sizing: border-box;
    border-radius: 20px;
  }
  .InputStyle{
    background-color: ${Colors.color32}; \
  box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field {  
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
  }
  }
`
