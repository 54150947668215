import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledFaq = styled.div`
  width:80%;
  padding-top: 50px;
  margin:auto;
    .title{

        ${Fonts.FontRegular};
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 20px;
        /* identical to box height, or 71% */
        /* PRIMARY VIOLET */
        color: #502773;
    }
    h3{
        margin-bottom:40px;
        font-family: "TruenoRegular", Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        /* identical to box height, or 44% */
        display: flex;
        align-items: center;
        /* TXT STD */
        color: #424242;
    }

.faq-row-wrapper {
    .faq-title {
    }

    .faq-body {
        .faq-row {
            .row-title {
                
                font-family: ${Fonts.FontRegular};
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 20px;
                /* identical to box height, or 71% */            
                /* PRIMARY VIOLET */
                color: #502773;

            .icon-wrapper{
                padding:0;margin:0;
                display:flex;
                justify-content:center;
                align-items:center;
                width:25px;
                height:25px;
                color:#502773;
                border-radius: 25px;
                border: 2px solid #7F59A0;
                box-sizing: border-box;
            }
        }
            

            .row-content {
                .row-content-text {
                    font-family: "TruenoUltralight", Arial, sans-serif;
                    margin-bottom :20px;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    width:90%;
  margin:auto;
    h3{
        margin-bottom:40px;
        font-family: "TruenoUltralight", Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height, or 44% */
        display: flex;
        align-items: center;
        /* TXT STD */
        color: #424242;
    }
    .title{

        ${Fonts.FontRegular};
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 71% */
        /* PRIMARY VIOLET */
        color: #502773;
    }
    

.faq-row-wrapper {
    .faq-title {
    }

    .faq-body {
        .faq-row {
            .row-title {
                
                ${Fonts.FontRegular};
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 20px;
                /* identical to box height, or 71% */            
                /* PRIMARY VIOLET */
                color: #502773;

            .icon-wrapper{
                padding:0;margin:0;
                display:flex;
                justify-content:center;
                align-items:center;
                width:25px;
                height:25px;
                color:#502773;
                border-radius: 25px;
                border: 2px solid #7F59A0;
                box-sizing: border-box;
            }
        }
            

            .row-content {
                .row-content-text {
                    
                    font-family: "TruenoUltralight", Arial, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    /* or 129% */


                    /* TXT STD */

                    color: #424242;
                }
            }
        }
    }
}
}
`
