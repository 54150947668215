// Deps
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";

// Libs
import Appconfig from "./config";
import registerServiceWorker from "./registerServiceWorker";

// App router
import App from "./App";

// Internationalization
import "./language/i18n";

// Styling
import "./index.css";

// Sentry setting
if (
  process.env.REACT_APP_ENV &&
  ["production", "staging"].includes(process.env.REACT_APP_ENV)
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <BrowserRouter basename={Appconfig.BASE_NAME || ""}>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
registerServiceWorker();
