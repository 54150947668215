import React, { Component } from 'react'
import { Container, Row, Col, Hidden, } from 'react-grid-system';

import { StyledUserQRDetail, Text, Label, InputStyle, } from './styles/StyledUserQRDetail'

import Input from '../components/input'
import Button from '../components/button'

import { Images, Colors, Fonts, } from '../themes/'
import axios from 'axios'
import { getUserById } from '../api/UserAPI'

import { createSafeReturnCard } from '../api/UserAPI'
import PopUpBox from '../components/modal'
import PopUpBoxHandler from '../components/modalhandler'
import AppConfig from '../config'
import { API } from '../lib/Request'

import hands from '../assets/images/hands.png'
import { HTML5_FMT } from 'moment';
import { Link } from 'react-router-dom';
const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
const DELAY = 500;


export class afterRetunCard extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      user: null,
      show: false,
      alert: false,
      name: '',
      phone: '',
      location: '',
      disable: true,
      load: false,
      errorMessage: '',
    }
    this.state = {isValid: false};
  }


  componentDidMount() {
    
  }
  handleChange = (event)=>{
    this.setState({value: event.target.value})
  
  }
   handleClose =()=> {
    this.props.history.push({pathname:'/'})   
  } 
  
  render() {

    return (
      <StyledUserQRDetail>
          <div style={{height:'100%'}} className="card-right-section-after-return">
            <div>
            <Row  style={{display:'flex',justifyContent:'center',marginTop:'30px'}}>

              <img  src={hands}/>
            </Row>
             <Row  style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>

             <h4 style={{  fontFamily: `"TruenoRegular", Arial, sans-serif`,
                            fontStyle: 'normal',
                            fontWeight: '800',
                            fontSize: '22px',
                            lineHeight: '28px',color: '#424242',      
                            textAlign: 'center'}}>Thank you for helping to connect with my Organisation Staff.</h4>
            </Row>
            <Row  >
              <Col>
              <Row style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
             <h5 style={{  fontFamily: `"TruenoRegular", Arial, sans-serif`,
                            fontStyle: 'normal',
                            fontWeight: '800',
                            fontSize: '18px',
                            lineHeight: '24px',color: '#424242',      
                            textAlign: 'center'}}>Here are some ways to support me if I am in distress :</h5>
                            </Row>
                            <Row style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
                            <h5  style={{  fontFamily: `"TruenoRegular", Arial, sans-serif`,
                            fontStyle: 'normal',
                            fontWeight: '800',
                            fontSize: '18px',
                            lineHeight: '24px',color: '#424242',      
                            textAlign: 'center'}}><span style={{color:'#7F59A0', fontFamily: `"TruenoRegular", Arial, sans-serif`,}}> <Link to={{  pathname: `https://cara.sg/safe-return-guide/` }}  style={{ color: '#62318C' }} target="_blank" >  cara.sg/safe-return/guide</Link></span></h5>
                            </Row>
                            <Row style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
                            <h5 style={{marginTop:'10px',  fontFamily: `"TruenoRegular", Arial, sans-serif`,
                            fontStyle: 'normal',
                            fontWeight: '800',
                            fontSize: '18px',
                            lineHeight: '24px',color: '#424242',      
                            textAlign: 'center'}}>
                              If this is an emergency, please call the relevant authorities. </h5>
                              </Row>
                  </Col>
            </Row>
            <Row  style={{display:'flex',justifyContent:'center',marginTop:'15px'}}>
            <Button   buttonStyle={{height:'60px', width:'433px', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', fontFamily: `"TruenoRegular", Arial, sans-serif`}}  style={{height:'20px'}} text="OK"
                    onClick={this.handleClose}
              />
            </Row>
             </div>
          </div>
     
      </StyledUserQRDetail>
    )
  }
}

export default afterRetunCard
